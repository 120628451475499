import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getEmployees,
  getOffices,
  getPositionOwners,
  getServices,
} from "./address-book";
import { setDictionaryStore } from "../store/dictionary.slice";
const apiUrl = process.env.REACT_APP_API_URL;

export function useDictionary(attr, params, parseResponse) {
  const storedDictionary = useSelector((state) => state.dictionary.store);
  const dispatch = useDispatch();
  if (!storedDictionary[attr]) {
    try {
      dispatch(
          setDictionaryStore({
            attr,
            items: [],
            requested: new Date().getTime(),
          })
      );
    } catch (err) {
      // refresh
    }
    let func = "";
    switch (attr) {
      case "services":
        func = getServices;
        params = params ?? [{}, [], 10000, 0, ["id", "name", "isHc", "isMd"]];
        break;
      case "offices":
        func = getOffices;
        params = params ?? [
          {},
          [],
          10000,
          0,
          ["id", "name", "isMd", "isHc", "isFeatured"],
        ];
        break;
      case "companies":
        func = getCompanies;
        params = params ?? [{}, [], 10000, 0, ["id", "name"]];
        break;
      case "countries":
        func = getCountries;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name", "nationality"],
          },
        ];
        break;
      case "fieldsOfWorkCategories":
        func = getFieldsOfWorkCategories;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name"],
          },
        ];
        break;
      case "fieldsOfWorks":
        func = getFieldsOfWorks;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name", "fieldsOfWorkCategoryId"],
          },
        ];
        break;
      case "workTypeMds":
        func = getWorkTypeMds;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name"],
          },
        ];
        break;
      case "languages":
        func = getLanguages;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name"],
          },
        ];
        break;
      case "qualifications":
        func = getQualifications;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name", "level"],
          },
        ];
        break;
      case "fieldOfStudyCategories":
        func = getFieldsOfStudyCategories;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name"],
          },
        ];
        break;
      case "fieldOfStudies":
        func = getFieldsOfStudies;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name", "fieldsOfStudyCategoryId"],
          },
        ];
        break;
      case "computerSkillCategories":
        func = getComputerSkillCategories;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name"],
          },
        ];
        break;
      case "computerSkills":
        func = getComputerSkills;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name", "computerSkillCategoryId"],
          },
        ];
        break;
      case "drivingLicences":
        func = getDrivingLicences;
        params = params ?? [
          {
            order: ["priority ASC"],
            fields: ["id", "name"],
          },
        ];
        break;
      case "tags":
        func = getTags;
        params = params ?? [
          {
            include: ["tagCategories"],
            order: ["tagCategoryId ASC", "name ASC"],
            fields: ["id", "name", "type", "tagCategoryId", "tagCategories"],
          },
        ];
        break;
      case "spareTimes":
        func = getSpareTimes;
        params = params ?? [
          {
            order: ["name ASC"],
            fields: ["id", "name"],
          },
        ];
        break;
      case "partners":
        func = getPartners;
        params = params ?? [
          {
            order: ["name ASC"],
            fields: ["id", "name"],
          },
        ];
        break;
      case "employees":
        func = getEmployees;
        params = params ?? [
          {},
          [],
          10000,
          0,
          ["id", "firstName", "lastName", "userEmployee"],
        ];
        break;
      case "cities":
        func = getCities;
        params = params ?? [
          "",
          100000,
          {
            fields: ["id", "name", "zip"],
          },
        ];
        break;
      case "counties":
        func = getCounties;
        params = params ?? [null, { fields: ["id", "name"] }];
        break;
      case "referers":
        func = getReferers;
        params = params ?? [{ fields: ["id", "name"] }];
        break;
      // case "":
      //   func = ;
      //   params = params ?? [];
      //   break;
    }
    if (!params) {
      params = [];
    }
    func(...params)
      .then((response) => {
        if (response.data) {
          let parsedResponse =
            parseResponse && typeof parseResponse === "function"
              ? parseResponse(response.data)
              : response.data;
          dispatch(
            setDictionaryStore({
              attr,
              items: parsedResponse,
              requested: storedDictionary?.[attr]?.requested ?? null,
              response: new Date().getTime(),
            })
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  }
  return storedDictionary[attr];
}

export const isactiveFilter = (filter) => {
  if (!filter) {
    filter = {};
  }
  if (!filter.where) {
    filter.where = {
      isActive: 1,
    };
  } else if (
    filter.where.and &&
    !filter.where.and.find((item) => Object.keys(item) === "isActive")
  ) {
    filter.where.and.push({
      isActive: 1,
    });
  } else if (filter.where) {
    const filterWhere = filter.where;
    filter.where = {
      and: [
        {
          isActive: 1,
        },
        filterWhere,
      ],
    };
  }
  return filter;
};

export const getTags = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/tags`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getTag = (id) => {
  return axios.get(`${apiUrl}/tags/${id}`, {});
};
export const setTag = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/tags/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/tags`, data);
  }
};
export const deleteTag = (id) => {
  return axios.delete(`${apiUrl}/tags/${id}`, {});
};

export const getFieldsOfWorkCategories = (
  filter = {},
  withInactives = false
) => {
  return axios.get(`${apiUrl}/fields-of-work-categories`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getFieldsOfWorkCategory = (id) => {
  return axios.get(`${apiUrl}/fields-of-work-categories/${id}`, {});
};
export const setFieldsOfWorkCategory = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/fields-of-work-categories/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/fields-of-work-categories`, data);
  }
};
export const deleteFieldsOfWorkCategory = (id) => {
  return axios.delete(`${apiUrl}/fields-of-work-categories/${id}`, {});
};

export const getFieldsOfWorks = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/fields-of-works`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getFieldsOfWorksByCategory = (
  filter = {},
  withInactives = false
) => {
  return axios.get(`${apiUrl}/fields-of-works`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getFieldsOfWork = (id) => {
  return axios.get(`${apiUrl}/fields-of-works/${id}`, {});
};
export const setFieldsOfWork = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/fields-of-works/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/fields-of-works`, data);
  }
};
export const deleteFieldsOfWork = (id) => {
  return axios.delete(`${apiUrl}/fields-of-works/${id}`, {});
};

export const getQualifications = (filter = {}, withInactives = false) => {
  filter = withInactives ? filter : isactiveFilter(filter);
  const params = { ...filter, ...{ order: "level ASC" } };
  return axios.get(`${apiUrl}/qualifications`, {
    params: params,
  });
};
export const getQualification = (id) => {
  return axios.get(`${apiUrl}/qualifications/${id}`, {});
};
export const setQualification = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/qualifications/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/qualifications`, data);
  }
};
export const deleteQualification = (id) => {
  return axios.delete(`${apiUrl}/qualifications/${id}`, {});
};

export const getFieldsOfStudyCategories = (
  filter = {},
  withInactives = false
) => {
  return axios.get(`${apiUrl}/fields-of-study-categories`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getFieldsOfStudyCategory = (id) => {
  return axios.get(`${apiUrl}/fields-of-study-categories/${id}`, {});
};
export const setFieldsOfStudyCategory = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/fields-of-study-categories/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/fields-of-study-categories`, data);
  }
};
export const deleteFieldsOfStudyCategory = (id) => {
  return axios.delete(`${apiUrl}/fields-of-study-categories/${id}`, {});
};

export const getFieldsOfStudies = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/fields-of-studies`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getFieldsOfStudiesByCategory = (categoryId) => {
  return axios.get(`${apiUrl}/fields-of-studies`, {
    params: {
      where: {
        fieldsOfStudyCategoryId: categoryId,
      },
    },
  });
};
export const getFieldsOfStudy = (id) => {
  return axios.get(`${apiUrl}/fields-of-studies/${id}`, {});
};
export const setFieldsOfStudy = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/fields-of-studies/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/fields-of-studies`, data);
  }
};
export const deleteFieldsOfStudy = (id) => {
  return axios.delete(`${apiUrl}/fields-of-studies/${id}`, {});
};

export const getWorkTypeMds = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/work-type-mds`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getWorkTypeMd = (id) => {
  return axios.get(`${apiUrl}/work-type-mds/${id}`, {});
};
export const setWorkTypeMd = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/work-type-mds/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/work-type-mds`, data);
  }
};
export const deleteWorkTypeMd = (id) => {
  return axios.delete(`${apiUrl}/work-type-mds/${id}`, {});
};

export const getComputerSkillCategories = (
  filter = {},
  withInactives = false
) => {
  return axios.get(`${apiUrl}/computer-skill-categories`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getComputerSkillCategory = (id) => {
  return axios.get(`${apiUrl}/computer-skill-categories/${id}`, {});
};
export const setComputerSkillCategory = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/computer-skill-categories/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/computer-skill-categories`, data);
  }
};
export const deleteComputerSkillCategory = (id) => {
  return axios.delete(`${apiUrl}/computer-skill-categories/${id}`, {});
};

export const getComputerSkills = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/computer-skills`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getComputerSkill = (id) => {
  return axios.get(`${apiUrl}/computer-skills/${id}`, {});
};
export const getComputerSkillsByCategory = (
  categoryId,
  withInactives = false
) => {
  const filter = {
    where: {
      computerSkillCategoryId: categoryId,
    },
  };
  return axios.get(`${apiUrl}/computer-skills`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const setComputerSkill = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/computer-skills/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/computer-skills`, data);
  }
};
export const deleteComputerSkill = (id) => {
  return axios.delete(`${apiUrl}/computer-skills/${id}`, {});
};

export const getReferers = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/referers`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getReferer = (id) => {
  return axios.get(`${apiUrl}/referers/${id}`, {});
};
export const setReferer = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/referers/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/referers`, data);
  }
};
export const deleteReferer = (id) => {
  return axios.delete(`${apiUrl}/referers/${id}`, {});
};

export const getPartners = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/partners`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getPartner = (id) => {
  return axios.get(`${apiUrl}/partners/${id}`, {});
};
export const setPartner = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/partners/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/partners`, data);
  }
};
export const deletePartner = (id) => {
  return axios.delete(`${apiUrl}/partners/${id}`, {});
};

export const getCountries = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/countries`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getCountriesAutocomplete = (nationality) => {
  const filter = {};
  if (nationality) {
    filter.where = {
      nationality: { like: `${nationality}%` },
    };
  }
  filter.limit = 25;
  return axios.get(`${apiUrl}/countries`, { params: filter });
};

export const getCountry = (id) => {
  return axios.get(`${apiUrl}/countries/${id}`, {});
};
export const setCountry = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/countries/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/countries`, data);
  }
};
export const deleteCountry = (id) => {
  return axios.delete(`${apiUrl}/countries/${id}`, {});
};

export const getAdminCitiesGroup = (search) => {
  return axios.get(`${apiUrl}/admin/cities-group-id/${search}`, {});
};

export const getDictionaryCities = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/cities`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getCities = (where, limit, filter) => {
  if (!filter) {
    filter = {
      where: {},
    };
  }
  if (where) {
    filter.where = { ...where, ...{ isActive: true } };
  }
  if (limit) {
    filter.limit = limit;
  }
  return axios.get(`${apiUrl}/cities`, { params: filter });
};
export const getCity = (id) => {
  return axios.get(`${apiUrl}/cities/${id}`, {});
};
export const setCity = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/cities/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/cities`, data);
  }
};
export const deleteCity = (id) => {
  return axios.delete(`${apiUrl}/cities/${id}`, {});
};

export const getLanguages = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/languages`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getLanguage = (id) => {
  return axios.get(`${apiUrl}/languages/${id}`, {});
};
export const setLanguage = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/languages/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/languages`, data);
  }
};
export const deleteLanguage = (id) => {
  return axios.delete(`${apiUrl}/languages/${id}`, {});
};

export const getDrivingLicences = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/driving-licences`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getDrivingLicence = (id) => {
  return axios.get(`${apiUrl}/driving-licences/${id}`, {});
};
export const setDrivingLicence = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/driving-licences/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/driving-licences`, data);
  }
};
export const deleteDrivingLicence = (id) => {
  return axios.delete(`${apiUrl}/driving-licences/${id}`, {});
};

export const getSpareTimes = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/spare-times`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getSpareTime = (id) => {
  return axios.get(`${apiUrl}/spare-times/${id}`, {});
};
export const setSpareTime = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/spare-times/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/spare-times`, data);
  }
};
export const deleteSpareTime = (id) => {
  return axios.delete(`${apiUrl}/spare-times/${id}`, {});
};

export const getCounties = (counties, filter = {}) => {
  if (typeof filter !== "object") {
    filter = {};
  }
  if (counties) {
    filter.where = {
      id: { inq: counties },
      isActive: true,
    };
  } else {
    filter.where = {
      isActive: true,
    };
  }
  return axios.get(`${apiUrl}/counties`, filter);
};
export const getCounty = (id) => {
  return axios.get(`${apiUrl}/counties/${id}`, {});
};
export const setCounty = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/counties/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/counties`, data);
  }
};
export const deleteCounty = (id) => {
  return axios.delete(`${apiUrl}/counties/${id}`, {});
};

export const getDictionaryTagCategories = (filter = {}, withInactives = false) => {
  return axios.get(`${apiUrl}/tag-categories`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getTagCategories = (categories, filter = {}) => {
  if (categories) {
    filter.where = {
      id: { inq: categories },
      isActive: true,
    };
  } else {
    filter.where = {
      isActive: true,
    };
  }
  return axios.get(`${apiUrl}/tag-categories`, filter);
};
export const getTagCategory = (id) => {
  return axios.get(`${apiUrl}/tag-categories/${id}`, {});
};
export const setTagCategory = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/tag-categories/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/tag-categories`, data);
  }
};
export const deleteTagCategory = (id) => {
  return axios.delete(`${apiUrl}/tag-categories/${id}`, {});
};
