import React, { useEffect, useState } from "react";
import {
  getFieldsOfWorks,
  getFieldsOfWorkCategories,
  getFieldsOfWork,
  setFieldsOfWork,
  deleteFieldsOfWork,
} from "../../services/dictionary";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";

FieldsOfWorks.propTypes = {};

function FieldsOfWorks(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);

  const permissions = usePermission("dictionary");
  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const [fieldsOfWorkCategories, setFieldsOfWorkCategories] = useState([]);
  useEffect(() => {
    getFieldsOfWorkCategories()
      .then((response) => {
        setFieldsOfWorkCategories(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Megnevezés",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "fieldsOfWorkCategoryId",
      fieldType: "select",
      headerName: "Szakterület",
      validation: {
        required: true,
      },
      valueGetter: (params) => {
        const fieldsOfWorkCategory = fieldsOfWorkCategories.find(
          (item) => item.id === params.row.fieldsOfWorkCategoryId
        );
        return fieldsOfWorkCategory
          ? fieldsOfWorkCategory.name
          : `ismeretlen ${params.row.fieldsOfWorkCategoryId}`;
      },
    },
    {
      field: "priority",
      fieldType: "number",
      // editable: false,
      defaultValue: 1,
      headerName: "Prioritás",
      width: 100,
    },
    {
      field: "highlighted",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Kiemelt",
      width: 100,
      valueGetter: (params) => {
        return params.row.highlighted ? "igen" : "nem";
      },
    },
    {
      field: "isActive",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Státusz",
      width: 100,
      valueGetter: (params) => {
        return params.row.isActive ? "aktív" : "inaktív";
      },
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Foglalkozások",
    url: "/dictionary/fields-of-works",
    item: "foglalkozás",
  };

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getFieldsOfWork}
          setFunc={setFieldsOfWork}
          fieldsOfWorkCategories={fieldsOfWorkCategories}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getFieldsOfWorks}
          isDictionary={true}
          deleteFunc={deleteFieldsOfWork}
          columns={columns}
          fieldsOfWorkCategories={fieldsOfWorkCategories}
        ></Lists>
      )}
    </>
  );
}

export default FieldsOfWorks;
