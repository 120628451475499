import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  getFieldsOfStudiesByCategory,
  getFieldsOfStudyCategories,
} from "../../../services/dictionary";

const FilterQualificationForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      if (value) {
        let qualificationIds = {};
        if (operator === "gt") {
          qualificationIds = options
            .filter((item) => item.level >= value)
            .map((item) => item.id);
        } else if (operator === "lt") {
          qualificationIds = options
            .filter((item) => item.level <= value)
            .map((item) => item.id);
        } else {
          qualificationIds = options
            .filter((item) => item.level === value)
            .map((item) => item.id);
        }
        filter.qualificationId = { inq: qualificationIds };
      }
      if (!inProgress) {
        filter.inProgress = 0;
      }
      if (fieldsOfStudyCategoryId) {
        filter.fieldsOfStudyCategoryId = fieldsOfStudyCategoryId;
      }
      if (fieldsOfStudyId) {
        filter.fieldsOfStudyId = fieldsOfStudyId;
      }
      if (school) {
        filter.school = { like: `%${school}%` };
      }
      return { userQualifications: filter };
    },
  }));

  const [fieldsOfStudyCategories, setFieldsOfStudyCategories] = useState([
    { id: 0, name: "Összes" },
  ]);
  const [fieldsOfStudyCategoryId, setFieldsOfStudyCategoryId] = useState(0);
  const [fieldsOfStudies, setFieldsOfStudies] = useState([
    { id: 0, name: "Összes" },
  ]);
  const [fieldsOfStudyId, setFieldsOfStudyId] = useState(0);

  const [inProgress, setInProgress] = useState(false);
  const [operator, setOperator] = useState("gt");
  const [value, setValue] = useState("");
  const [school, setSchool] = useState("");

  const options = params.options ?? [];
  const operators = [
    { id: "gt", name: "legalább" },
    { id: "lt", name: "legfeljebb" },
    { id: "=", name: "pontosan" },
  ];

  useEffect(() => {
    getFieldsOfStudyCategories()
      .then((response) => {
        setFieldsOfStudyCategories([
          ...[{ id: 0, name: "Összes" }],
          ...response.data,
        ]);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    getFieldsOfStudiesByCategory(fieldsOfStudyCategoryId)
      .then((response) => {
        setFieldsOfStudies([...[{ id: 0, name: "Összes" }], ...response.data]);
      })
      .catch((err) => console.error(err));
  }, [fieldsOfStudyCategoryId]);

  return (
    <Grid container direction={"column"}>
      <Grid>
        <label className={`col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid container direction={"column"} spacing={2}>
        <Grid>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={inProgress}
                      onChange={() => setInProgress(!inProgress)}
                    />
                  }
                  label="folyamatban lévő is"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  name={`${params.field}-operator`}
                  onChange={(event) => {
                    setOperator(event.target.value ?? "");
                  }}
                  value={operator}
                >
                  {operators.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Végzettség</InputLabel>
                <Select
                  name={params.field}
                  onChange={(event) => {
                    setValue(event.target.value ?? "");
                  }}
                  value={value}
                  label={"Végzettség"}
                  sx={{ minWidth: 250 }}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option.level}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Tanulmányterület</InputLabel>
                <Select
                  name={`${params.field}-fields-of-study-categories`}
                  label={"Tanulmányterület"}
                  onChange={(event) => {
                    setFieldsOfStudyCategoryId(event.target.value ?? "");
                  }}
                  value={fieldsOfStudyCategoryId}
                  sx={{ minWidth: 200 }}
                >
                  {fieldsOfStudyCategories.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Szakvégzettség</InputLabel>
                <Select
                  name={`${params.field}-fields-of-studies`}
                  label={"Szakvégzettség"}
                  onChange={(event) => {
                    setFieldsOfStudyId(event.target.value ?? "");
                  }}
                  value={fieldsOfStudyId}
                  sx={{ minWidth: 100 }}
                >
                  {fieldsOfStudies.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid>
              <TextField
                type="text"
                onChange={(event) => {
                  setSchool(event.target.value ?? "");
                }}
                label={"Oktatási intézmény"}
                value={school}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const FilterQualification = forwardRef(FilterQualificationForwardRef);

FilterQualification.propTypes = {
  params: PropTypes.object,
};

export default FilterQualification;
