import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  getFieldsOfWorkCategories,
  getFieldsOfWorksByCategory,
} from "../../../services/dictionary";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

const FilterFieldsOfWorkForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = { userFieldsOfWorks: {} };
      filter.userFieldsOfWorks[operator] = [];
      fieldsOfWorkCategoryIds.map((categoryId, index) => {
        const filterRow = {};
        if (categoryId) {
          filterRow.fieldsOfWorkCategoryId = categoryId;
          if (fieldsOfWorkIds[index]) {
            filterRow.fieldsOfWorkId = fieldsOfWorkIds[index];
          }
          filter.userFieldsOfWorks[operator].push(filterRow);
        }
      });
      return filter;
    },
  }));

  const [operator, setOperator] = useState("or");
  const [fieldsOfWorkCategories, setFieldsOfWorkCategories] = useState([
    { id: 0, name: "Összes" },
  ]);
  const [fieldsOfWorkCategoryIds, setFieldsOfWorkCategoryIds] = useState([0]);
  const [fieldsOfWorks, setFieldsOfWorks] = useState([
    [{ id: 0, name: "Összes" }],
  ]);
  const [fieldsOfWorkIds, setFieldsOfWorkIds] = useState([]);

  useEffect(() => {
    getFieldsOfWorkCategories()
      .then((response) => {
        setFieldsOfWorkCategories([
          ...[{ id: 0, name: "Összes" }],
          ...response.data,
        ]);
      })
      .catch((err) => console.error(err));
  }, []);

  const changeCategoryId = (index, categoryId) => {
    const newCategoryIds = JSON.parse(JSON.stringify(fieldsOfWorkCategoryIds));
    newCategoryIds[index] = categoryId;
    setFieldsOfWorkCategoryIds(newCategoryIds);

    if (categoryId) {
      getFieldsOfWorksByCategory({
        where: { fieldsOfWorkCategoryId: categoryId },
      })
        .then((response) => {
          const newFieldsOfWorks = JSON.parse(JSON.stringify(fieldsOfWorks));
          newFieldsOfWorks[index] = [
            ...[{ id: 0, name: "Összes" }],
            ...response.data,
          ];
          setFieldsOfWorks(newFieldsOfWorks);
        })
        .catch((err) => console.error(err));
    } else {
      const newFieldsOfWorks = JSON.parse(JSON.stringify(fieldsOfWorks));
      newFieldsOfWorks[index] = [...[{ id: 0, name: "Összes" }]];
      setFieldsOfWorks(newFieldsOfWorks);
    }
  };

  const handleAddRow = () => {
    setFieldsOfWorkCategoryIds([...fieldsOfWorkCategoryIds, 0]);
  };

  const handleDeleteRow = (toDelete) => {
    setFieldsOfWorkCategoryIds(
      fieldsOfWorkCategoryIds.filter((item, index) => index !== toDelete)
    );
  };

  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid>
        <label className={`col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <Grid container spacing={2}>
          <Grid>
            <FormControl disabled={fieldsOfWorkCategoryIds.length < 2}>
              <FormLabel id="group-label">
                A felsorolt területek közül ...
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="group-label"
                name="radio-buttons-group"
                value={operator}
                onChange={(event) => setOperator(event.target.value)}
              >
                <FormControlLabel
                  value="and"
                  control={<Radio />}
                  label="...mindegyik"
                />
                <FormControlLabel
                  value="or"
                  control={<Radio />}
                  label="...bármelyik"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {fieldsOfWorkCategoryIds.map((fieldsOfWorkCategoryId, index) => (
          <Grid key={index} container alignItems={"center"}>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Munkaterület</InputLabel>
                <Select
                  name={`${params.field}-fields-of-study-categories[]`}
                  label={"Munkaterület"}
                  onChange={(event) => {
                    changeCategoryId(index, event.target.value ?? "");
                  }}
                  value={fieldsOfWorkCategoryId}
                  sx={{ minWidth: 200 }}
                >
                  {fieldsOfWorkCategories.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Foglalkozás</InputLabel>
                <Select
                  name={`${params.field}-fields-of-works[]`}
                  label={"Foglalkozás"}
                  onChange={(event) => {
                    const newFieldsOfWorkIds = JSON.parse(
                      JSON.stringify(fieldsOfWorkIds)
                    );
                    newFieldsOfWorkIds[index] = event.target.value ?? "";
                    setFieldsOfWorkIds(newFieldsOfWorkIds);
                  }}
                  value={fieldsOfWorkIds[index] ?? 0}
                  sx={{ minWidth: 100 }}
                >
                  {(
                    fieldsOfWorks[index] ?? [...[{ id: 0, name: "Összes" }]]
                  ).map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid>
              {index === fieldsOfWorkCategoryIds.length - 1 && (
                <IconButton color="success" onClick={() => handleAddRow()}>
                  <PlaylistAddIcon />
                </IconButton>
              )}
              {index !== fieldsOfWorkCategoryIds.length - 1 && (
                <IconButton
                  color="error"
                  onClick={() => handleDeleteRow(index)}
                >
                  <PlaylistRemoveIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
const FilterFieldsOfWork = forwardRef(FilterFieldsOfWorkForwardRef);

FilterFieldsOfWork.propTypes = {
  params: PropTypes.object,
};

export default FilterFieldsOfWork;
