import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  formError,
  formErrorMsg,
  formFieldError,
  formGetValue,
  formSetValue,
} from "./formUtils";
import { Alert } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying } = props;

  const [value, setValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [inited, setInited] = useState(false);

  useEffect(() => {
    setInited(true);
  }, [formik, column]);

  useEffect(() => {
    if (inited) {
      if (value && confirm && value === confirm) {
        formSetValue(formik, column, value);
        dirtying();
        formError(formik, column);
        setErrorMsg("");
      } else if (!value) {
        setErrorMsg("A jelszó megadása kötelező!");
      } else if (value.length < 6) {
        setErrorMsg("A jelszó legalább 6 karakteres legyen!");
      } else if (!confirm) {
        setErrorMsg("A jelszó megerősítése kötelező!");
      } else if (value !== confirm) {
        setErrorMsg("A jelszó és a jelszó megerősítése nem egyezik!");
      }
    }
  }, [value, confirm]);

  const errorText = formFieldError(formik, column, isFormDirty);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="row">
      {column.headerName && (
        <label
          className={`col-lg-4 col-form-label fw-bold fs-6 ${
            column.validation?.required && "required"
          }`}
        >
          {column.headerName}
        </label>
      )}
      <div className={`fv-row ${column.headerName ? "col-lg-8" : "col-lg-12"}`}>
        <FormControl sx={{ width: "100%", mb: 3 }}>
          <TextField
            type={showPassword ? "text" : "password"}
            placeholder={column.headerName}
            name={column.field}
            autoComplete="new-password"
            onChange={(event) => {
              if (column.onChange) {
                column.onChange(event.target.value ?? "");
              }
              setValue(event.target.value ?? "");
              return formik.handleChange;
            }}
            value={value ?? ""}
            error={!!formFieldError(formik, column, isFormDirty)}
            helperText={errorText ? errorText : column.helperText ?? ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            type={showPassword ? "text" : "password"}
            placeholder={`${column.headerName} megerősítés`}
            name={`${column.field}-confirm`}
            autoComplete="off"
            onChange={(event) => {
              setConfirm(event.target.value ?? "");
              return formik.handleChange;
            }}
            value={confirm ?? ""}
            error={!!formFieldError(formik, column, isFormDirty)}
            helperText={errorText ? errorText : column.helperText ?? ""}
          />
        </FormControl>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      </div>
    </div>
  );
};

const PasswordInput = forwardRef(InputForwardRef);

PasswordInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default PasswordInput;
