import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import {
  getCounties,
  getFieldsOfWorkCategories,
} from "../../../services/dictionary";
import {
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { useFormik } from "formik";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";

const PreferenceFormForwardRef = (props, ref) => {
  const { formik } = props;

  useImperativeHandle(ref, () => ({
    submit() {
      return formik.values;
    },
  }));

  return (
    <>
      <form
        noValidate="novalidate"
        className="form"
        onSubmit={formik.handleSubmit}
      >
        {props.children}
      </form>
    </>
  );
};
const PreferenceForm = forwardRef(PreferenceFormForwardRef);
PreferenceForm.propTypes = {
  formik: PropTypes.object,
};

const PreferenceForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit, options } = props;
  const formRefs = useRef([]);

  const [selectedCountyPreferences, setSelectedCountyPreferences] = useState(
    []
  );
  const [
    selectedFieldsOfWorkCategoryPreferences,
    setSelectedFieldsOfWorkCategoryPreferences,
  ] = useState([]);

  const isDense = true;

  const formik = useFormik({
    initialValues: {
      userCountyPreferences: [],
      userFieldsOfWorkCategoryPreferences: [],
    },
    onSubmit: (values, formikBag) => {
      console.log("preference values", values);
    },
  });

  const columns = [
    {
      field: "userCountyPreferences",
      fieldType: "checkbox",
      fieldModel: "userCountyPreferences",
      headerName: "",
    },
    {
      field: "userFieldsOfWorkCategoryPreferences",
      fieldType: "checkbox",
      fieldModel: "userFieldsOfWorkCategoryPreferences",
      headerName: "",
    },
  ];

  useEffect(() => {
    const selectedFieldsOfWorkCategories = [];
    (user.userFieldsOfWorkCategoryPreferences ?? []).map((item) => {
      const found = options.fieldsOfWorkCategories.find(
        (licence) => licence.id === item.fieldsOfWorkCategoryId
      );
      if (found) {
        selectedFieldsOfWorkCategories.push(found);
      }
      setSelectedFieldsOfWorkCategoryPreferences(
        selectedFieldsOfWorkCategories
      );
    });

    const selectedCounties = [];
    (user.userCountyPreferences ?? []).map((item) => {
      const found = options.counties.find(
        (licence) => licence.id === item.countyId
      );
      if (found) {
        selectedCounties.push(found);
      }
      setSelectedCountyPreferences(selectedCounties);
    });
  }, [options]);

  useEffect(() => {
    formik.setFieldValue(
      "userCountyPreferences",
      selectedCountyPreferences.map((item) => {
        return {
          countyId: item.id,
        };
      })
    );
  }, [selectedCountyPreferences]);

  useEffect(() => {
    formik.setFieldValue(
      "userFieldsOfWorkCategoryPreferences",
      selectedFieldsOfWorkCategoryPreferences.map((item) => {
        return {
          fieldsOfWorkCategoryId: item.id,
        };
      })
    );
  }, [selectedFieldsOfWorkCategoryPreferences]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  return (
    <Grid container spacing={3}>
      {columns.length > 0 && (
        <>
          <Grid md={12}>
            <div className={`card ${isDense ? "border-0" : ""}`}>
              <div className={`card-body ${isDense ? "p-0" : "py-3"}`}>
                <div className="collapse show">
                  <React.Fragment>
                    {!isEditable && selectedCountyPreferences.length > 0 && (
                      <>
                        <FormLabel
                          className={"col-form-label fw-bold fs-6 d-block"}
                        >
                          Megyék
                        </FormLabel>
                        {selectedCountyPreferences.map((item, index) => (
                          <Chip
                            key={index}
                            className={"me-3"}
                            label={item.name}
                            variant="outlined"
                          />
                        ))}
                      </>
                    )}
                    {isEditable && (
                      <PreferenceForm
                        formik={formik}
                        ref={(el) => (formRefs.current[0] = el)}
                      >
                        <div className={`card-body ${isDense ? "p-0" : "p-9"}`}>
                          <FormControl component="fieldset">
                            <FormLabel
                              className={"col-form-label fw-bold fs-6"}
                            >
                              Megyék
                            </FormLabel>
                            <FormGroup aria-label="countyPreferences" row>
                              {options.counties.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  value="end"
                                  control={
                                    <Checkbox
                                      name="countyId[]"
                                      checked={
                                        !!selectedCountyPreferences.find(
                                          (selected) => selected.id === item.id
                                        )
                                      }
                                      onChange={(event) => {
                                        if (
                                          !event.target.checked &&
                                          selectedCountyPreferences.find(
                                            (selected) =>
                                              selected.id === item.id
                                          )
                                        ) {
                                          const newSelectedCountyPreferences =
                                            selectedCountyPreferences.filter(
                                              (selected) =>
                                                selected.id !== item.id
                                            );
                                          setSelectedCountyPreferences(
                                            newSelectedCountyPreferences
                                          );
                                        } else if (
                                          event.target.checked &&
                                          !selectedCountyPreferences.find(
                                            (selected) =>
                                              selected.id === item.id
                                          )
                                        ) {
                                          setSelectedCountyPreferences([
                                            ...selectedCountyPreferences,
                                            options.counties.find(
                                              (licence) =>
                                                licence.id === item.id
                                            ),
                                          ]);
                                        }
                                        // return formik.handleChange;
                                      }}
                                      value={item.id}
                                    />
                                  }
                                  label={item.name}
                                  labelPlacement="end"
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </div>
                      </PreferenceForm>
                    )}
                  </React.Fragment>
                  <Divider sx={{ display: "block", my: 3 }} />
                  <React.Fragment>
                    {!isEditable &&
                      selectedFieldsOfWorkCategoryPreferences.length > 0 && (
                        <>
                          <FormLabel
                            className={"col-form-label fw-bold fs-6 d-block"}
                          >
                            Iparágak
                          </FormLabel>
                          {selectedFieldsOfWorkCategoryPreferences.map(
                            (item, index) => (
                              <Chip
                                key={index}
                                className={"me-3"}
                                label={item.name}
                                variant="outlined"
                              />
                            )
                          )}
                        </>
                      )}
                    {isEditable && (
                      <PreferenceForm
                        formik={formik}
                        ref={(el) => (formRefs.current[0] = el)}
                      >
                        <div className={`card-body ${isDense ? "p-0" : "p-9"}`}>
                          <FormControl component="fieldset">
                            <FormLabel
                              className={"col-form-label fw-bold fs-6"}
                            >
                              Iparágak
                            </FormLabel>
                            <FormGroup
                              aria-label="fieldsOfWorkCategoryPreferences"
                              row
                            >
                              {options.fieldsOfWorkCategories.map(
                                (item, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value="end"
                                    control={
                                      <Checkbox
                                        name="fieldsOfWorkCategoryId[]"
                                        checked={
                                          !!selectedFieldsOfWorkCategoryPreferences.find(
                                            (selected) =>
                                              selected.id === item.id
                                          )
                                        }
                                        onChange={(event) => {
                                          if (
                                            !event.target.checked &&
                                            selectedFieldsOfWorkCategoryPreferences.find(
                                              (selected) =>
                                                selected.id === item.id
                                            )
                                          ) {
                                            const newSelectedFieldsOfWorkCategoryPreferences =
                                              selectedFieldsOfWorkCategoryPreferences.filter(
                                                (selected) =>
                                                  selected.id !== item.id
                                              );
                                            setSelectedFieldsOfWorkCategoryPreferences(
                                              newSelectedFieldsOfWorkCategoryPreferences
                                            );
                                          } else if (
                                            event.target.checked &&
                                            !selectedFieldsOfWorkCategoryPreferences.find(
                                              (selected) =>
                                                selected.id === item.id
                                            )
                                          ) {
                                            setSelectedFieldsOfWorkCategoryPreferences(
                                              [
                                                ...selectedFieldsOfWorkCategoryPreferences,
                                                options.fieldsOfWorkCategories.find(
                                                  (licence) =>
                                                    licence.id === item.id
                                                ),
                                              ]
                                            );
                                          }
                                          // return formik.handleChange;
                                        }}
                                        value={item.id}
                                      />
                                    }
                                    label={item.name}
                                    labelPlacement="end"
                                  />
                                )
                              )}
                            </FormGroup>
                          </FormControl>
                        </div>
                      </PreferenceForm>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};
const Preference = forwardRef(PreferenceForwardRef);
Preference.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default Preference;
