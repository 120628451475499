import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FilterIntervalForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      if (params.getFilter) {
        return params.getFilter([valueFrom, valueTo]);
      } else if (valueFrom || valueTo) {
        filter[params.field] = { between: [valueFrom ?? 0, valueTo ?? 0] };
      }
      return filter;
    },
  }));

  const [valueFrom, setValueFrom] = useState("");
  const [valueTo, setValueTo] = useState("");

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={` col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row `}>
        <Grid
          container
          alignItems={"center"}
          sx={{ minWidth: 200, width: "100%" }}
        >
          <Grid>
            <TextField
              type="text"
              name={`${params.field}-from`}
              autoComplete="off"
              onChange={(event) => {
                setValueFrom(event.target.value ?? "");
              }}
              value={valueFrom}
              sx={{ width: 60 }}
            />
          </Grid>
          <Grid> - </Grid>
          <Grid>
            <TextField
              type="text"
              name={`${params.field}-to`}
              autoComplete="off"
              onChange={(event) => {
                setValueTo(event.target.value ?? "");
              }}
              value={valueTo}
              sx={{ width: 60 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const FilterInterval = forwardRef(FilterIntervalForwardRef);

FilterInterval.propTypes = {
  params: PropTypes.object,
};

export default FilterInterval;
