import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useDictionary } from "../../../services/dictionary";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

const FilterComputerSkillForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = { userComputerSkills: {} };
      filter.userComputerSkills[operator] = [];
      computerSkillCategoryIds.map((categoryId, index) => {
        const filterRow = {};
        if (categoryId) {
          filterRow.computerSkillCategoryId = categoryId;
          if (computerSkillIds[index]) {
            filterRow.computerSkillId = computerSkillIds[index];
          }
          if (computerSkillLevels[index]) {
            const currentLevels = [];
            for (let i = computerSkillLevels[index]; i <= 4; i++) {
              currentLevels.push(i);
            }
            filterRow.level = { inq: currentLevels };
          }
          filter.userComputerSkills[operator].push(filterRow);
        }
      });
      return filter;
    },
  }));

  const [operator, setOperator] = useState("or");
  const [computerSkillCategoryIds, setComputerSkillCategoryIds] = useState([0]);
  const [computerSkillIds, setComputerSkillIds] = useState([]);
  const [computerSkillLevels, setComputerSkillLevels] = useState([]);

  const levels = params.levels ?? [];

  let computerSkillCategories = useDictionary("computerSkillCategories");
  useEffect(() => {
    computerSkillCategories = [
      ...[{ id: 0, name: "Összes" }],
      ...computerSkillCategories,
    ];
  }, [computerSkillCategories]);

  let computerSkills = useDictionary("computerSkills");
  useEffect(() => {
    computerSkills = [...[{ id: 0, name: "Összes" }], ...computerSkills];
  }, [computerSkills]);

  const changeCategoryId = (index, categoryId) => {
    const newCategoryIds = JSON.parse(JSON.stringify(computerSkillCategoryIds));
    newCategoryIds[index] = categoryId;
    setComputerSkillCategoryIds(newCategoryIds);
  };

  const handleAddRow = () => {
    setComputerSkillCategoryIds([...computerSkillCategoryIds, 0]);
  };

  const handleDeleteRow = (toDelete) => {
    setComputerSkillCategoryIds(
      computerSkillCategoryIds.filter((item, index) => index !== toDelete)
    );
  };

  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid>
        <label className={`col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <Grid container>
          <Grid>
            <FormControl disabled={computerSkillCategoryIds.length < 2}>
              <FormLabel id="group-label">
                A felsorolt területek közül ...
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="group-label"
                name="radio-buttons-group"
                value={operator}
                onChange={(event) => setOperator(event.target.value)}
              >
                <FormControlLabel
                  value="and"
                  control={<Radio />}
                  label="...mindegyik"
                />
                <FormControlLabel
                  value="or"
                  control={<Radio />}
                  label="...bármelyik"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {computerSkillCategoryIds.map((computerSkillCategoryId, index) => (
          <Grid key={index} container spacing={2} alignItems={"center"}>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Kategória</InputLabel>
                <Select
                  name={`${params.field}-computer-skill-categories[]`}
                  label={"Kategória"}
                  onChange={(event) => {
                    changeCategoryId(index, event.target.value ?? "");
                  }}
                  value={computerSkillCategoryId}
                  sx={{ minWidth: 200 }}
                >
                  {computerSkillCategories.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Ismeret</InputLabel>
                <Select
                  name={`${params.field}-computer-skill[]`}
                  label={"Ismeret"}
                  onChange={(event) => {
                    const newComputerSkillIds = JSON.parse(
                      JSON.stringify(computerSkillIds)
                    );
                    newComputerSkillIds[index] = event.target.value ?? "";
                    setComputerSkillIds(newComputerSkillIds);
                  }}
                  value={computerSkillIds[index] ?? 0}
                  sx={{ minWidth: 100 }}
                >
                  <MenuItem key={0} value={0}>
                    Összes
                  </MenuItem>
                  {(computerSkillCategoryId
                    ? computerSkills.filter(
                        (item) =>
                          item.computerSkillCategoryId ===
                          computerSkillCategoryId
                      )
                    : computerSkills
                  ).map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel>Szint</InputLabel>
                <Select
                  name={`${params.field}[level][]`}
                  label={"Szint"}
                  onChange={(event) => {
                    const newValues = JSON.parse(
                      JSON.stringify(computerSkillLevels)
                    );
                    newValues[index] = event.target.value ?? "";
                    setComputerSkillLevels(newValues);
                  }}
                  value={computerSkillLevels[index] ?? ""}
                >
                  {levels.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid>
              {index === computerSkillCategoryIds.length - 1 && (
                <IconButton color="success" onClick={() => handleAddRow()}>
                  <PlaylistAddIcon />
                </IconButton>
              )}
              {index !== computerSkillCategoryIds.length - 1 && (
                <IconButton
                  color="error"
                  onClick={() => handleDeleteRow(index)}
                >
                  <PlaylistRemoveIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
const FilterComputerSkill = forwardRef(FilterComputerSkillForwardRef);

FilterComputerSkill.propTypes = {
  params: PropTypes.object,
};

export default FilterComputerSkill;
