import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Divider, Paper, Typography } from "@mui/material";
import { fetchStrapiWrapper } from "../../../services/fetch-strapi-wrapper";
import parse from "html-react-parser";

Informations.propTypes = {};

function Informations(props) {
  const [content, setContent] = useState({
    Title: "",
    Content: "",
    AlertContent: "",
  });

  useEffect(() => {
    fetchStrapiWrapper
      .get("/admin-informations")
      .then((response) => {
        setContent(response.data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (
      content.AlertContent &&
      content.AlertContent.replace(/<\/?[^>]+(>|$)|\s/g, "") === ""
    ) {
      setContent({ ...content, AlertContent: "" });
    }
    return () => {};
  }, [content]);

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant={"h3"}>{content.Title}</Typography>
      <Divider sx={{ my: 3 }}></Divider>
      <div>{parse(content.Content)}</div>
      {content.AlertContent !== "" && (
        <Alert sx={{ mx: -3, mb: -3 }} severity="error">
          {parse(content.AlertContent)}
        </Alert>
      )}
    </Paper>
  );
}

export default Informations;
