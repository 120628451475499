import React, {useEffect, useRef, useState} from "react";
import {useRoles} from "../../services/auth";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2";
import {
    Autocomplete,
    Chip,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Stack,
    Switch,
    Tooltip,
    TextField,
    Typography, CircularProgress, Snackbar,
} from "@mui/material";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/hu";
import {dashboardPositions, dashboardList, dashboardEmployees} from "../../services/dashboard";
import {getEmployees} from "../../services/address-book";
import {DataGrid} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {blue, red} from "@mui/material/colors";
import {darken, lighten, styled} from "@mui/material/styles";
import {grey} from "@mui/material/colors";

function Leader() {
    const [employees, setEmployees] = useState([]);
    const [inputValue, setInputValue] = React.useState("");
    const [user, setUser] = useState(null);
    const [positionFilter, setPositionFilter] = useState('');
    const [from, setFrom] = useState(moment().add(-30, "days"));
    const [to, setTo] = useState(moment());
    const [mdPositions, setMdPositions] = useState(true);
    const [hcPositions, setHcPositions] = useState(true);
    const [groupBy, setGroupBy] = useState("employees"); // employees || positions
    const [sortBy, setSortBy] = useState([{field: "id", sort: "desc"}]);
    const [posLoading, setPosLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [error, setError] = useState('');

    const [rawData, setRawData] = useState([]);
    const [sumRow, setSumRow] = useState({});
    const [posData, setPosData] = useState([]);
    const [userData, setUserData] = useState({});

    const roles = useRoles();
    const controller = new AbortController();

    // const refController = useRef(controller);

    const refPosLimit = useRef(100);
    const listDataLabels = {
        positionsHeader: {header: "Pozíciók"},
        positionsPublished: {
            primary: "Publikált pozíciók",
            secondary: "",
            color: "default",
        },
        positionsApplied: {
            primary: "Ezekre jelentkeztek",
            secondary: "",
            color: "default",
        },
        positionsCompanyUser: {
            primary: "Megrendelői hozzáférés",
            secondary: "",
            color: "default",
        },
        usersHeader: {header: "Felhasználók"},
        confirmedUsers: {primary: "Megerősítve", secondary: "", color: "default"},
        registered: {primary: "Regisztrált", secondary: "", color: "default"},
        applied: {primary: "Jelentkezett", secondary: "", color: "success"},
        loggedin: {primary: "Belépett", secondary: "", color: "primary"},
        chosen: {primary: "Kiválasztva", secondary: "", color: "error"},
        activeUsers: {
            primary: "Aktív",
            secondary: "regisztrált, belépett vagy jelentkezett",
            color: "warning",
        },
    };
    const getSite = () => {
        let site = "all";
        if (mdPositions && !hcPositions) {
            site = "md";
        } else if (hcPositions && !mdPositions) {
            site = "hc";
        }
        return site;
    };

    useEffect(() => {
        if (roles.includes("hca_m")) {
            getEmployees({
                status: 30,
                isActive: true,
            })
                .then((response) => {
                    setEmployees(response.data);
                })
                .catch((err) => console.error(err));
        }
    }, [roles]);

    useEffect(() => {
        refPosLimit.current = 100;
        const fn = (groupBy === "employees") ? dashboardEmployees : dashboardPositions;
        setPosLoading(true);
        setError('');
        fn(
            getSite(),
            user?.id ?? 0,
            positionFilter,
            from ? from.format("YYYY-MM-DD") : null,
            to ? to.format("YYYY-MM-DD") : null,
            controller
        )
            .then((response) => {
                setError('');
                setRawData(response.data);
                const tmpSumRow = {
                    id: 0,
                    name: `@ Összesen ${response.data.length} ${(groupBy === "employees") ? 'munkatárs' : 'pozíció'}`,
                    positionUsers: [],
                    allomanyban: 0,
                    kivalasztott: 0,
                    jelentkezo: 0,
                    bemutatott: 0,
                    allomanyban_megrendelt: 0,
                    kivalasztott_megrendelt: 0,
                    bemutatott_jelentkezo: 0,
                    allomanyban_jelentkezo: 0,
                    kivalasztott_jelentkezo: 0,
                    allomanyban_bemutatott: 0,
                    kivalasztott_bemutatott: 0,
                    teljesites: 0,
                    kezeles: 0,
                };
                response.data.map((item) => {
                    tmpSumRow.positionUsers = [
                        ...tmpSumRow.positionUsers,
                        ...(item.positionUsers ?? []),
                    ];
                    tmpSumRow.allomanyban += item.allomanyban ?? 0;
                    tmpSumRow.kivalasztott += item.kivalasztott ?? 0;
                    tmpSumRow.jelentkezo += item.jelentkezo ?? 0;
                    tmpSumRow.bemutatott += item.bemutatott ?? 0;
                });
                setSumRow(tmpSumRow);
                // console.log('sumRow', tmpSumRow);
                // if (response.data.length > refPosLimit.current) {
                //     const erased = response.data.length - refPosLimit.current - 2;
                //     response.data = response.data.slice(0, refPosLimit.current - 2);
                //     response.data = [
                //         ...response.data,
                //         {
                //             id: -1,
                //             name: `... további ${erased} ${(groupBy === "employees") ? 'munkatárs' : 'pozíció'}`,
                //         },
                //     ];
                // }
                // console.log('posData', [...response.data, tmpSumRow]);
                setPosData([...response.data, tmpSumRow]);
                setPosLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setError('Hiba történt az adatok betöltése során');
                setPosLoading(false);
            });

        return () => {
            try {
                controller.abort();
            } catch (e) {
            }
            setPosData([]);
            setPosLoading(false);
        };
    }, [groupBy, user, positionFilter, from, to, mdPositions, hcPositions]);

    useEffect(() => {
        setUserLoading(true);
        dashboardList(
            getSite(),
            user?.id ?? 0,
            positionFilter,
            from ? from.format("YYYY-MM-DD") : null,
            to ? to.format("YYYY-MM-DD") : null,
            controller
        )
            .then((response) => {
                setError('');
                setUserData(response.data);
                setUserLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setError('Hiba történt az adatok betöltése során');
                setUserLoading(false);
            });
        return () => {
            try {
                controller.abort();
            } catch (e) {
            }
            setUserLoading(false);
        };
    }, [user, positionFilter, from, to, mdPositions, hcPositions]);
/*

    useEffect(() => {
        // console.log('sorting', rawData);
        if (rawData.length) {
            // refPosLimit.current = rawData.length > 99 ? 99 : rawData.length;

            const attr = sortBy?.[0]?.field ?? "id";
            const asc =
                sortBy?.[0]?.sort === "asc" ? -1 : sortBy?.[0]?.sort === "desc" ? 1 : 1;
            const desc = asc === -1 ? 1 : -1;
            let ordered = rawData.sort((a, b) => {
                if (a[attr] < b[attr]) {
                    return asc;
                } else if (a[attr] > b[attr]) {
                    return desc;
                } else {
                    return 0;
                }
            });
            // let max = 0;
            // let min = 0;
            // rawData.map((item) => {
            //   if (item[attr] > max) {
            //     max = item[attr];
            //   }
            //   if (item[attr] < min) {
            //     min = item[attr];
            //   }
            // });
            if (ordered.length > refPosLimit.current) {
                // const erased = ordered.length - refPosLimit.current - 2;
                ordered = ordered.slice(0, refPosLimit.current - 2);
                ordered = [
                    ...ordered,
                    // {
                    //   id: -1,
                    //   name: `... további ${erased} pozíció`,
                    //   [attr]: sortBy?.[0]?.sort === "asc" ? max + 1 : min - 1,
                    // },
                ];
            }
            // const orderedSumRow = Object.assign({}, sumRow);
            // orderedSumRow[attr] = sortBy?.[0]?.sort === "asc" ? max + 2 : min - 2;
            // setPosData([...ordered, orderedSumRow]);
            // console.log('sumRow', sumRow);
            setPosData([...ordered, sumRow]);
        }
    }, [sortBy, refPosLimit.current]);
*/

    const posColumns = [
            {
                field: "id",
                headerName: "id",
                width: 80,
                valueGetter: (params) => {
                    return params.row.id <= 0 ? "" : params.row.id;
                },
            },
            {
                field: "name",
                headerName: "Pozíció megnevezése",
                width: 200,
            },
            {
                field: "kivalasztott",
                headerName: "Kiválasztott",
                flex: true,
                disableColumnMenu: true,
                valueGetter: (params) => {
                    if (params.row.id < 0) {
                        return "";
                    }
                    return params.row.kivalasztott;
                },
            },
            {
                field: "jelentkezo",
                headerName: "Összes jelentkező",
                flex: true,
                disableColumnMenu: true,
                valueGetter: (params) => {
                    if (params.row.id < 0) {
                        return "";
                    }
                    return params.row.jelentkezo;
                },
            },
            {
                field: "bemutatott",
                headerName: "Bemutatott",
                description: "Összes bemutatott",
                flex: true,
                disableColumnMenu: true,
                valueGetter: (params) => {
                    if (params.row.id < 0) {
                        return "";
                    }
                    return params.row.bemutatott;
                },
            },
            {
                field: "kivalasztott_megrendelt",
                // headerName: "Áll. / rend.",
                // description: "Állományban / megrendelt %",
                renderHeader: (params) => (
                    <Tooltip title={'Kiválasztott / Megrendelt %'}><span className={'custom-data-grid-header'}>
                    {'Kiválasztott'}
                        <hr/>
                        {'Megrendelt'}
                </span></Tooltip>
                ),
                flex: true,
                disableColumnMenu: true,
                valueGetter: (params) => {
                    if (params.row.id <= 0) {
                        return "";
                    }
                    return params.row.kivalasztott_megrendelt;
                },
                valueFormatter: (params) => {
                    return `${params.value} %`;
                }
            },
            {
                field: "bemutatott_jelentkezo",
                // headerName: "Bem. / jel.",
                // description: "Bemutatott / jelentkező %",
                renderHeader: (params) => (
                    <Tooltip title={'Bemutatott / Jelentkező %'}><span className={'custom-data-grid-header'}>
                    {'Bemutatott'}
                        <hr/>
                        {'Jelentkező'}
                </span></Tooltip>
                ),
                flex: true,
                disableColumnMenu: true,
                valueGetter: (params) => {
                    if (params.row.id <= 0) {
                        return "";
                    }
                    return params.row.bemutatott_jelentkezo;
                },
                valueFormatter: (params) => {
                    return `${params.value} %`;
                }
            },
            {
                field: "kivalasztott_jelentkezo",
                // headerName: "Áll. / jel.",
                // description: "Állományban / jelentkező %",
                flex: true,
                disableColumnMenu: true,
                renderHeader: (params) => (
                    <Tooltip title={'Kiválasztott / Jelentkező %'}><span className={'custom-data-grid-header'}>
                    {'Kiválasztott'}
                        <hr/>
                        {'Jelentkező'}
                </span></Tooltip>
                ),
                valueGetter: (params) => {
                    if (params.row.id <= 0) {
                        return "";
                    }
                    return params.row.kivalasztott_jelentkezo;
                },
                valueFormatter: (params) => {
                    return `${params.value} %`;
                }
            },
            {
                field: "kivalasztott_bemutatott",
                // headerName: "Áll. / bem.",
                // description: "Állományban / bemutatott %",
                renderHeader: (params) => (
                    <Tooltip title={'Kiválasztott / Bemutatott %'}><span className={'custom-data-grid-header'}>
                    {'Kiválasztott'}
                        <hr/>
                        {'Bemutatott'}
                </span></Tooltip>
                ),
                flex: true,
                disableColumnMenu: true,
                valueGetter:
                    (params) => {
                        if (params.row.id <= 0) {
                            return "";
                        }
                        return params.row.kivalasztott_bemutatott;
                    },
                valueFormatter: (params) => {
                    return `${params.value} %`;
                }
            },
            {
                field: "teljesites",
                headerName:
                    "Teljesítés",
                description:
                    "Megrendelés teljesítésének időtartama (nap)",
                flex:
                    true,
                disableColumnMenu: true,
                valueGetter:
                    (params) => {
                        if (params.row.id <= 0) {
                            return "";
                        }
                        return params.row.teljesites;
                    },
            }
            ,
            {
                field: "kezeles",
                headerName:
                    "Kezelés",
                description:
                    "Jelentkezők kezelésének időtartama (nap)",
                flex:
                    true,
                disableColumnMenu: true,
                valueGetter:
                    (params) => {
                        if (params.row.id <= 0) {
                            return "";
                        }
                        return params.row.kezeles;
                    },
            }
            ,
            {
                field: "actions",
                headerName:
                    "Megnyitás",
                width:
                    90,
                align:
                    "right",
                headerAlign:
                    "right",
                sortable:
                    false,
                filterable:
                    false,
                disableColumnMenu:
                    true,
                renderCell:
                    (params) => {
                        if (params.row.id <= 0) {
                            return "";
                        }
                        return (
                            <>
                                <Link to={`/positions/${params.row.id}`} target={"_blank"}>
                                    <IconButton
                                        variant={"contained"}
                                        aria-label="megnyitás"
                                        size="large"
                                        className={"me-3"}
                                    >
                                        <OpenInNewIcon/>
                                    </IconButton>
                                </Link>
                            </>
                        );
                    },
            }
            ,
        ]
    ;

    const employeeColumns = [
        {
            field: "name",
            headerName: "Munkatárs neve",
            width: 200,
            valueGetter: (params) => {
                let ret = '';
                if (params.row.id <= 0) {
                    ret = params.row?.name;
                } else {
                    ret = params.row.id <= 0 ? "" : `${params.row.employee?.lastName ?? ''} ${params.row.employee?.firstName ?? ''}`;
                    if (ret.trim() === '' && params.row.id > 0) {
                        ret = `Hiányzó felhasználó: ${params.row.id}`;
                    }
                }
                return ret;
            },
        },
        {
            field: "positions",
            headerName: "Kezelt pozíciók",
            flex: true,
            valueGetter: (params) => {
                if (params.row.id < 0) {
                    return "";
                }
                return params.row.positions;
            },
        },
        {
            field: "allomanyban",
            headerName: "Felvett létszám",
            flex: true,
            valueGetter: (params) => {
                if (params.row.id < 0) {
                    return "";
                }
                return params.row.allomanyban;
            },
        },
        {
            field: "jelentkezo",
            headerName: "Összes jelentkező",
            flex: true,
            valueGetter: (params) => {
                if (params.row.id < 0) {
                    return "";
                }
                return params.row.jelentkezo;
            },
        },
        {
            field: "bemutatott",
            headerName: "Bemutatott",
            description: "Összes bemutatott",
            flex: true,
            valueGetter: (params) => {
                if (params.row.id < 0) {
                    return "";
                }
                return params.row.bemutatott;
            },
        },
        {
            field: "kivalasztott_megrendelt",
            // headerName: "Áll. / rend.",
            // description: "Állományban / megrendelt %",
            renderHeader: (params) => (
                <Tooltip title={'Kiválasztott / megrendelt %'}><span className={'custom-data-grid-header'}>
                    {'Kiválasztott'}
                    <hr/>
                    {'megrendelt'}
                </span></Tooltip>
            ),
            flex: true,
            valueGetter: (params) => {
                if (params.row.id <= 0) {
                    return "";
                }
                return params.row.kivalasztott_megrendelt;
            },
            valueFormatter: (params) => {
                return `${params.value} %`;
            }
            // sortComparator: nameAdminSortComparator,
        },
        {
            field: "bemutatott_jelentkezo",
            // headerName: "Bem. / jel.",
            // description: "Bemutatott / jelentkező %",
            flex: true,
            renderHeader: (params) => (
                <Tooltip title={'Bemutatott / Jelentkező %'}><span className={'custom-data-grid-header'}>
                    {'Bemutatott'}
                    <hr/>
                    {'Jelentkező'}
                </span></Tooltip>
            ),
            valueGetter: (params) => {
                if (params.row.id <= 0) {
                    return "";
                }
                return params.row.bemutatott_jelentkezo;
            },
            valueFormatter: (params) => {
                return `${params.value} %`;
            }
        },
        {
            field: "kivalasztott_jelentkezo",
            headerName: "Áll. / jel.",
            description: "Kiválasztott / jelentkező %",
            renderHeader: (params) => (
                <Tooltip title={'Kiválasztott / Jelentkező %'}><span className={'custom-data-grid-header'}>
                    {'Kiválasztott'}
                    <hr/>
                    {'Jelentkező'}
                </span></Tooltip>
            ),
            flex: true,
            valueGetter: (params) => {
                if (params.row.id <= 0) {
                    return "";
                }
                return params.row.kivalasztott_jelentkezo;
            },
            valueFormatter: (params) => {
                return `${params.value} %`;
            }
        },
        {
            field: "kivalasztott_bemutatott",
            // headerName: "Áll. / bem.",
            // description: "Állományban / bemutatott %",
            renderHeader: (params) => (
                <Tooltip title={'Kiválasztott / Bemutatott %'}><span className={'custom-data-grid-header'}>
                    {'Kiválasztott'}
                    <hr/>
                    {'Bemutatott'}
                </span></Tooltip>
            ),
            flex: true,
            valueGetter: (params) => {
                if (params.row.id <= 0) {
                    return "";
                }
                return params.row.kivalasztott_bemutatott;
            },
            valueFormatter: (params) => {
                return `${params.value} %`;
            }
        },
        {
            field: "teljesites",
            headerName: "Teljesítés",
            description: "Megrendelés teljesítésének időtartama (nap)",
            flex: true,
            valueGetter: (params) => {
                if (params.row.id <= 0) {
                    return "";
                }
                return params.row.teljesites;
            },
        },
        {
            field: "kezeles",
            headerName: "Kezelés",
            description: "Jelentkezők kezelésének időtartama (nap)",
            flex: true,
            valueGetter: (params) => {
                if (params.row.id <= 0) {
                    return "";
                }
                return params.row.kezeles;
            },
        },
    ];

    const getBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
    const getHoverBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
    const getSelectedBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
    const getSelectedHoverBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
    const StyledDataGrid = styled(DataGrid)(({theme}) => ({
        "& .row-md": {
            backgroundColor: getBackgroundColor(red[200], theme.palette.mode),
            "&:hover": {
                backgroundColor: getHoverBackgroundColor(red[200], theme.palette.mode),
            },
            "&.Mui-selected": {
                backgroundColor: getSelectedBackgroundColor(
                    red[200],
                    theme.palette.mode
                ),
                "&:hover": {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        red[200],
                        theme.palette.mode
                    ),
                },
            },
        },
        "& .row-hc": {
            backgroundColor: getBackgroundColor(blue[200], theme.palette.mode),
            "&:hover": {
                backgroundColor: getHoverBackgroundColor(blue[200], theme.palette.mode),
            },
            "&.Mui-selected": {
                backgroundColor: getSelectedBackgroundColor(
                    blue[200],
                    theme.palette.mode
                ),
                "&:hover": {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        blue[200],
                        theme.palette.mode
                    ),
                },
            },
        },
    }));

    return (
        <>
            <h1>Leader dash</h1>
            <Grid container direction={"column"} spacing={3} sx={{width: "100%"}}>
                <Grid>
                    <Grid container spacing={3} alignItems={"center"}>
                        <Grid sx={{flexGrow: 1}}>
                            <Grid container spacing={3}>
                                <Grid
                                    container
                                    alignItems={"center"}
                                    sx={{minWidth: 480, width: "100%"}}
                                >
                                    <Grid>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale="hu"
                                        >
                                            <DatePicker
                                                className={`form-control form-control-lg form-control-solid`}
                                                name={`date-from`}
                                                autoComplete="off"
                                                value={from}
                                                minDate={moment(to).subtract(1, 'year')}
                                                maxDate={moment(to)}
                                                onChange={(newValue) => {
                                                    console.log('date', newValue);
                                                    setFrom(newValue);
                                                }}
                                                sx={{width: 180}}
                                                // slotProps={{
                                                //     field: { clearable: true, onClear: () => setFrom(null) },
                                                // }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid> - </Grid>
                                    <Grid>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale="hu"
                                        >
                                            <DatePicker
                                                className={`form-control form-control-lg form-control-solid`}
                                                name={`date-to`}
                                                autoComplete="off"
                                                value={to}
                                                // minDate={moment()}
                                                maxDate={moment(from).add(1, 'year')}
                                                onChange={(newValue) => {
                                                    setTo(newValue);
                                                }}
                                                sx={{width: 180}}
                                                // slotProps={{
                                                //     field: { clearable: true, onClear: () => setTo(null) },
                                                // }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={{flexGrow: 1}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="error"
                                        onClick={(event) => {
                                            if (event.target.checked) {
                                                setMdPositions(true);
                                            } else {
                                                setMdPositions(false);
                                            }
                                        }}
                                        defaultChecked={true}
                                    />
                                }
                                label="MD"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        onClick={(event) => {
                                            if (event.target.checked) {
                                                setHcPositions(true);
                                            } else {
                                                setHcPositions(false);
                                            }
                                        }}
                                        defaultChecked={true}
                                    />
                                }
                                label="HC"
                            />
                        </Grid>
                        <Grid sx={{flexGrow: 1}}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Munkatárs<br/>szerint</Typography>
                                <Switch
                                    color={"success"}
                                    onClick={(event) => {
                                        setPosData([]);
                                        if (event.target.checked) {
                                            setGroupBy("positions");
                                        } else {
                                            setGroupBy("employees");
                                        }
                                    }}
                                    defaultChecked={false}
                                />
                                <Typography>Pozíció<br/>szerint</Typography>
                            </Stack>
                        </Grid>
                        <Grid>
                            {roles.includes("hca_m") && employees.length > 0 && (
                                <Autocomplete
                                    disablePortal
                                    value={user}
                                    onChange={(event, newValue) => {
                                        setUser(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    getOptionLabel={(option) =>
                                        `${option.lastName} ${option.firstName}`
                                    }
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {`${option.lastName} ${option.firstName}`}
                                            </li>
                                        );
                                    }}
                                    id="combo-employees"
                                    options={employees}
                                    sx={{width: 300, marginBottom: 3}}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Alkalmazott"/>
                                    )}
                                />
                            )}
                            {roles.includes("hca_m") && (
                                <TextField label="Pozícióra szűrés" value={positionFilter} onChange={(e) => {
                                    setPositionFilter(e.target.value);
                                }} helperText="Adjon meg azonosítót vagy pozíció név részletet!"/>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid container columns={12} spacing={3} sx={{width: "100%"}}>
                        <Grid item={"true"} lg={9}>
                            {posLoading && (<CircularProgress />)}
                            {!posLoading && <StyledDataGrid
                                rows={posData}
                                columns={(groupBy === "employees") ? employeeColumns : posColumns}
                                // hideFooterPagination={true}
                                initialState={{
                                    pagination: {
                                        paginationModel: {pageSize: refPosLimit.current, page: 0},
                                    },
                                }}
                                // sortingMode="server"
                                // sortModel={sortBy}
                                // onSortModelChange={(params, event, details) => {
                                //     console.log("onSortModelChange", params, event, details);
                                //     setSortBy(params);
                                // }}
                                getRowClassName={(params) => {
                                    if (params.row.type === 10) {
                                        return "row-hc";
                                    } else if (params.row.type === 20) {
                                        return "row-md";
                                    }
                                }}
                            />}
                        </Grid>
                        <Grid item={"true"} lg={3}>
                            {userLoading && (<CircularProgress />)}
                            {!userLoading && Object.keys(userData).length > 0 && (
                                <>
                                    {from === null && to === null && (
                                        <Typography sx={{display: 'block', textAlign: 'center'}} variant={'caption'}>Dátum
                                            nélkül az utolsó 1 hónap adatai</Typography>)}
                                    <List sx={{width: "100%"}}>
                                        {Object.keys(listDataLabels).map((attr, index) => (
                                            <React.Fragment key={index}>
                                                {listDataLabels[attr].header && (
                                                    <ListSubheader>
                                                        {listDataLabels[attr].header}
                                                    </ListSubheader>
                                                )}
                                                {!listDataLabels[attr].header && (
                                                    <ListItem
                                                        key={attr}
                                                        secondaryAction={
                                                            <Chip
                                                                label={userData[attr] ?? 0}
                                                                color={listDataLabels[attr].color ?? "primary"}
                                                            />
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={listDataLabels[attr].primary}
                                                            secondary={
                                                                listDataLabels[attr].secondary ?? undefined
                                                            }
                                                        />
                                                    </ListItem>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                message={error}
            />
        </>
    );
}

export default Leader;
