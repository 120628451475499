import React from "react";
import PropTypes from "prop-types";
import Users from "./Users";

UserHcs.propTypes = {};

function UserHcs(props) {
  return <Users type={"hc"} />;
}

export default UserHcs;
