import React, { useEffect, useState } from "react";
import {
  getOffices,
  getOffice,
  setOffice,
  deleteOffice,
  getOfficeCount,
  getUserEmployee,
  getOfficeSales,
  getOfficeProjectManagers,
} from "../../services/address-book";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";

Offices.propTypes = {};

function Offices(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);
  const permissions = usePermission("office", id ?? 0);

  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const [defaultSalesUsers, setDefaultSalesUsers] = useState([]);
  const [defaultProjectManagerUsers, setDefaultProjectManagerUsers] = useState(
    []
  );

  const types = [
    { id: 10, value: 10, name: "kiemelt iroda" },
    { id: 20, value: 20, name: "iroda" },
    { id: 30, value: 30, name: "képviselet" },
  ];
  useEffect(() => {
    if (isForm && id) {
      getOfficeSales(id)
        .then((response) => {
          setDefaultSalesUsers(response.data);
        })
        .catch((err) => console.error(err));
      getOfficeProjectManagers(id)
        .then((response) => {
          setDefaultProjectManagerUsers(response.data);
        })
        .catch((err) => console.error(err));
    }
  }, [isForm, id]);

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Név",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "slug",
      fieldType: "text",
      headerName: "URL",
      hide: true,
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "type",
      fieldType: "select",
      defaultValue: 20,
      headerName: "Típus",
      validation: {
        required: true,
      },
      width: 100,
      valueGetter: (params) => {
        let value = `ismeretlen (${params.row.type})`;
        switch (params.row.type) {
          case 10:
            value = "kiemelt iroda";
            break;
          case 20:
            value = "iroda";
            break;
          case 30:
            value = "képviselet";
            break;
        }
        return value;
      },
    },
    {
      field: "isActive",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Státusz",
      width: 100,
      valueGetter: (params) => {
        return params.row.isActive ? "aktív" : "inaktív";
      },
    },
    {
      field: "address",
      fieldType: "text",
      headerName: "Cím",
    },
    {
      field: "lat",
      fieldType: "text",
      hide: true,
      headerName: "GPS (szélességi, latitude, 45.x-49.x)",
    },
    {
      field: "lng",
      fieldType: "text",
      hide: true,
      headerName: "GPS (hosszúsági, longitude, 16.x-22.x)",
    },
    {
      field: "phone",
      fieldType: "text",
      headerName: "Telefonszám",
      hide: true,
    },
    {
      field: "emailHc",
      fieldType: "text",
      headerName: "E-mail (HC)",
      hide: true,
    },
    {
      field: "emailMd",
      fieldType: "text",
      headerName: "E-mail (MD)",
      hide: true,
    },
    {
      field: "openTime",
      fieldType: "texteditor",
      headerName: "Nyitva tartás",
      hide: true,
    },
    {
      field: "description",
      fieldType: "texteditor",
      headerName: "Leírás",
      hide: true,
    },
    {
      field: "isHc",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "HC iroda?",
      hide: true,
    },
    {
      field: "isMd",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "MD iroda?",
      hide: true,
    },
    {
      field: "defaultSalesUserId",
      fieldType: "select",
      headerName: "Értékesítő",
      labelAttr: (item) => `${item.lastName} ${item.firstName}`,
      hide: true,
      submitGetter: (value) => {
        const found = defaultSalesUsers.find(
          (item) => item.id === parseInt(value)
        );
        if (found) {
          return found;
        }
        return null;
      },
    },
    {
      field: "defaultSalesUser",
      headerName: "Értékesítő",
      valueGetter: (params) => {
        let value = "";
        if (params.row.defaultSalesUser) {
          value = `${params.row.defaultSalesUser.lastName} ${params.row.defaultSalesUser.firstName}`;
        }
        return value;
      },
      submitGetter: (value) => {
        return null;
      },
    },
    {
      field: "defaultProjectManagerUserId",
      fieldType: "select",
      headerName: "Projektvezető",
      labelAttr: (item) => `${item.lastName} ${item.firstName}`,
      hide: true,
      submitGetter: (value) => {
        const found = defaultProjectManagerUsers.find(
          (item) => item.id === parseInt(value)
        );
        if (found) {
          return found;
        }
        return null;
      },
    },
    {
      field: "defaultProjectManagerUser",
      headerName: "Projektvezető",
      valueGetter: (params) => {
        let value = "";
        if (params.row.defaultProjectManagerUser) {
          value = `${params.row.defaultProjectManagerUser.lastName} ${params.row.defaultProjectManagerUser.firstName}`;
        }
        return value;
      },
      submitGetter: (value) => {
        return null;
      },
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Irodák",
    url: "/address-books/offices",
    item: "iroda",
  };

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          permissionParam={{ officeId: id }}
          columns={columns}
          getFunc={getOffice}
          setFunc={setOffice}
          types={types}
          defaultSalesUsers={defaultSalesUsers}
          defaultProjectManagerUsers={defaultProjectManagerUsers}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          isDictionary={true}
          getFunc={getOffices}
          getCountFunc={getOfficeCount}
          deleteFunc={deleteOffice}
          columns={columns}
          types={types}
          defaultSalesUsers={defaultSalesUsers}
          defaultProjectManagerUsers={defaultProjectManagerUsers}
        ></Lists>
      )}
    </>
  );
}

export default Offices;
