import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import {
  formGetValue,
  formOptions,
  formValueAttr,
  formLabelAttr,
  formSetValue,
  formError,
  formFieldError,
} from "./formUtils";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying, ...args } =
    props;

  const valueAttr = formValueAttr(column);
  const { labelAttr, labelAttrFn } = formLabelAttr(column);

  const options = formOptions(column, args);
  let formikValue = formik.values?.[column.field] ?? 0;
  if (formikValue && !options.find((item) => item.id === formikValue)) {
    formikValue = 0;
  }
  const [value, setValue] = useState(formikValue ?? 0);
  const [inited, setInited] = useState(false);

  useEffect(() => {
    let initValue = initialValue;
    if (
      !inited &&
      options.length &&
      initValue !== undefined &&
      initValue !== null
    ) {
      if (initValue && !options.find((item) => item.id === initValue)) {
        initValue = 0;
      }
      setValue(initValue);
    }
    if (options.length) {
      setInited(true);
    }
  }, [initialValue, options]);

  useEffect(() => {
    if (inited) {
      if (
        value &&
        options.length > 0 &&
        !options.find((item) => item.id === value)
      ) {
        setValue(0);
      }
    }
  }, [inited, options, value]);

  useEffect(() => {
    if (inited) {
      // console.log("select setValue", column.field, value);
      formSetValue(formik, column, value);
      dirtying();
      formError(formik, column, options);
    }
  }, [value]);

  return (
    <div className="row">
      <label
        className={`col-lg-4 col-form-label fw-bold fs-6 ${
          column.validation && "required"
        }`}
      >
        {column.headerName}
      </label>
      <div className="col-lg-8 fv-row">
        <FormControl
          sx={{ width: "100%" }}
          error={!!formFieldError(formik, column, isFormDirty)}
        >
          <Select
            name={column.field}
            id={column.field}
            required
            onChange={(event) => {
              setValue(event.target.value ?? 0);
              if (column.onChange) {
                column.onChange(event, column);
              }
            }}
            value={value}
          >
            {!options.find((item) => item.id === 0 || item.id === 0) && (
              <MenuItem value={0}>Kérlek válassz...</MenuItem>
            )}
            {options.map((item, index) => {
              const label = labelAttrFn ? labelAttrFn(item) : item[labelAttr];
              return (
                <MenuItem key={index} value={item[valueAttr]}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
          {!!formFieldError(formik, column, isFormDirty) && (
            <FormHelperText>
              {formFieldError(formik, column, isFormDirty)}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    </div>
  );
};

const SelectInput = forwardRef(InputForwardRef);

SelectInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default SelectInput;
