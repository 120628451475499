import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useEffect, useRef, useState } from "react";

const options = [
  {
    value: ["10", "20", "25", "30", "40", "50", "60+65+70", "90"],
    label: "Nem visszautasítottak",
  },
  { value: [""], label: "Mind" },
  { value: ["60+65+70"], label: "Állományban" },
  { value: ["10"], label: "Jelentkező" },
  { value: ["20"], label: "Potenciális" },
  { value: ["25"], label: "Továbbított" },
  { value: ["30"], label: "Interjú" },
  { value: ["40"], label: "Bemutatott" },
  { value: ["50"], label: "Kiválasztott" },
  { value: ["90"], label: "Parkolópályán" },
  { value: ["80"], label: "Visszautasított" },
];

ApplicantsExport.propTypes = {};

function ApplicantsExport(props) {
  const { handleChangeListFilter } = props;

  const [inited, setInited] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    handleChangeListFilter(options[selectedIndex], options[selectedIndex].label);
  };

  useEffect(() => {
    setInited(true);
  }, []);

  useEffect(() => {
    if (inited) {
      setOpen(false);
      handleClick();
    }
  }, [selectedIndex]);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="export button"
      >
        <Button onClick={handleClick}>{options[selectedIndex].label}</Button>
        <Button
          size="small"
          aria-controls={open ? "export-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10000,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="export-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default ApplicantsExport;
