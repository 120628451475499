import React, { forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FileBrowser, {
  RawFileBrowser,
  FileRenderers,
  FolderRenderers,
  Groupers,
  Icons,
} from "react-keyed-file-browser";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "font-awesome/css/font-awesome.min.css";
import "react-keyed-file-browser/dist/react-keyed-file-browser.css";

import {
  formError,
  formErrorMsg,
  formFieldClass,
  formFieldError,
  formGetValue,
  formSetValue,
} from "./formUtils";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";
import moment from "moment";
import { browseDir } from "../../../services/auth";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying } = props;

  // console.log("BrowserInput initialValue", initialValue);

  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("");
  const [inited, setInited] = useState(false);

  const refBrowser = useRef(null);

  useEffect(() => {
    if (column.path) {
      browseDir(column.path)
        .then((response) => {
          response.data.map((item) => {
            item.modified = +moment(item.modified);
            item.thumbnail_url = item.url;
          });
          setFiles(response.data);
        })
        .catch((err) => console.error(err));
    }
  }, [column]);

  useEffect(() => {
    const initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      setValue(initValue);
      if (refBrowser.current) {
        const image = initValue.replace(column.path, "");
        refBrowser.current.selectFiles([image]);
      }
    }
    if (refBrowser.current) {
      setInited(true);
    }
  }, [refBrowser, initialValue, column]);

  // useEffect(() => {
  //   const formValue = formGetValue(formik, column) ?? "";
  //   setValue(formValue);
  //   if (refBrowser.current) {
  //     if (formValue && !inited) {
  //       const image = formValue.replace(column.path, "");
  //       refBrowser.current.selectFiles([image]);
  //     }
  //     setInited(true);
  //   }
  //   return () => {
  //     setValue("");
  //   };
  // }, [refBrowser, formik, column]);

  useEffect(() => {
    if (inited) {
      formSetValue(formik, column, value);
      dirtying();
      formError(formik, column);
    }
  }, [value]);

  // todo: kesobb belerakni a kepfeltoltest es konyvtar letrehozast
  const handleCreateFolder = (key) => {
    setFiles([...files, { key }]);
  };

  const handleCreateFiles = (createdFiles, prefix) => {
    console.log("handleCreateFiles", createdFiles, prefix);

    const newFiles = createdFiles.map((file) => {
      let newKey = prefix;
      if (
        prefix !== "" &&
        prefix.substring(prefix.length - 1, prefix.length) !== "/"
      ) {
        newKey += "/";
      }
      const invalidChar = ["/", "\\"];
      if (invalidChar.some((char) => file.name.indexOf(char) !== -1)) return;
      newKey += file.name;
      return {
        key: newKey,
        size: file.size,
        modified: +moment(),
      };
    });
    const uniqueNewFiles = [];
    newFiles.forEach((newFile) => {
      const exists = files.some(
        (existingFile) => existingFile.key === newFile.key
      );
      if (!exists) uniqueNewFiles.push(newFile);
    });
    const updatedFiles = [...files, ...uniqueNewFiles];
    setFiles(updatedFiles);
    return { files: updatedFiles };
  };

  const handleFileSelect = (file) => {
    console.log("handleFileSelect", file);
    if (file.url) {
      formSetValue(formik, column, file.url);
      dirtying();
      formError(formik, column);
    }
  };

  return (
    <FormControl
      sx={{ width: "100%" }}
      error={!!formFieldError(formik, column, true)}
      component="fieldset"
      variant="standard"
    >
      <div className="row">
        <label
          className={`col-lg-4 col-form-label fs-6 ${
            column.validation?.required && "required"
          }`}
        >
          <Typography
            className={"fw-bold"}
            sx={{
              color: `${
                !!formFieldError(formik, column, true) ? "#d32f2f" : "#3f4254"
              }`,
            }}
            component={"span"}
          >
            {column.headerName}
          </Typography>
        </label>
        <div className="col-lg-8 fv-row">
          <div className={`form-control form-control-lg form-control-solid`}>
            <div style={{ minHeight: 300 }}>
              <DndProvider backend={HTML5Backend}>
                <RawFileBrowser
                  ref={refBrowser}
                  files={files}
                  icons={Icons.FontAwesome(4)}
                  // onCreateFolder={handleCreateFolder}
                  // onCreateFiles={handleCreateFiles}
                  onSelectFile={handleFileSelect}
                  renderStyle="list"
                  headerRenderer={null}
                  nestChildren
                  detailRenderer={() => {}}
                  group={Groupers.GroupByFolder}
                  fileRenderer={FileRenderers.ListThumbnailFile}
                  fileRendererProps={{
                    showSize: false,
                    showModified: false,
                  }}
                  folderRenderer={FolderRenderers.ListThumbnailFolder}
                />
              </DndProvider>
            </div>
          </div>
          {!!formFieldError(formik, column, true) && (
            <FormHelperText>
              {formFieldError(formik, column, true)}
            </FormHelperText>
          )}
        </div>
      </div>
    </FormControl>
  );
};

const BrowserInput = forwardRef(InputForwardRef);

BrowserInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default BrowserInput;
