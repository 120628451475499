import React, {useEffect, useRef, useState} from "react";
import {
  getEmailSubscribers,
  getEmailSubscriber,
  setEmailSubscriber,
  deleteEmailSubscriber,
  getEmailSubscriberCount,
} from "../../services/address-book";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";
import moment from "moment";

EmailSubscriberMds.propTypes = {};

function EmailSubscriberMds(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);
  const [filter, setFilter] = useState('');
  const [listFilter, setListFilter] = useState({ siteId: "md" });

  const permissions = usePermission("dictionary");

  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const sites = [
    {
      id: "md",
      value: "md",
      name: "MindDiák",
    },
    {
      id: "hc",
      value: "hc",
      name: "HumanCentrum",
    },
  ];

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Név",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "email",
      fieldType: "text",
      headerName: "E-mail",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "createTime",
      fieldType: "",
      headerName: "Feliratkozás dátuma",
      width: 200,
      valueGetter: (params) => {
        return moment(params.row.createTime).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Állásposta feliratkozottak (MD)",
    url: "/address-books/email-subscriber-mds",
    item: "állásposta feliratkozott",
  };

  const refTimeout = useRef(null);

  const handleQuickfilter = (value) => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
      refTimeout.current = null;
    }
    refTimeout.current = setTimeout(() => {
      setFilter(value);
    }, 100);
  }

  useEffect(() => {
    const where = {};
    if (filter.toString() !== '') {
      const like = `%${filter}%`;
      where.and = [
        {siteId: "md"},
        {or: [
            {name: {like: like}},
            {email: {like: like}},
          ]},
      ];
    }
    setListFilter(where);
  }, [filter]);

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getEmailSubscriber}
          getParams={{ siteId: "md" }}
          setFunc={setEmailSubscriber}
          sites={sites}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getEmailSubscribers}
          getCountFunc={getEmailSubscriberCount}
          quickfilterFunc={handleQuickfilter}
          getParams={listFilter}
          deleteFunc={deleteEmailSubscriber}
          columns={columns}
          sites={sites}
        ></Lists>
      )}
    </>
  );
}

export default EmailSubscriberMds;
