import React, { useEffect, useState } from "react";
import {
  getTagCategory,
  setTagCategory,
  deleteTagCategory, getDictionaryTagCategories,
} from "../../services/dictionary";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";

TagCategories.propTypes = {};

function TagCategories(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);

  const permissions = usePermission("dictionary");
  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Megnevezés",
      validation: {
        required: true,
        minLength: 3,
      },
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Címke kategóriák",
    url: "/dictionary/tag-categories",
    item: "címke kategória",
  };

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getTagCategory}
          setFunc={setTagCategory}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getDictionaryTagCategories}
          isDictionary={true}
          deleteFunc={deleteTagCategory}
          columns={columns}
        ></Lists>
      )}
    </>
  );
}

export default TagCategories;
