import React, { useEffect, useState } from "react";
import {
  getComputerSkills,
  getComputerSkillCategories,
  getComputerSkill,
  setComputerSkill,
  deleteComputerSkill,
} from "../../services/dictionary";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";

ComputerSkills.propTypes = {};

function ComputerSkills(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);

  const permissions = usePermission("dictionary");
  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const [computerSkillCategories, setComputerSkillCategories] = useState([]);
  useEffect(() => {
    getComputerSkillCategories()
      .then((response) => {
        setComputerSkillCategories(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Megnevezés",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "computerSkillCategoryId",
      fieldType: "select",
      headerName: "Kategória",
      validation: {
        required: true,
      },
      valueGetter: (params) => {
        const computerSkillCategory = computerSkillCategories.find(
          (item) => item.id === params.row.computerSkillCategoryId
        );
        return computerSkillCategory
          ? computerSkillCategory.name
          : `ismeretlen ${params.row.computerSkillCategoryId}`;
      },
    },
    {
      field: "priority",
      fieldType: "number",
      // editable: false,
      defaultValue: 1,
      headerName: "Prioritás",
      width: 100,
    },
    {
      field: "isActive",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Státusz",
      width: 100,
      valueGetter: (params) => {
        return params.row.isActive ? "aktív" : "inaktív";
      },
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Számítógépes ismeretek",
    url: "/dictionary/computer-skills",
    item: "számítógépes ismeret",
  };

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getComputerSkill}
          setFunc={setComputerSkill}
          computerSkillCategories={computerSkillCategories}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getComputerSkills}
          isDictionary={true}
          deleteFunc={deleteComputerSkill}
          columns={columns}
          computerSkillCategories={computerSkillCategories}
        ></Lists>
      )}
    </>
  );
}

export default ComputerSkills;
