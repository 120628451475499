import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import {
  formError,
  formFieldError,
  formLabelAttr,
  formSetValue,
  formValueAttr,
} from "./formUtils";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying, ...args } =
    props;

  const valueAttr = formValueAttr(column);
  const { labelAttr, labelAttrFn } = formLabelAttr(column);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");

  // todo: prevValue vs. initialValue ???
  // const prevValue = column.getParams.original ?? 0;
  // console.log(
  //   column.field,
  //   "initialValue",
  //   initialValue,
  //   "prevValue",
  //   prevValue
  // );
  const [inited, setInited] = useState(false);

  useEffect(() => {
    console.log('forceValue', column.forceValue);
    if (column.forceValue) {
      setValue(column.forceValue);
      column
          .getFunc(`id=${column.forceValue}`)
          .then((response) => {
            setOptions(response.data);
            const found = response.data.find(
                (item) => item[valueAttr] === column.forceValue
            );
            if (found) {
              setValue(found);
            }
          })
          .catch((err) => console.error(err));
    }
  }, [column.forceValue]);

  useEffect(() => {
    const initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      setValue(initValue);
      // console.log("initValue", initValue);
    }
    setInited(true);
  }, [initialValue]);

  useEffect(() => {
    if (initialValue) {
      column
        .getFunc(`id=${initialValue}`)
        .then((response) => {
          setOptions(response.data);
          const found = response.data.find(
            (item) => item[valueAttr] === initialValue
          );
          if (found) {
            setValue(found);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [column, initialValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    formSetValue(formik, column, value);
    dirtying();
    formError(formik, column, options);
  }, [value]);

  let optionTimer;

  return (
    <div className="row">
      <label
        className={`col-lg-4 col-form-label fw-bold fs-6 ${
          column.validation && "required"
        }`}
      >
        {column.headerName}
      </label>
      <div className="col-lg-8 fv-row">
        <FormControl sx={{ width: "100%" }}>
          <Autocomplete
            id={column.field}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            freeSolo={true}
            options={options}
            isOptionEqualToValue={(option, val) => {
              return option[valueAttr] === val[valueAttr];
            }}
            getOptionLabel={(option) => option[labelAttr] ?? ""}
            filterOptions={(x) => {
              return x;
            }}
            onInputChange={(event, val) => {
              clearTimeout(optionTimer);
              if (val) {
                optionTimer = setTimeout(() => {
                  column
                    .getFunc(initialValue ? `${initialValue}|${val}` : val)
                    .then((response) => {
                      setOptions(response.data);
                    })
                    .catch((err) => console.error(err));
                }, 200);
              }
            }}
            onChange={(event, val) => {
              if (column.onChange) {
                column.onChange(val);
              }
              setValue(val ?? "");
            }}
            value={value}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!formFieldError(formik, column, isFormDirty)}
                helperText={
                  formFieldError(formik, column, isFormDirty) ??
                  column.helperText ??
                  ""
                }
              />
            )}
          ></Autocomplete>
          <input
            type="hidden"
            name={column.field}
            onChange={formik.handleChange}
            value={value}
          />
        </FormControl>
      </div>
    </div>
  );
};

const AutocompleteInput = forwardRef(InputForwardRef);

AutocompleteInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default AutocompleteInput;
