import React, {
  forwardRef,
  useEffect,
  useImperativeHandle, useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  huHU,
  useGridApiRef,
} from "@mui/x-data-grid";
import { darken, lighten, styled } from "@mui/material/styles";
import {grey, red} from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableViewIcon from '@mui/icons-material/TableView';
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  IconButton, MenuItem,
  Popper, TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";

function CustomToolbar(header, props) {
  const exportOption = {
    ...{ allColumns: true, delimiter: "\t", utf8WithBom: true },
    ...(header.exportOptions ?? {}),
  };
  let toolbarExport = (<GridToolbarExport
      excelOptions={exportOption}
      csvOptions={exportOption}
      printOptions={{ disableToolbarButton: true }}
  />);

  if (header.exportToExcel) {
    toolbarExport = (<Button onClick={() => header.exportToExcel()}>
      <TableViewIcon sx={{marginRight: 1}} />Excel export
    </Button>);
    // toolbarExport = (<GridToolbarExportContainer {...props}>
    //   {/*<GridCsvExportMenuItem options={exportOption} />
    //   <GridPrintExportMenuItem />*/}
    //   <ExcelExportMenuItem />
    // </GridToolbarExportContainer>);
  }
  return (
    <GridToolbarContainer>
      {!header.disableColumnsToolbar && <GridToolbarColumnsButton />}
      {!header.disableFilterToolbar && <GridToolbarFilterButton />}
      {!header.disableDensityToolbar && <GridToolbarDensitySelector />}
      {!header.disableExportToolbar && (toolbarExport)}
    </GridToolbarContainer>
  );
}

const ListsForwardRef = (props, ref) => {
  const {
    header,
    addonHeader,
    permissions,
    getFunc,
    getParams,
    isDictionary,
    getCountFunc,
    quickfilterFunc,
    deleteFunc,
    onRowEnter,
    onRowLeave,
    columns,
    createButtons,
    ...args
  } = props;

  useImperativeHandle(ref, () => ({
    setRows(rows) {
      setRows(rows);
    },
    returnGrid() {
      return refGrid.current;
    },
    returnGridApi() {
      return apiRef?.current ?? null;
    },
  }));

  const dataGridArgs = args.dataGrid ?? {};
  // const possibleParams = [
  //   "columnVisibilityModel",
  //   "filterModel",
  //   "sortModel",
  //   "page",
  //   "pageSize",
  // ];

  const navigate = useNavigate();

  // console.log(header, getFn, deleteFn, columns, args);
  const [gridInited, setGridInited] = useState(false);
  const [gridRefresh, setGridRefresh] = useState(false);
  const [countRefresh, setCountRefresh] = useState(false);
  const [gridColumns, setGridColumns] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [dataGridParams, setDataGridParams] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({ id: 0, text: "" });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [quickfilter, setQuickfilter] = useState('');

  const refGrid = useRef(null);
  const refTimeoutCounter = useRef(0);

  const parseFilter = (items) => {
    let filter = {};
    items.map((item) => {
      if (item.operatorValue === "contains" && item.value) {
        filter[item.columnField] = { like: `%${item.value}%` };
      }
    });
    return filter;
  };

  const initRows = (isFirst = false) => {
    if (currentPermissions.length && currentPermissions.includes("read")) {
      let promise;
      if (typeof getFunc === "function" && getCountFunc && rowCount !== null) {
        const limit = dataGridParams.paginationModel?.pageSize ?? 25;
        const skip = (dataGridParams.paginationModel?.page ?? 0) * limit;
        let order = [];
        if (dataGridParams.sortModel?.length) {
          dataGridParams.sortModel.map((item) => {
            order.push(`${item.field} ${item.sort.toUpperCase()}`);
          });
        }
        let where = getParams ?? {};
        if (dataGridParams.filterModel?.items?.length) {
          where = {
            ...where,
            ...parseFilter(dataGridParams.filterModel.items),
          };
        }
        promise = getFunc(where, order, limit, skip, [], isDictionary);

        const newGridParams = {
          ...dataGridParams,
          paginationMode: "server",
          rowCount,
          filterMode: "server",
        };
        // delete newGridParams.rowCount;
        setDataGridParams(newGridParams);
      } else if (typeof getFunc === "function" && !getCountFunc && getParams) {
        promise = getFunc(getParams, isDictionary ?? false);
      } else if (typeof getFunc === "function" && !getCountFunc && !getParams) {
        promise = getFunc(null, isDictionary ?? false);
      } else if (typeof getFunc === "object") {
        promise = getFunc;
      }
      if (promise) {
        if (Array.isArray(promise)) {
          setGridRefresh(false);
          setRows(promise);
          if (!gridInited) {
            setGridInited(true);
          }
        } else {
          promise
            .then((response) => {
              setGridRefresh(false);
              setRows(response.data);
              if (!gridInited) {
                setGridInited(true);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    } else {
      if (refTimeoutCounter.current < 100) {
        setTimeout(() => {
          initRows(isFirst);
          // console.log('initRows timeout', currentPermissions, refTimeoutCounter.current);
          refTimeoutCounter.current++;
        }, 100);
      }
    }
  };

  useEffect(() => {
    let columnModel = {};
    // const storedColumnsModelStr = localStorage.getItem(
    //   `${header.url}/columnsModel`
    // );
    // if (storedColumnsModelStr) {
    //   const storedColumnsModel = JSON.parse(storedColumnsModelStr);
    //   if (storedColumnsModel) {
    //     columnModel = storedColumnsModel;
    //   }
    // }
    const urlColumnsModelStr = window.location.search;
    if (urlColumnsModelStr) {
      const urlColumnsModels = urlColumnsModelStr.substring(1).split("&");
      const urlColumnsModel = {};
      urlColumnsModels.map((item) => {
        const param = item.split("=", 2);
        if (param.length === 2) {
          urlColumnsModel[param[0]] = JSON.parse(decodeURIComponent(param[1]));
        }
      });
      if (Object.keys(urlColumnsModel).length) {
        columnModel = urlColumnsModel;
      }
    }
    if (Object.keys(columnModel).length) {
      if (columnModel !== dataGridParams) {
        Object.keys(columnModel).map((key) => {
          if (!columnModel[key]) {
            delete columnModel[key];
          }
        });
        setDataGridParams(columnModel);
      }
    }
    return () => {
      setGridRefresh(false);
      setGridInited(false);
      setCountRefresh(false);
      setGridColumns([]);
      setCurrentPermissions([]);
      setRows([]);
      setRowCount(null);
      setDataGridParams({});
      setDialogOpen(false);
      setColumnVisibilityModel({});
      setQuickfilter('');
    }
  }, []);

  useEffect(() => {
    if (typeof permissions === "function") {
      permissions()
        .then((response) => {
          // console.log('permissions fetch', response.data, 'gridRefresh', gridRefresh);
          setCurrentPermissions(response.data);
        })
        .catch((err) => console.error(err));
    } else if (Array.isArray(permissions)) {
      // console.log('permissions array', permissions, 'gridRefresh', gridRefresh);
      setCurrentPermissions(permissions);
    }
    return () => {
      // console.log('permissions cleanup', 'gridRefresh', gridRefresh);
      setCurrentPermissions([]);
    };
  }, [permissions]);

  /*
  useEffect(() => {
    if (currentPermissions.includes("read") && getCountFunc) {
      if (rowCount === null) {
        let where = getParams ?? {};
        if (dataGridParams.filterModel?.items?.length) {
          where = {
            ...where,
            ...parseFilter(dataGridParams.filterModel.items),
          };
        }
        getCountFunc(where)
          .then((response) => {
            setRowCount(response.data?.count ?? 0);
          })
          .catch((err) => console.error(err));
      }
    }
  }, [currentPermissions, getCountFunc, getParams]);
*/

  useEffect(() => {
    if (countRefresh) {
      let where = getParams ?? {};
      if (dataGridParams.filterModel?.items?.length) {
        where = {
          ...where,
          ...parseFilter(dataGridParams.filterModel.items),
        };
      }
      getCountFunc(where)
        .then((response) => {
          setCountRefresh(false);
          setGridRefresh(false);
          setRowCount(response.data?.count ?? 0);
        })
        .catch((err) => console.error(err));
    }
  }, [countRefresh]);

  useEffect(() => {
    if (currentPermissions.includes("read") && getCountFunc) {
      setCountRefresh(true);
      setRowCount(null);
    }
  }, [currentPermissions, getCountFunc, getParams]);

  useEffect(() => {
    if (gridRefresh) {
      initRows(gridInited);
    }
  }, [gridRefresh]);

  useEffect(() => {
    if (typeof getFunc === "function" && getCountFunc && rowCount !== null && currentPermissions.length > 0) {
      // console.log('gridRefresh setting 1', gridRefresh, currentPermissions);
      setGridRefresh(true);
    } else if (typeof getFunc === "function" && !getCountFunc && getParams && currentPermissions.length > 0) {
      // console.log('gridRefresh setting 2', gridRefresh, currentPermissions);
      setGridRefresh(true);
    } else if (typeof getFunc === "function" && !getCountFunc && !getParams && currentPermissions.length > 0) {
      // console.log('gridRefresh setting 3', gridRefresh, currentPermissions);
      setGridRefresh(true);
    } else if (typeof getFunc === "object" && currentPermissions.length > 0) {
      // console.log('gridRefresh setting 4', gridRefresh, currentPermissions);
      setGridRefresh(true);
    }
  }, [
    getParams,
    getFunc,
    getCountFunc,
    currentPermissions,
    isDictionary,
    rowCount,
  ]);

  useEffect(() => {
    const newColumnVisibilityModel = Object.assign({}, columnVisibilityModel);
    columns.map((column) => {
      if (column.field === "id" && column.hide === undefined) {
        column.hide = true;
      }
      if (!column.width) {
        column.flex = 1;
      }
      if (!column.editable) {
        column.editable = false;
      }
      if (column.align && !column.headerAlign) {
        column.headerAlign = column.align;
      }
      if (column.hide === true) {
        newColumnVisibilityModel[column.field] = false;
      } else if (column.hide === false) {
        newColumnVisibilityModel[column.field] = true;
      } else {
        newColumnVisibilityModel[column.field] = columnVisibilityModel[column.field] ?? true;
      }
      return {
        ...column,
        headerClassName: "text-dark",
        cellClassName: "text-dark",
      };
    });
    if (!columns.find((item) => item.field === "actions")) {
      columns.push({
        field: "actions",
        headerName: "Műveletek",
        width: 120,
        align: "right",
        headerAlign: "right",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerClassName: "text-dark",
        cellClassName: "text-dark",
        renderCell: (params) => {
          return (
            <>
              <Link to={`${header.url}/${params.row.id}`}>
                <IconButton
                  variant={"contained"}
                  aria-label="szerkesztés"
                  size="large"
                  className={"me-3"}
                >
                  {currentPermissions.includes("update") && <EditIcon />}
                  {!currentPermissions.includes("update") && <VisibilityIcon />}
                </IconButton>
              </Link>
              {currentPermissions.includes("delete") && (
                <IconButton
                  variant={"contained"}
                  aria-label="törlés"
                  size="large"
                  onClick={() => handleDelete(params.row)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          );
        },
      });
    }
    setGridColumns(columns);
    setColumnVisibilityModel(newColumnVisibilityModel);
  }, [currentPermissions, gridRefresh, columns]);

  const handleDelete = (row) => {
    setDialogData({
      id: row.id,
      text: `"<b>${row.name ? row.name : (row.firstName && row.lastName) ? row.lastName + ' ' + row.firstName : ''}</b>" megnevezésű ${header.item}`,
    });
    setDialogOpen(true);
  };

  const handleDeleteAgree = (id) => {
    if (deleteFunc) {
      deleteFunc(id)
        .then((response) => {
          setDialogOpen(false);
          setGridRefresh(true);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDataGridParamChange = (type, param) => {
    const newDataGridParam = JSON.parse(JSON.stringify(dataGridParams));
    if (param) {
      if (type === "columnVisibilityModel") {
        let isEmpty = false;
        if (Object.keys(param).length === 0) {
          isEmpty = true;
        }
        columns.map((column) => {
          if (isEmpty) {
            param[column.field] = true;
          } else if (!Object.keys(param).includes(column.field)) {
            param[column.field] = false;
          }
        });
      }
      newDataGridParam[type] = param;
    } else {
      delete newDataGridParam[type];
    }
    // const hasStored = localStorage.getItem(`${header.url}/columnsModel`);
    // if (gridInited || !hasStored) {
    if (gridInited) {
      setDataGridParams(newDataGridParam);
      setGridRefresh(true);
    }
  };

  let customDataGrid, dataGridStyles;
  if (header.styles) {
    dataGridStyles = header.styles;
  } else {
    const getBackgroundColor = (color, mode) =>
      mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
    const getHoverBackgroundColor = (color, mode) =>
      mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
    const getSelectedBackgroundColor = (color, mode) =>
      mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
    const getSelectedHoverBackgroundColor = (color, mode) =>
      mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
    dataGridStyles = ({ theme }) => ({
      "& .row-inactive": {
        backgroundColor: getBackgroundColor(grey[300], theme.palette.mode),
        color: grey[500],
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            grey[300],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            grey[300],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              grey[300],
              theme.palette.mode
            ),
          },
        },
      },
      "& .row-deleted": {
        backgroundColor: getBackgroundColor(red[300], theme.palette.mode),
        color: red[800],
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
              red[300],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
              red[300],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
                red[300],
              theme.palette.mode
            ),
          },
        },
      },
    });
  }

  const apiRef = useGridApiRef();

  const gridRows = useMemo(() => {
    if (quickfilterFunc) {
      quickfilterFunc(quickfilter);
    } else if (quickfilter && rows.length > 0) {
      let filteredRows = [];
      const regexp = new RegExp(quickfilter, 'i');
      rows.map(row => {
        let isFiltered = false;
        Object.keys(columns).map(columnName => {
          const column = columns[columnName];
          const field = column.field ?? columnName;
          let value;
          if (column?.valueGetter) {
            value = column.valueGetter({row});
          } else if (row[field]) {
            value = row[field];
          }
          if (value && value.toString().match(regexp)) {
            isFiltered = true;
          }
        });
        if (isFiltered) {
          filteredRows.push(row);
        }
      });
      return filteredRows;
    }
    return rows;
  }, [rows, quickfilter]);

  const StyledDataGrid = styled(DataGrid)(dataGridStyles);
  customDataGrid = (
    <StyledDataGrid
      apiRef={apiRef}
      localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
      components={{
        Toolbar: (props) => CustomToolbar(header, props),
      }}
      componentsProps={{
        toolbar: {
          csvOptions: { allColumns: true },
        },
        row: {
          onMouseEnter: onRowEnter ? (e) => onRowEnter(e, rows) : () => {},
          onMouseLeave: onRowLeave ? (e) => onRowLeave(e, rows) : () => {},
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      rows={gridRows ?? []}
      columns={gridColumns}
      paginationModel={{ page: 0, pageSize: 25 }}
      pageSizeOptions={[10, 25, 50]}
      disableSelectionOnClick
      autoHeight={true}
      disableColumnFilter={!getCountFunc}
      // experimentalFeatures={{ newEditingApi: true }}
      {...dataGridArgs}
      {...dataGridParams}
      // onRowClick={(params, event) => {
      //   console.log("onRowClick", params, event);
      //   // onRowEnter(event, rows);
      // }}
      onColumnVisibilityModelChange={(model) =>
          handleDataGridParamChange("columnVisibilityModel", model)
      }
      onFilterModelChange={(model) =>
          handleDataGridParamChange("filterModel", model)
      }
      onSortModelChange={(model) =>
          handleDataGridParamChange("sortModel", model)
      }
      onPaginationModelChange={(model, details) => {
        handleDataGridParamChange("paginationModel", model);
      }}
      // onPageChange={(value) => {
      //   handleDataGridParamChange("page", value);
      // }}
      // onChangePage={(value) => {
      //   handleDataGridParamChange("page", value);
      // }}
      // onPageSizeChange={(value) => {
      //   handleDataGridParamChange("pageSize", value);
      // }}
      getRowClassName={(params) => {
        if (header.getRowClassName) {
          if (typeof header.getRowClassName === "function") {
            return header.getRowClassName(params);
          } else {
            return header.getRowClassName;
          }
        } else {
          return `${
            Object.keys(params.row).includes("isActive") && !params.row.isActive
              ? "row-inactive"
              : ""
          }`;
        }
      }}
    />
  );

  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        {!header.listskip && (
          <div className="card-header border-0 pt-5">
            <Grid container width={"100%"} alignItems={"center"}>
              <Grid flexGrow={1}>
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">
                    {header.title}
                  </span>
                  <span className="text-muted mt-1 fw-semibold fs-7">
                    Találatok száma: {rowCount ? rowCount : rows.length} db
                  </span>
                </h3>
              </Grid>
              {(!getCountFunc || quickfilterFunc) && <Grid flexGrow={1} sx={{ marginX: 5, }}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                      type="text"
                      name="quickfilter"
                      label="Gyorsszűrő"
                      autoComplete="off"
                      onChange={(event) => {
                        setQuickfilter(event.target.value ?? "");
                      }}
                      value={quickfilter}
                  />
                </FormControl>
              </Grid>}
              {addonHeader && addonHeader}
              <Grid>
                {createButtons && <>{createButtons}</>}
                {!createButtons && currentPermissions.includes("create") && (
                  <Link to={`${header.url}/0`}>
                    <Button variant={"contained"} color={"primary"}>
                      Új {header.item}
                    </Button>
                  </Link>
                )}
              </Grid>
            </Grid>
          </div>
        )}
        <Box
          className="card-body py-3"
          sx={header.nopadding ? { padding: "0 !important" } : {}}
        >
          <div className="card-toolbar" ref={refGrid}>
            <Box sx={{ width: "100%" }}>{customDataGrid}</Box>
          </div>
        </Box>
      </div>
      {deleteFunc && (
        <Dialog
          open={dialogOpen}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Adat törlése</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Biztosan törölni szeretné az alábbi adatot?
              <br />
              {parse(dialogData.text)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Mégsem</Button>
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={() => {
                handleDeleteAgree(dialogData.id);
              }}
            >
              Igen, biztosan
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
const Lists = forwardRef(ListsForwardRef);

Lists.propTypes = {
  header: PropTypes.object.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
    .isRequired,
  getFunc: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  getCountFunc: PropTypes.func,
  quickfilterFunc: PropTypes.func,
  getParams: PropTypes.object,
  isDictionary: PropTypes.bool,
  deleteFunc: PropTypes.func,
  onRowEnter: PropTypes.func,
  onRowLeave: PropTypes.func,
  createButtons: PropTypes.object,
  columns: PropTypes.array.isRequired,
};

export default Lists;
