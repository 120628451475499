import axios from "axios";
import { Tooltip } from "@mui/material";
import parse from "html-react-parser";
import CommentIcon from "@mui/icons-material/Comment";
import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
const apiUrl = process.env.REACT_APP_API_URL;

export const getUsers = (where, order = [], limit = 25, skip = 0) => {
  let filter = {
    where: {},
    order,
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/admin/users`, { params: filter });
};
export const getUsersList = (where, order = [], limit = 25, skip = 0) => {
  let filter = {
    where: {},
    order,
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/admin/users/list`, { params: filter });
};
export const getUserCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/admin/users/count`, { params: filter });
};
export const getUser = (id) => {
  return axios.get(`${apiUrl}/users/${id}`, {});
};
export const getUserForAdmin = (id) => {
  return axios.get(`${apiUrl}/admin/users/${id}`, {});
};
export const emailCheck = (site, id, data) => {
  return axios.post(`${apiUrl}/admin/check-email/${site}/${id}`, data);
};
export const setUser = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/admin/users/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/admin/users`, data);
  }
};
export const deleteUser = (id) => {
  return axios.delete(`${apiUrl}/admin/users/${id}`, {});
};
export const inactivateUser = (id) => {
  return axios.post(`${apiUrl}/admin/users/inactivate/${id}`, {});
};
export const blacklistUser = (id, checked, comment) => {
  return axios.post(`${apiUrl}/admin/users/blacklist/${id}`, {
    isBlacklisted: checked,
    blacklistComment: comment,
  });
};

export const uploadDocument = (userId, data) => {
  return axios.post(`${apiUrl}/admin/upload-document/${userId}`, data);
};
export const updateDocument = (userId, docId, data) => {
  if (docId) {
    return axios.patch(`${apiUrl}/admin/documents/${userId}/${docId}`, data);
  } else {
    return axios.post(`${apiUrl}/admin/documents/${userId}`, data);
  }
};
export const deleteDocument = (userId, docId) => {
  return axios.delete(`${apiUrl}/admin/documents/${userId}/${docId}`, {});
};

export const getUserDeletionLogs = (
  where,
  order = [],
  limit = 25,
  skip = 0
) => {
  let filter = {
    where: {},
    order,
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/user-deletion-logs`, { params: filter });
};
export const getUserDeletionLogCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/user-deletion-logs/count`, { params: filter });
};

export const getUserEventLog = (userId) => {
  return axios.get(`${apiUrl}/event-logs`, {
    params: {
      where: { userId },
      include: ["position"],
    },
  });
};

export const getUserComments = (userId) => {
  return axios.get(`${apiUrl}/user-comments`, {
    params: {
      where: { userId },
    },
  });
};

export const addUserComment = (userId, comment) => {
  return axios.post(`${apiUrl}/admin/user-comments`, {
    userId,
    text: comment,
  });
};

export const getPictureSrc = (src, uid) => {
  if (src.match(/^https?:\/\//)) {
    return src;
  } else if (!uid) {
    return apiUrl + "/" + src;
  } else if (isNaN(parseInt(uid))) {
    const userPath = uid;
    const path = `/user/${userPath}/`;
    return apiUrl + path + src;
  } else {
    const userPath =
      uid.toString().substring(uid.toString().length - 3) +
      "/" +
      uid.toString();
    const path = `/user/${userPath}/`;
    return apiUrl + path + src;
  }
};

export const roleNames = [
  { id: "hc_mv", name: "HC munkavállaló", level: 10 },
  { id: "md_mv", name: "MD munkavállaló", level: 10 },

  { id: "hca", name: "HC alkalmazott", level: 50 },
  { id: "hca_m", name: "HC vezető", level: 75 },
  // { id: "hca_w", name: "HC tartalom szerkesztő", level: 50 },
  { id: "hca_r", name: "HC rendszergazda", level: 75 },
  { id: "hca_p", name: "HC projektvezető", level: 60 },
  // { id: "hca_ev", name: "HC értékesítési vezető", level: 70 },
  // { id: "hca_e", name: "HC értékesítő", level: 65 },
  { id: "hca_c", name: "HC jelentkezés monitoring", level: 50 },

  { id: "position_creator", name: "Pozíció létrehozó", level: 55 },
  { id: "position_owner", name: "Pozíció gazda", level: 55 },
  { id: "position_admin", name: "Pozíció manager", level: 55 },

  { id: "hcf", name: "HC foglalkoztató", level: 1 },
  { id: "hcf_a", name: "HC foglalkoztató admin", level: 3 },
  { id: "hcf_u", name: "HC foglalkoztató ügyintéző", level: 2 },

  { id: "superadmin", name: "Super Admin", level: 100 },
];

export const roleLevel = (roles) => {
  let maxLevel = 0;
  roleNames.map((item) => {
    if (roles && Array.isArray(roles) && roles.includes(item.id) && item.level > maxLevel) {
      maxLevel = item.level;
    }
  });
  return maxLevel;
};

export const userWithComment = (user, link) => {
  const site = user?.type === 10 ? "md" : user?.type === 20 ? "hc" : "all";
  let commentIcon = <></>;
  if (user?.userComments?.length) {
    const lastComment = user.userComments[user.userComments.length - 1];
    const commentContent = `${lastComment.text}<hr class="comment-hr" />${
      lastComment.admin
        ? lastComment.admin.lastName + " " + lastComment.admin.firstName
        : ""
    } <i>(${moment(lastComment.createTime).format("YYYY-MM-DD HH:mm:ss")})</i>`;
    commentIcon = (
      <Tooltip sx={{ ml: 2 }} title={parse(commentContent)} arrow>
        <CommentIcon />
      </Tooltip>
    );
  }
  if (link) {
    return (
      <Link
        to={link === true ? `/users/${site}/${user.id}` : link}
        target={"_blank"}
      >
        {user?.lastName} {user?.firstName}
        {commentIcon}
      </Link>
    );
  }
  return (
    <>
      {user?.lastName} {user?.firstName}
      {commentIcon}
    </>
  );
};

export const userForMonitoring = (user, link) => {
  const site = user?.type === 10 ? "md" : user?.type === 20 ? "hc" : "all";
  if (link) {
    return (
      <Link
        to={link === true ? `/users/${site}/${user.id}` : link}
        target={"_blank"}
      >
        {user?.lastName} {user?.firstName}
      </Link>
    );
  }
  return (
    <>
      {user?.lastName} {user?.firstName}
    </>
  );
};

export const verifyUserNewEmail = (userId, email) => {
  return axios.post(`${apiUrl}/admin/members/verify-new-email/${userId}`, {
    email,
  });
};

export const saveUserNewEmail = (userId, email) => {
  return axios.post(`${apiUrl}/admin/members/new-email/${userId}`, {
    email,
  });
};

export const saveUserNewPassword = (userId, password) => {
  return axios.post(`${apiUrl}/admin/members/change-password/${userId}`, {
    password,
  });
};

export const importUsers = (excel, schema, site) => {
  return axios.post(`${apiUrl}/admin/users/import`, {
    excel,
    schema,
    site,
  });
};
