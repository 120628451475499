import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Positions from "./components/Positions";
import Applicants from "../position/components/Applicants";
import Informations from "./components/Informations";
import { useRoles } from "../../services/auth";
import { getPosition } from "../../services/position";

Employee.propTypes = {};

function Employee() {
  const [posHeight, setPosHeight] = useState(0);
  const [positionId, setPositionId] = useState(0);
  const [position, setPosition] = useState(null);

  const roles = useRoles();
  console.log('roles', roles);

  const isMonitoring = roles.includes('hca_c');

  const handleChangePosition = (id) => {
    if (id) {
      setPositionId(id);
    }
  };

  useEffect(() => {
    if (positionId) {
      setPosHeight(200);
      getPosition(positionId)
        .then((response) => {
          setPosition(response.data);
        })
        .catch((err) => console.error(err));
    } else {
      setPosition(null);
    }
  }, [positionId]);

  return (
    <Grid container spacing={3}>
      <Grid md={isMonitoring ? 12 : 8}>
        <Box
          sx={posHeight ? { maxHeight: posHeight, overflowY: "scroll" } : {}}
        >
          <Positions onChange={handleChangePosition}></Positions>
        </Box>
        {!!position && (
          <Box
            sx={{
              my: 3,
              paddingRight: "20px",
              maxHeight: 400,
              overflowY: "scroll",
            }}
          >
            <Applicants
              id={positionId}
              position={position}
              attr={{ nopadding: true, nobuttons: true }}
            ></Applicants>
          </Box>
        )}
      </Grid>
      {!isMonitoring && <Grid md={4}>
        <Informations></Informations>
      </Grid>}
    </Grid>
  );
}

export default Employee;
