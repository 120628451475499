import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FilterSelectForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      if (params.getFilter) {
        return params.getFilter(value);
      } else if (value) {
        filter[params.field] = value;
      }
      return filter;
    },
  }));

  const [value, setValue] = useState("");

  const options = params.options ?? [];

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={`col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`} sx={{ minWidth: 150 }}>
        <FormControl sx={{ width: "100%" }}>
          <Select
            name={params.field}
            onChange={(event) => {
              setValue(event.target.value ?? "");
            }}
            value={value}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
const FilterSelect = forwardRef(FilterSelectForwardRef);

FilterSelect.propTypes = {
  params: PropTypes.object,
};

export default FilterSelect;
