import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FilterHighestQualificationForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      let ids = {};
      if (operator === "gt") {
        ids = options
          .filter((item) => item.level >= value)
          .map((item) => item.id);
      } else if (operator === "lt") {
        ids = options
          .filter((item) => item.level <= value)
          .map((item) => item.id);
      } else {
        ids = options
          .filter((item) => item.level === value)
          .map((item) => item.id);
      }
      return { userHc: { highestQualificationId: { inq: ids } } };
    },
  }));

  const [operator, setOperator] = useState("gt");
  const [value, setValue] = useState("");

  const options = params.options ?? [];
  const operators = [
    { id: "gt", name: "legalább" },
    { id: "lt", name: "legfeljebb" },
    { id: "=", name: "pontosan" },
  ];

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={`col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row `}>
        <Grid container alignItems={"center"}>
          <Grid>
            <FormControl sx={{ width: "100%" }}>
              <Select
                name={`${params.field}-operator`}
                onChange={(event) => {
                  setOperator(event.target.value ?? "");
                }}
                value={operator}
              >
                {operators.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl sx={{ width: "100%" }}>
              <Select
                name={params.field}
                onChange={(event) => {
                  setValue(event.target.value ?? "");
                }}
                value={value}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.level}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const FilterHighestQualification = forwardRef(
  FilterHighestQualificationForwardRef
);

FilterHighestQualification.propTypes = {
  params: PropTypes.object,
};

export default FilterHighestQualification;
