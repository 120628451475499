import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import FileBrowser, {
  RawFileBrowser,
  FileRenderers,
  FolderRenderers,
  Groupers,
  Icons,
} from "react-keyed-file-browser";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "font-awesome/css/font-awesome.min.css";
import "react-keyed-file-browser/dist/react-keyed-file-browser.css";

import {
  formError,
  formErrorMsg,
  formFieldClass,
  formFieldError,
  formGetValue,
  formSetValue,
} from "./formUtils";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import {browseDir, useRoles} from "../../../services/auth";
import ImageUploading from "react-images-uploading";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import DeleteIcon from "@mui/icons-material/Delete";
import { getPictureSrc } from "../../../services/user";
import Grid from "@mui/material/Unstable_Grid2";

const CoverInputForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    callMe(args) {
      if (!handleRandomPick(args)) {
        setCallMeArgs(args);
      }
    },
  }));

  const { formik, initialValue, column, isFormDirty, dirtying, values } = props;

  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("");
  const [randomValue, setRandomValue] = useState(null);
  const [browserRefresh, setBrowserRefresh] = useState(false);
  const [inited, setInited] = useState(false);
  const [needBrowser, setNeedBrowser] = useState(false);
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const [callMeArgs, setCallMeArgs] = useState(null);

  const refCover = useRef(null);
  const refFormControl = useRef(null);

  const roles = useRoles();

  // console.log("CoverInput initialValue", initialValue);

  useEffect(() => {
    setValue(formGetValue(formik, column) ?? "");
    setInited(true);
  }, [formik, column]);

  useEffect(() => {
    console.log('cover value', value);
    if (inited) {
      if (typeof value === "object" && value?.data) {
        formSetValue(formik, column, JSON.stringify(value));
      } else if (
        typeof value === "string" &&
        value.match(/^{["']data["']:["']data\:/)
      ) {
        formSetValue(formik, column, value);
      } else {
        if (value && values.id) {
          setImages([{ dataUrl: getPictureSrc(value, values.id) }]);
        } else if (value) {
          setImages([{ dataUrl: getPictureSrc(value) }]);
        }
        formSetValue(formik, column, value);
      }
      dirtying();
      formError(formik, column);
    }
  }, [value]);

  useEffect(() => {
    if (column.path) {
      browseDir(column.path)
        .then((response) => {
          response.data.map((item) => {
            item.modified = +moment(item.modified);
            item.thumbnail_url = item.url;
          });
          setFiles(response.data);
        })
        .catch((err) => console.error(err));
    }
  }, [column]);

  useEffect(() => {
    if (files.length && randomValue) {
      handleFileSelect(randomValue);
    }
  }, [randomValue, files]);

  useEffect(() => {
    const initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      setValue(initValue);
      if (refCover.current) {
        if (initValue && (!inited || browserRefresh)) {
          let image = initValue;
          if (image.match(`^${process.env.REACT_APP_API_URL}${column.path}`)) {
            image = image.replace(
              new RegExp(`^${process.env.REACT_APP_API_URL}${column.path}`),
              ""
            );
          } else if (
            image.match(
              `^${process.env.REACT_APP_API_URL.replace("[::1]", "localhost")}${
                column.path
              }`
            )
          ) {
            image = image.replace(
              new RegExp(
                `^${process.env.REACT_APP_API_URL.replace(
                  "[::1]",
                  "localhost"
                )}${column.path}`
              ),
              ""
            );
          } else {
            image = image.replace(column.path, "");
          }
          refCover.current.selectFiles([image]);
        }
      }
    }
    if (refCover.current) {
      setInited(true);
      setBrowserRefresh(false);
    }
  }, [refCover, initialValue, column, browserRefresh]);

  // useEffect(() => {
  //   const formValue = formGetValue(formik, column) ?? "";
  //   setValue(formValue);
  //   if (refCover.current) {
  //     if (formValue && (!inited || browserRefresh)) {
  //       let image = formValue;
  //       if (image.match(`^${process.env.REACT_APP_API_URL}${column.path}`)) {
  //         image = image.replace(
  //           new RegExp(`^${process.env.REACT_APP_API_URL}${column.path}`),
  //           ""
  //         );
  //       } else if (
  //         image.match(
  //           `^${process.env.REACT_APP_API_URL.replace("[::1]", "localhost")}${
  //             column.path
  //           }`
  //         )
  //       ) {
  //         image = image.replace(
  //           new RegExp(
  //             `^${process.env.REACT_APP_API_URL.replace("[::1]", "localhost")}${
  //               column.path
  //             }`
  //           ),
  //           ""
  //         );
  //       } else {
  //         image = image.replace(column.path, "");
  //       }
  //       refCover.current.selectFiles([image]);
  //     }
  //   }
  //   setInited(true);
  //   setBrowserRefresh(false);
  //   return () => {
  //     setValue("");
  //   };
  // }, [refCover, formik, column, inited, browserRefresh]);

  useEffect(() => {
    if (inited) {
      formSetValue(formik, column, value);
      dirtying();
      formError(formik, column);
    }
  }, [value]);

  useEffect(() => {
    handleRandomPick();
  }, [callMeArgs, files]);

  // todo: kesobb belerakni a kepfeltoltest es konyvtar letrehozast
  const handleCreateFolder = (key) => {
    setFiles([...files, { key }]);
  };

  const handleCreateFiles = (createdFiles, prefix) => {
    console.log("handleCreateFiles", createdFiles, prefix);

    const newFiles = createdFiles.map((file) => {
      let newKey = prefix;
      if (
        prefix !== "" &&
        prefix.substring(prefix.length - 1, prefix.length) !== "/"
      ) {
        newKey += "/";
      }
      const invalidChar = ["/", "\\"];
      if (invalidChar.some((char) => file.name.indexOf(char) !== -1)) return;
      newKey += file.name;
      return {
        key: newKey,
        size: file.size,
        modified: +moment(),
      };
    });
    const uniqueNewFiles = [];
    newFiles.forEach((newFile) => {
      const exists = files.some(
        (existingFile) => existingFile.key === newFile.key
      );
      if (!exists) uniqueNewFiles.push(newFile);
    });
    const updatedFiles = [...files, ...uniqueNewFiles];
    setFiles(updatedFiles);
    return { files: updatedFiles };
  };

  const handleFileSelect = (file) => {
    // console.log("handleFileSelect", file);
    if (file.url) {
      formSetValue(formik, column, file.url);
      dirtying();
      formError(formik, column);
      handleCloseBrowser();
    }
  };

  const handleOpenBrowser = () => {
    setNeedBrowser(true);
    setBrowserRefresh(true);
  };

  const handleCloseBrowser = () => {
    setNeedBrowser(false);
    setBrowserRefresh(false);
    if (refFormControl.current) {
      refFormControl.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleRandomPick = (args) => {
    if (!args) {
      args = callMeArgs;
    }
    if (files.length && args) {
      const randomImages = [];
      if (args.categoryDirectory) {
        files.map((item) => {
          if (item.key.match(`\/${args.categoryDirectory}\/`)) {
            randomImages.push(item);
          }
        });
      }
      if (randomImages.length) {
        setInited(false);
        setRandomValue(
          randomImages[Math.floor(Math.random() * randomImages.length)]
        );
      }
      setCallMeArgs(null);
      return true;
    }
    return false;
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    setValue({
      data: imageList[0].dataUrl,
      meta: {
        filename: imageList[0].file.name,
        filesize: imageList[0].file.size,
      },
    });
  };

  return (
    <FormControl
      sx={{ width: "100%" }}
      error={!!formFieldError(formik, column, true)}
      component="fieldset"
      variant="standard"
      ref={refFormControl}
    >
      <div className="row noselect">
        <label
          className={`col-lg-4 col-form-label fs-6 ${
            column.validation?.required && "required"
          }`}
        >
          <Typography
            className={"fw-bold"}
            sx={{
              color: `${
                !!formFieldError(formik, column, true) ? "#d32f2f" : "#3f4254"
              }`,
            }}
            component={"span"}
          >
            {column.headerName}
          </Typography>
        </label>
        <div className="col-lg-8 fv-row">
          <FormControl sx={{ width: "100%" }}>
            <ImageUploading
              multiple={false}
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="dataUrl"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
                errors,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  {!imageList.length && (
                    <>
                      {roles.includes("hca_m") && <Button
                        variant={"contained"}
                        color={isDragging ? "secondary" : "primary"}
                        onClick={onImageUpload}
                        {...dragProps}
                        sx={{ mb: 2, mr: 2 }}
                      >
                        Kép feltöltése
                      </Button>}
                      <Button
                        variant={"contained"}
                        color={"success"}
                        onClick={() => handleOpenBrowser()}
                        sx={{ mb: 2, mr: 2 }}
                      >
                        Kép tallózása
                      </Button>
                    </>
                  )}
                  &nbsp;
                  {/*<button onClick={onImageRemoveAll}>Remove all images</button>*/}
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img
                        src={image["dataUrl"]}
                        alt=""
                        className={"max-height-100"}
                      />
                      <div className="image-item__btn-wrapper">
                        <Grid container direction={"column"} spacing={2}>
                          <Grid>
                            <Grid container direction={"row"} spacing={2}>
                              {roles.includes("hca_m") && <Grid>
                                <Tooltip title="Új kép feltöltése">
                                  <IconButton
                                    onClick={() => onImageUpdate(index)}
                                    sx={{ mb: 2 }}
                                  >
                                    <FileUploadIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>}
                              <Grid>
                                <Tooltip title="Böngészés a galériában">
                                  <IconButton
                                    onClick={() => handleOpenBrowser()}
                                    sx={{ mb: 2 }}
                                  >
                                    <BurstModeIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <Tooltip title="Kép törlése">
                              <IconButton
                                color={"error"}
                                onClick={() => onImageRemove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ))}
                  {errors && (
                    <div>
                      {errors.maxNumber && (
                        <span>Number of selected images exceed maxNumber</span>
                      )}
                      {errors.acceptType && (
                        <span>Your selected file type is not allow</span>
                      )}
                      {errors.maxFileSize && (
                        <span>Selected file size exceed maxFileSize</span>
                      )}
                      {errors.resolution && (
                        <span>
                          Selected file is not match your desired resolution
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </ImageUploading>
          </FormControl>
          {needBrowser && (
            <div className={`form-control form-control-lg form-control-solid`}>
              <div style={{ minHeight: 300 }}>
                <DndProvider backend={HTML5Backend}>
                  <RawFileBrowser
                    ref={refCover}
                    files={files}
                    icons={Icons.FontAwesome(4)}
                    // onCreateFolder={handleCreateFolder}
                    // onCreateFiles={handleCreateFiles}
                    onSelectFile={handleFileSelect}
                    renderStyle="list"
                    headerRenderer={null}
                    nestChildren
                    detailRenderer={() => {}}
                    group={Groupers.GroupByFolder}
                    fileRenderer={FileRenderers.ListThumbnailFile}
                    fileRendererProps={{
                      showSize: false,
                      showModified: false,
                    }}
                    folderRenderer={FolderRenderers.ListThumbnailFolder}
                  />
                </DndProvider>
              </div>
            </div>
          )}
          {!!formFieldError(formik, column, true) && (
            <FormHelperText>
              {formFieldError(formik, column, true)}
            </FormHelperText>
          )}
        </div>
      </div>
    </FormControl>
  );
};

const CoverInput = forwardRef(CoverInputForwardRef);

CoverInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default CoverInput;
