import React, { useEffect, useState } from "react";
import {
  getTag,
  getTagCategories,
  getTags,
  setTag,
  deleteTag,
} from "../../services/dictionary";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import { tagTypes } from "../../services/constants";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";

Tags.propTypes = {};

function Tags(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);

  const permissions = usePermission("dictionary");
  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const [tagCategories, setTagCategories] = useState([]);
  useEffect(() => {
    getTagCategories()
      .then((response) => {
        setTagCategories(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const types = tagTypes;

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Megnevezés",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "tagCategoryId",
      fieldType: "select",
      headerName: "Kategória",
      validation: {
        required: true,
      },
      valueGetter: (params) => {
        const tagCategory = tagCategories.find(
          (item) => item.id === params.row.tagCategoryId
        );
        // console.log(
        //   "tagCategory",
        //   tagCategory,
        //   params.row.tagCategoryId,
        //   tagCategories
        // );
        return tagCategory
          ? tagCategory.name
          : `ismeretlen ${params.row.tagCategoryId}`;
      },
    },
    {
      field: "type",
      headerName: "Típus",
      validation: {
        required: true,
      },
      fieldType: "select",
      width: 120,
      align: "center",
      valueGetter: (params) => {
        let v = "Egyéb";
        switch (params.row.type) {
          case 10:
            v = "Minddiák";
            break;
          case 20:
            v = "Humáncentrum";
            break;
        }
        return v;
      },
    },
    {
      field: "isActive",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Státusz",
      width: 100,
      valueGetter: (params) => {
        return params.row.isActive ? "aktív" : "inaktív";
      },
    },
    {
      field: "showFrontend",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Frontend",
      description: "Ez a kapcsoló állítja, hogy szűrhető-e erre a címkére",
      width: 100,
      valueGetter: (params) => {
        return params.row.showFrontend ? "igen" : "nem";
      },
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Címkék",
    url: "/dictionary/tags",
    item: "címke",
  };

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getTag}
          setFunc={setTag}
          tagCategories={tagCategories}
          types={types}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getTags}
          isDictionary={true}
          deleteFunc={deleteTag}
          columns={columns}
          tagCategories={tagCategories}
        ></Lists>
      )}
    </>
  );
}

export default Tags;
