import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import { Autocomplete, Button, FormControl, TextField } from "@mui/material";
import { getAdminCitiesGroup } from "../../../services/dictionary";
import Grid from "@mui/material/Unstable_Grid2";
import AvailabilityTable from "../../../modules/hcmd/components/AvailabilityTable";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

const FilterAvailabilityForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = [];
      values.map((table, index) => {
        const current = {};
        Object.keys(table).map((period) => {
          if (table[period] === 1) {
            current[period] = 1;
          }
        });
        if (
          !isNaN(parseInt(cities[index].id)) &&
          parseInt(cities[index].id) !== 0
        ) {
          current.cityId = parseInt(cities[index].id);
        }
        filter.push(current);
      });
      return { userAvailabilityMds: { or: filter } };
    },
  }));

  const [values, setValues] = useState([{}]);
  const [cities, setCities] = useState([{}]);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  let optionTimer;

  const handleTableChange = (checked, index) => {
    const newValues = JSON.parse(JSON.stringify(values));
    Object.keys(checked).map((attr) => {
      if (!attr.match(/p7$/)) {
        newValues[index][attr] = checked[attr];
      }
    });
    setValues(newValues);
  };

  const handleAddRow = () => {
    setValues([...values, {}]);
    setCities([...cities, {}]);
  };

  return (
    <Grid container direction={"column"} spacing={2}>
      <Grid>
        <label className={`col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        {values.map((currentValue, index) => (
          <Grid key={index} container direction={"column"}>
            <Grid>
              <FormControl sx={{ width: "100%" }}>
                <Autocomplete
                  name={params.field}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  freeSolo={true}
                  options={options}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value;
                  }}
                  getOptionLabel={(option) => option.name ?? ""}
                  filterOptions={(x) => {
                    return x;
                  }}
                  onInputChange={(event, value) => {
                    clearTimeout(optionTimer);
                    optionTimer = setTimeout(() => {
                      if (value) {
                        getAdminCitiesGroup(value)
                          .then((response) => {
                            setOptions(response.data);
                          })
                          .catch((err) => console.error(err));
                      } else {
                        setOptions([]);
                      }
                    }, 200);
                  }}
                  onChange={(event, value) => {
                    const newCities = JSON.parse(JSON.stringify(cities));
                    newCities[index] = value;
                    setCities(newCities);
                  }}
                  value={cities?.[index] ?? {}}
                  renderInput={(params) => <TextField {...params} />}
                ></Autocomplete>
              </FormControl>
            </Grid>
            <Grid>
              <AvailabilityTable
                isEditable={true}
                index={index}
                values={currentValue}
                onChange={(checked, index) => handleTableChange(checked, index)}
              ></AvailabilityTable>
            </Grid>
          </Grid>
        ))}
        <Button
          variant={"contained"}
          size={"small"}
          startIcon={<PlaylistAddIcon />}
          color={"success"}
          onClick={() => {
            handleAddRow();
          }}
          sx={{ mr: 3 }}
        >
          További ráérés hozzáadása
        </Button>
      </Grid>
    </Grid>
  );
};
const FilterAvailability = forwardRef(FilterAvailabilityForwardRef);

FilterAvailability.propTypes = {
  params: PropTypes.object,
};

export default FilterAvailability;
