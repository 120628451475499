import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Forms from "../../../modules/hcmd/components/Forms";
import { getAutocompleteCities } from "../../../services/position";
import { Button, IconButton, Paper } from "@mui/material";
import { useFormik } from "formik";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import Box from "@mui/material/Box";
import AvailabilityTable from "../../../modules/hcmd/components/AvailabilityTable";

const AvailabilityFormForwardRef = (props, ref) => {
  const { formik } = props;

  useImperativeHandle(ref, () => ({
    submit() {
      return formik.values;
    },
  }));

  return (
    <>
      <form
        noValidate="novalidate"
        className="form"
        onSubmit={formik.handleSubmit}
      >
        {props.children}
      </form>
    </>
  );
};
const AvailabilityForm = forwardRef(AvailabilityFormForwardRef);
AvailabilityForm.propTypes = {
  formik: PropTypes.object,
};

const AvailabilityForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit, options } = props;
  const formRefs = useRef([]);

  const [formItems, setFormItems] = useState({});
  const [columns, setColumns] = useState(null);

  const formik = useFormik({
    initialValues: {
      userAvailabilityMds: {},
    },
    onSubmit: (values, formikBag) => {
      console.log("availability values", values);
    },
  });

  useEffect(() => {
    if (user.userAvailabilityMds?.length > 0) {
      const newFormItems = {};
      user.userAvailabilityMds.map((item, index) => {
        newFormItems[index] = item;
      });
      setFormItems(newFormItems);
    }
  }, [user]);

  useEffect(() => {
    const newColumns = {};
    Object.keys(formItems).map((index) => {
      const formItem = formItems[index];
      const currentColumns = [
        {
          field: "cityId",
          fieldType: "autocomplete",
          headerName: "Település",
          fieldModel: "userAvailabilityMds",
          fieldModelArrayIndex: index,
          getFunc: getAutocompleteCities,
          getParams: { original: formItem.cityId ?? 0 },
          validation: {
            required: true,
          },
          valueGetter: (params, values) => {
            const cityId =
              params.row ??
              formItem.cityId ??
              values.userAvailabilityMds?.[index]?.cityId ??
              0;
            const found = options.cities.find((item) => item.id === cityId);
            if (found) {
              return found.name;
            }
            return "";
          },
          submitGetter: (value) => {
            return value.id ? value.id : value;
          },
        },
      ];
      for (let d = 1; d <= 7; d++) {
        for (let p = 1; p <= 6; p++) {
          currentColumns.push({
            field: `d${d}p${p}`,
            fieldType: "hidden",
            headerName: "",
            fieldModel: "userAvailabilityMds",
            fieldModelArrayIndex: index,
            defaultValue: 0,
          });
        }
      }
      newColumns[index] = currentColumns;
    });
    setColumns(newColumns);
  }, [formItems]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  const handleAddRow = () => {
    const sorted = Object.keys(formItems).sort(
      (a, b) => parseInt(a) < parseInt(b)
    );
    const newRow =
      sorted.length > 0 ? parseInt(sorted[sorted.length - 1]) + 1 : 0;
    const newItem = {
      fieldsOfWorkId: 0,
    };
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    newFormItems[newRow] = newItem;
    setFormItems(newFormItems);
  };

  const handleDeleteRow = (delRow) => {
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    delete newFormItems[delRow];
    setFormItems(newFormItems);
  };

  return (
    <>
      {Object.keys(formItems).map((index) => {
        const currentColumns = columns?.[index] ?? [];
        return (
          <React.Fragment key={index}>
            {currentColumns.length > 0 && (
              <Paper className={"bg-paper"}>
                <Grid container spacing={3} alignItems={"flex-start"}>
                  <Grid xs={10} sm={11} container>
                    <Grid xs={12}>
                      <Forms
                        isDense={true}
                        editable={isEditable}
                        header={{ skip: true }}
                        permissions={["create", "read", "update", "delete"]}
                        columns={currentColumns}
                        getObj={user}
                        ref={(el) => (formRefs.current[index] = el)}
                        submitFunc={(values) => handleSubmit(values)}
                      ></Forms>
                    </Grid>
                    <Grid xs={12}>
                      <Box sx={{ maxWidth: 600 }}>
                        <AvailabilityTable
                          isEditable={isEditable}
                          values={formItems[index]}
                          formik={formik}
                          index={index}
                          onChange={(values, index) => {
                            const newFormItems = JSON.parse(
                              JSON.stringify(formItems)
                            );
                            newFormItems[index] = {
                              ...newFormItems[index],
                              ...values,
                            };
                            setFormItems(newFormItems);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sm={1}>
                    {isEditable && (
                      <IconButton
                        onClick={() => handleDeleteRow(index)}
                        color="error"
                        className={"mb-6"}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            )}
          </React.Fragment>
        );
      })}
      {isEditable && Object.keys(formItems).length < 2 && (
        <Button
          variant={"contained"}
          size={"small"}
          startIcon={<PlaylistAddIcon />}
          color={"success"}
          onClick={() => {
            handleAddRow();
          }}
          sx={{ mr: 3 }}
        >
          Ráérés hozzáadása
        </Button>
      )}
    </>
  );
};
const Availability = forwardRef(AvailabilityForwardRef);
Availability.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default Availability;
