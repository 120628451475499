import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { formFieldError } from "../../../modules/hcmd/components/formUtils";
import {
  getCounties,
  getCountriesAutocomplete,
} from "../../../services/dictionary";
import Grid from "@mui/material/Unstable_Grid2";

const FilterPreferencesForwardRef = (props, ref) => {
  const { params, site } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      if (countyValues) {
        filter.userCountyPreferences = { countyId: { inq: countyValues } };
      }
      if (fowValues) {
        filter.userFieldsOfWorkCategoryPreferences = {
          fieldsOfWorkCategoryId: { inq: fowValues },
        };
      }
      return filter;
    },
  }));

  const [countyValues, setCountyValues] = useState([]);
  const [fowValues, setFowValues] = useState([]);

  const counties = params.counties ?? [];
  const fieldsOfWorkCategories = params.fieldsOfWorkCategories ?? [];

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={` col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <Grid container direction={"column"}>
          <Grid>
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                name={`${params.field}-counties`}
                multiple={true}
                options={counties}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value;
                }}
                getOptionLabel={(option) => option.name ?? ""}
                onChange={(event, value) => {
                  setCountyValues(value ?? []);
                }}
                value={countyValues}
                renderInput={(params) => (
                  <TextField {...params} placeholder={"Több is választható"} />
                )}
              ></Autocomplete>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                name={`${params.field}-fields-of-work-categories`}
                multiple={true}
                options={fieldsOfWorkCategories}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value;
                }}
                getOptionLabel={(option) => option.name ?? ""}
                onChange={(event, value) => {
                  setFowValues(value ?? []);
                }}
                value={fowValues}
                renderInput={(params) => (
                  <TextField {...params} placeholder={"Több is választható"} />
                )}
              ></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const FilterPreferences = forwardRef(FilterPreferencesForwardRef);

FilterPreferences.propTypes = {
  params: PropTypes.object,
};

export default FilterPreferences;
