import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgress } from "@mui/material";

function Loading() {
  return (
    <Grid container alignContent={"center"} justifyContent={"center"}>
      <Grid>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

export default Loading;
