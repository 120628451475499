import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FilterTextForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      if (params.getFilter) {
        return params.getFilter(value);
      } else if (value && params.exact) {
        filter[params.field] = value;
      } else if (value) {
        filter[params.field] = { like: `%${value}%` };
      }
      return filter;
    },
  }));

  const [value, setValue] = useState("");

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={` col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            type="text"
            name={params.field}
            autoComplete="off"
            onChange={(event) => {
              setValue(event.target.value ?? "");
            }}
            helperText={params.helperText ?? ""}
            value={value}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
const FilterText = forwardRef(FilterTextForwardRef);

FilterText.propTypes = {
  params: PropTypes.object,
};

export default FilterText;
