import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FilterWorkExperienceHcForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      if (name || companyName) {
        filter.userFieldsOfWorks = {};
        if (name) {
          filter.userFieldsOfWorks.name = { like: `%${name}%` };
        }
        if (companyName) {
          filter.userFieldsOfWorks.companyName = { like: `%${companyName}%` };
        }
      }
      return filter;
    },
  }));

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={` col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <Grid container alignItems={"center"}>
          <Grid>
            <FormControl sx={{ width: 150 }}>
              <TextField
                type="text"
                name={`${params.field}-name`}
                autoComplete="off"
                onChange={(event) => {
                  setName(event.target.value ?? "");
                }}
                label={"Beosztás"}
                value={name}
              />
            </FormControl>
          </Grid>
          <Grid>
            <FormControl sx={{ width: 150 }}>
              <TextField
                type="text"
                name={`${params.field}-companyName`}
                autoComplete="off"
                onChange={(event) => {
                  setCompanyName(event.target.value ?? "");
                }}
                label={"Munkahely"}
                value={companyName}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const FilterWorkExperienceHc = forwardRef(FilterWorkExperienceHcForwardRef);

FilterWorkExperienceHc.propTypes = {
  params: PropTypes.object,
};

export default FilterWorkExperienceHc;
