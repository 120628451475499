import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";

import UserHcs from "../../../pages/user/UserHcs";
import UserMds from "../../../pages/user/UserMds";
import DeletionLogs from "../../../pages/user/DeletionLogs";
import ImportUser from "../../../pages/user/components/ImportUser";
import {useRoles} from "../../../services/auth";
import {roleLevel} from "../../../services/user";

UserRoutes.propTypes = {};

export const pages = [
  {
    title: "Felnőtt",
    url: "hc",
    children: [":id", ""],
    element: <UserHcs />,
    elementIcon: <WorkIcon />,
  },
  {
    title: "Diák",
    url: "md",
    children: [":id", ""],
    element: <UserMds />,
    elementIcon: <SchoolIcon />,
  },
  {
    title: "Importálás",
    url: "import",
    children: [""],
    element: <ImportUser />,
    elementIcon: <ImportContactsIcon />,
  },
  {
    title: "Törlési napló",
    url: "deletion-logs",
    children: [":id", ""],
    element: <DeletionLogs />,
    elementIcon: <FolderDeleteIcon />,
    visible: (roles) => {
      return roleLevel(roles) >= 75;
    },
  },
];

function UserRoutes(props) {
  const roles = useRoles();
  return (
      <>
      {roles?.includes("hca") && (
        <Routes>
          <Route element={<Outlet />}>
            {pages.map((page) => (
                <Route key={page.url} path={page.url} element={page.element}>
                  {page.children.map((child) => (
                      <Route
                          key={`${page.url}/${child}`}
                          path={child}
                          element={page.element}
                      />
                  ))}
                </Route>
            ))}

            <Route index element={<Navigate to="/users/hc" />} />
          </Route>
        </Routes>)}
      </>
  );
}

export default UserRoutes;
