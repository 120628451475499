import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { blacklistUser } from "../../../services/user";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

const DialogBlacklistForwardRef = (props, ref) => {
  const { user } = props;

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    open() {
      setBlacklistDialogOpen(true);
    },
  }));

  const [blacklistDialogOpen, setBlacklistDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (user.isBlacklisted) {
      setChecked(true);
    }
    if (user.blacklistComment) {
      setComment(user.blacklistComment);
    }
  }, [user]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleBlacklistDialogAgree = () => {
    blacklistUser(user.id, checked, comment)
      .then((response) => {
        if (response.data.success) {
          setSnackbarMessage("Sikeresen módosítottad a felhasználót!");
          setSnackbarOpen(true);
          setTimeout(() => {
            window.history.back();
          }, 2000);
        } else {
          setSnackbarColor("error");
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        }
      })
      .catch((err) => console.error(err));
    setBlacklistDialogOpen(false);
  };
  const handleBlacklistDialogClose = () => {
    setBlacklistDialogOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Dialog
        open={blacklistDialogOpen}
        onClose={handleBlacklistDialogClose}
        aria-labelledby="blacklist-dialog-title"
        aria-describedby="blacklist-dialog-description"
      >
        <DialogTitle id="blacklist-dialog-title">
          {"Felhasználó feketelistás állapota"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component={"div"}
            id="blacklist-dialog-description"
          >
            <Grid container direction={"column"} spacing={2}>
              <Grid>Biztosan fekete listára akarod állítani a felhasználót?</Grid>
              <Grid>
                <FormControlLabel
                  required
                  control={<Switch checked={checked} onChange={handleChange} />}
                  label="Feketelistás?"
                />
              </Grid>
              <Grid>
                <TextField
                  label="Indoklás"
                  required={true}
                  value={comment}
                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBlacklistDialogClose} color={"error"}>
            Nem
          </Button>
          <Button
            onClick={handleBlacklistDialogAgree}
            variant={"contained"}
            color={"success"}
            autoFocus
          >
            Igen
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        open={snackbarOpen}
        message={snackbarMessage}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarColor}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const DialogBlacklist = forwardRef(DialogBlacklistForwardRef);

DialogBlacklist.propTypes = {
  user: PropTypes.object.isRequired,
};

export default DialogBlacklist;
