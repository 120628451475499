import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Alert,
  Button,
  Chip,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { usePermission } from "../../services/auth";
import { getPictureSrc, getUserForAdmin } from "../../services/user";
import Box from "@mui/material/Box";

import NewReleasesIcon from "@mui/icons-material/NewReleases";
import BadgeIcon from "@mui/icons-material/Badge";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import RuleIcon from "@mui/icons-material/Rule";
import CommentIcon from "@mui/icons-material/Comment";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Details from "./components/Details";
import moment from "moment";
import Uploads from "./components/Uploads";
import Positions from "./components/Positions";
import parse from "html-react-parser";
import Comments from "./components/Comments";
import Logs from "./components/Logs";
import md5 from "md5";
import LoginData from "./components/LoginData";

User.propTypes = {
  id: PropTypes.number,
  site: PropTypes.string,
};

function User(props) {
  const { id, site, options } = props;
  const [userSite, setUserSite] = useState(null);
  const [user, setUser] = useState(null);
  const [lastComment, setLastComment] = useState("");
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [tabHash, setTabHash] = useState("");
  const [avatar, setAvatar] = useState("");
  const [searchParams] = useSearchParams();

  const permissions = usePermission("user", id);

  const navigate = useNavigate();
  const { hash } = useLocation();

  useEffect(() => {
    setTabHash(hash);
  }, []);

  useEffect(() => {
    if (id) {
      getUserForAdmin(id)
        .then((response) => {
          console.log("user", response.data);
          setUser(response.data);
          const userSite =
            response.data.type === 10
              ? "md"
              : response.data.type === 20
              ? "hc"
              : "";
          setUserSite(userSite);

          if (response.data.userComments?.length > 0) {
            setLastComment(
              response.data.userComments[response.data.userComments.length - 1]
            );
          }
        })
        .catch((err) => console.error(err));
    } else {
      setUser({
        id: 0,
      });
    }
    return () => {
      setUser(null);
    };
  }, [id, searchParams]);

  useEffect(() => {
    if (["md", "hc"].includes(site)) {
      setUserSite(site);
      setUser({
        ...user,
        type: site === "hc" ? 20 : site === "md" ? 10 : 0,
      });
    }
  }, [site]);

  const handleTabChange = (event, newTab) => {
    const tabName = tabs?.[newTab]?.name ?? "";
    if (tabName) {
      navigate(`#${tabName}`);
    }
    setCurrentTab(newTab);
  };

  const statusChip = (user) => {
    const status = user.status;
    switch (status) {
      case 10:
        return <Chip size="small" label={"függőben"} />;
        break;
      case 20:
        return <Chip size="small" color={"info"} label={"validált"} />;
        break;
      case 25:
        if (!user.isActive) {
          return <Chip size="small" label={"Inaktív"} />;
        }
        return <Chip size="small" color={"success"} label={"egyszerűsített"} />;
        break;
      case 30:
        if (!user.isActive) {
          return <Chip size="small" label={"Inaktív"} />;
        }
        return <Chip size="small" color={"success"} label={"aktív"} />;
        break;
      case 40:
        return <Chip size="small" color={"secondary"} label={"törölt"} />;
        break;
    }
    return <Chip size="small" label={"ismeretlen"} />;
  };

  const a11yProps = (index) => {
    return {
      id: `user-tab-${index}`,
      "aria-controls": `user-tabpanel-${index}`,
    };
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`user-tabpanel-${index}`}
        aria-labelledby={`user-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
      </div>
    );
  };

  useEffect(() => {
    if (user) {
      const userTabs = [
        {
          name: "user",
          label: "Alapadatok",
          icon: <BadgeIcon></BadgeIcon>,
          element: (
            <Details
              id={id}
              user={user}
              site={userSite}
              options={options}
            ></Details>
          ),
          permission: () => {
            return true;
          },
        },
        {
          name: "document",
          label: "Feltöltött fájlok",
          icon: <TextSnippetIcon></TextSnippetIcon>,
          element: <Uploads id={id} user={user} site={userSite}></Uploads>,
          permission: () => permissions.includes("read"),
        },
        {
          name: "position",
          label: "Pozíciók",
          icon: <RuleIcon></RuleIcon>,
          element: <Positions user={user} site={userSite}></Positions>,
          permission: () => permissions.includes("read"),
        },
        {
          name: "comment",
          label: "Megjegyzések",
          icon: <CommentIcon></CommentIcon>,
          element: <Comments user={user} site={userSite}></Comments>,
          permission: () => permissions.includes("read"),
        },
        {
          name: "log",
          label: "Napló",
          icon: <MenuBookIcon></MenuBookIcon>,
          element: <Logs user={user} site={userSite}></Logs>,
          permission: () => permissions.includes("read"),
        },
      ];
      if (permissions.includes("logindata")) {
        userTabs.push({
          name: "login",
          label: "Belépési adatok",
          icon: <VpnKeyIcon></VpnKeyIcon>,
          element: <LoginData user={user}></LoginData>,
          permission: () => permissions.includes("logindata"),
        });
      }
      setTabs(userTabs);

      if (tabHash && userTabs.find((item) => `#${item.name}` === tabHash)) {
        setCurrentTab(
          userTabs.findIndex((item) => `#${item.name}` === tabHash)
        );
      }

      if (user.picture && user.id) {
        setAvatar(getPictureSrc(user.picture, user.id));
      } else {
        setAvatar("");
      }
    } else {
      setTabs([]);
      setAvatar("");
    }
    return () => {
      setTabs([]);
      setAvatar("");
    };
  }, [user, permissions]);

  const handleCvDownload = () => {
    let siteUrl = "";
    if (process.env.REACT_APP_LOCALHOST) {
      siteUrl = "http://localhost:4200";
    } else if (process.env.REACT_APP_DEV) {
      siteUrl =
        userSite === "md"
          ? "https://dev.minddiak.hu"
          : userSite === "hc"
          ? "https://dev.humancentrum.hu"
          : "";
    } else {
      siteUrl =
        userSite === "md"
          ? "https://minddiak.hu"
          : userSite === "hc"
          ? "https://humancentrum.hu"
          : "";
    }
    if (siteUrl && id && user) {
      const hash = md5(`/HCMD:${userSite}:user:${id}:email:${user.email}/`);
      window.open(`${siteUrl}/member/admin-cv/${id}/${hash}`, "cv");
    }
  };

  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <Grid container width={"100%"} alignItems={"center"}>
            <Grid flexGrow={1}>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Munkavállaló{" "}
                  {!permissions.includes("update") &&
                  !permissions.includes("create")
                    ? "megtekintése"
                    : id
                    ? "szerkesztés"
                    : "hozzáadás"}
                </span>
              </h3>
              {user && user.id > 0 && (
                <>
                  <Grid container>
                    {avatar && (
                      <Grid>
                        <img
                          src={avatar}
                          alt={`${user.lastName} ${user.firstName}`}
                          className={"user-avatar"}
                        />
                      </Grid>
                    )}
                    <Grid>
                      <h1 className={user.isBlacklisted ? "text-danger" : ""}>
                        {user.lastName} {user.firstName}
                        {user.isBlacklisted && (
                          <>
                            <Tooltip
                              sx={{ ml: 2 }}
                              title={parse(user.blacklistComment)}
                              arrow
                            >
                              <Chip
                                label="Feketelistás"
                                icon={<NewReleasesIcon />}
                                color={"error"}
                              />
                            </Tooltip>
                          </>
                        )}
                        {lastComment && (
                          <Tooltip
                            sx={{ ml: 2 }}
                            title={parse(
                              `${lastComment.text}<hr class="comment-hr" />${
                                lastComment.admin
                                  ? lastComment.admin.lastName +
                                    " " +
                                    lastComment.admin.firstName
                                  : ""
                              } <i>(${moment(lastComment.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )})</i>`
                            )}
                            arrow
                          >
                            <CommentIcon />
                          </Tooltip>
                        )}
                        <Typography component={"span"} sx={{ ml: 2 }}>
                          {statusChip(user)}
                        </Typography>
                      </h1>
                      <h5>
                        {user.lastLoginTime && (
                          <>
                            <span>
                              Utolsó bejelentkezés ideje:{" "}
                              {moment(user.lastLoginTime).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </span>
                            <br />
                          </>
                        )}
                        {user.createTime && (
                          <>
                            <small>
                              Regisztráció dátuma:{" "}
                              {moment(user.createTime).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </small>
                            <br />
                          </>
                        )}
                        {user.updateTime && (
                          <>
                            <small>
                              Utolsó módosítás dátuma:{" "}
                              {moment(user.updateTime).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </small>
                            <br />
                          </>
                        )}
                      </h5>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid textAlign={"right"}>
              <Box sx={{ mb: 3 }}>
                <Grid container spacing={3}>
                  <Grid>
                    <Button
                      variant={"contained"}
                      color={"secondary"}
                      onClick={() => handleCvDownload()}
                    >
                      Adatlap letöltés
                    </Button>
                  </Grid>
                  <Grid>
                    <Link to={"/users"}>
                      <Button variant={"contained"} color={"primary"}>
                        Vissza
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
              {/*{user && user.id > 0 && (
                <FormGroup>
                  <FormControlLabel
                    disabled
                    control={<Switch defaultChecked={!!user.isPublished} />}
                    label="Publikált"
                  />
                  <FormControlLabel
                    disabled
                    control={<Switch defaultChecked={!!user.isExported} />}
                    label="Exportált"
                  />
                </FormGroup>
              )}*/}
            </Grid>
          </Grid>
        </div>
        {user && (
          <div className="card-body py-3">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label="user tabs"
              >
                {tabs.map((tab, index) => {
                  return (
                    <Tab
                      key={tab.name}
                      label={tab.label}
                      icon={tab.icon}
                      iconPosition="start"
                      {...a11yProps(index)}
                      disabled={tab?.permission ? !tab.permission() : true}
                    />
                  );
                })}
              </Tabs>
            </Box>
            {tabs.map((tab, index) => (
              <TabPanel key={tab.name} value={currentTab} index={index}>
                {tab.permission() ? (
                  tab.element
                ) : (
                  <Alert severity="error">
                    Nincs jogosultságod ehhez a részhez!
                  </Alert>
                )}
              </TabPanel>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

// @ts-ignore
export default User;
