import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Popper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { getPictureSrc } from "../../../services/user";
import moment from "moment";

const UserPopperForwardRef = (props, ref) => {
  const {} = props;

  useImperativeHandle(ref, () => ({
    elementPause() {
      setPause(true);
      this.elementOff();
      setTimeout(() => {
        setPause(false);
      }, 1000);
    },
    // elementOn(targetElement, user) {
    elementOn(row, user) {
      if (!pause) {
        const targetElement = document.getElementById(`row-${row.id}`);
        if (targetElement?.parentNode?.parentNode) {
          setAnchorEl(targetElement.parentNode.parentNode);
        }
        setUser(user);
      }
    },
    elementOff() {
      setAnchorEl(null);
      setUser(null);
    },
  }));

  const [pause, setPause] = useState(false);
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  let age = 0;
  if (user?.birthDate) {
    age = parseInt(
      moment.duration(moment().diff(moment(user.birthDate))).asYears()
    );
  }
  let gender = "";
  if (user?.sex) {
    gender = user.sex === 1 ? "F" : user.sex === 2 ? "N" : "";
  }
  let ageGender = `${age ? age : ""} ${gender}`;

  return (
    <>
      {anchorEl && (
        <Popper id={"userCard"} open={open} anchorEl={anchorEl}>
          {user && (
            <Card sx={{ display: "flex" }}>
              {user.picture && (
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={getPictureSrc(user.picture, user.id)}
                  alt={`${user.lastName} ${user.firstName}`}
                />
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="h5">
                    {user.lastName} {user.firstName}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    {ageGender}
                  </Typography>
                  {user.lastLoginTime && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="div"
                    >
                      Utolsó belépés ideje:
                      <br />
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component="span"
                      >
                        {moment(user.lastLoginTime).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </Typography>
                    </Typography>
                  )}
                </CardContent>
              </Box>
            </Card>
          )}
        </Popper>
      )}
    </>
  );
};
const UserPopper = forwardRef(UserPopperForwardRef);

UserPopper.propTypes = {};

export default UserPopper;
