import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  me: false,
  roles: false,
  permissions: {
    user: {},
    position: {},
    office: {},
    dictionary: {},
    company: {},
    employee: {},
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMe: (state, action) => {
      state.me = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setPermission: (state, action) => {
      state.permissions[action.payload.type][action.payload.id] =
        action.payload.permissions;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMe, setRoles, setPermission } = authSlice.actions;

export default authSlice.reducer;
