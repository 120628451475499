import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { Chip, IconButton, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { darken, lighten } from "@mui/material/styles";
import { blue, red } from "@mui/material/colors";
import {getMyPositionCount, getMyPositions, getPositions} from "../../../services/position";
import Lists from "../../../modules/hcmd/components/Lists";
import {usePermission, useRoles} from "../../../services/auth";
import Box from "@mui/material/Box";
import moment from "moment";

Positions.propTypes = {
  onChange: PropTypes.func,
};

function Positions(props) {
  const { onChange } = props;

  const roles = useRoles();
  const permissions = usePermission("position");

  const [myPositions, setMyPositions] = useState([]);
  const [selected, setSelected] = useState(null);

  const refList = useRef(null);

  useEffect(() => {
    if (permissions.includes("read")) {
      let fn = getMyPositions;
      if (roles?.includes('hca_r')) {
        fn = getPositions;
      }
      fn(
        {
          type: { inq: [10, 20] },
          status: { inq: [10, 20, 30] },
        },
        ["id DESC"],
        null,
        0,
        ["positionUsers"]
      )
        .then((response) => {
          response.data.map(position => {
            position.applicants = position.positionUsers?.length ?? 0;
            if (position.applicants) {
              position.lastApplication = position.positionUsers.sort(
                  (a, b) => {
                    if (a.createTime && b.createTime && a.createTime > b.createTime) {
                      return -1;
                    } else if (
                        a.createTime &&
                        b.createTime &&
                        a.createTime < b.createTime
                    ) {
                      return 1;
                    }
                    return 0;
                  },
              )[0].createTime;
              if (position.lastApplication) {
                position.lastApplication = moment(position.lastApplication).format('YYYY-MM-DD HH:mm:ss');
              }
            }
          });
          setMyPositions(response.data);
        })
        .catch((err) => console.error(err));
    }
  }, [permissions]);

  const handleShowApplicants = (positionId) => {
    setSelected(positionId);
    onChange(positionId);
    setTimeout(() => {
      const grid = refList.current.returnGrid();
      if (grid) {
        const rowSelected = grid.getElementsByClassName("row-selected");
        if (rowSelected.length) {
          rowSelected[0].scrollIntoView();
        }
      }
    }, 1500);
  };

  let columns = [
    {
      field: "id",
      headerName: "ID",
      hide: false,
      width: 70,
    },
    {
      field: "name",
      headerName: "Megnevezés",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "company",
      headerName: "Cég",
      sortable: false,
      valueGetter: (params) => {
        return `${params.row.company?.name ?? ""}`;
      },
    },
    {
      field: "applicants",
      headerName: "Jelentkezők",
      // sortable: false,
      width: 90,
      renderCell: (params) => {
        return params.row.applicants ?? params.row.positionUsers?.length ?? 0;
      },
    },
    {
      field: "lastApplication",
      headerName: "Utolsó jelentkezés",
      // sortable: false,
      width: 140,
      renderCell: (params) => {
        if (params.row.lastApplication) {
          return params.row.lastApplication;
        }
        const arr = params.row.positionUsers ?? [];
        if (arr.length > 0) {
          return moment(
            arr.sort((a, b) => {
              if (a.createTime > b.createTime) {
                return -1;
              } else if (a.createTime < b.createTime) {
                return 1;
              }
              return 0;
            })[0].createTime
          ).format("YYYY-MM-DD HH:mm:ss");
        }
        return "";
      },
    },
    {
      field: "status",
      defaultValue: 10,
      headerName: "Státusz",
      width: 110,
      renderCell: (params) => {
        switch (params.row.status) {
          case 10:
            return <Chip size="small" label={"szerkesztett"} />;
            break;
          case 20:
            return <Chip size="small" color={"info"} label={"rögzített"} />;
            break;
          case 30:
            return (
              <Chip size="small" color={"warning"} label={"jóváhagyott"} />
            );
            break;
          case 40:
            return (
              <Chip size="small" color={"success"} label={"megvalósult"} />
            );
            break;
          case 50:
            return <Chip size="small" color={"secondary"} label={"lezárult"} />;
            break;
          case 60:
            return <Chip size="small" color={"error"} label={"törölt"} />;
            break;
        }
        return <Chip size="small" label={"ismeretlen"} />;
      },
    },
    {
      field: "actions",
      headerName: "Műveletek",
      width: 120,
      align: "right",
      headerAlign: "right",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: "text-dark",
      cellClassName: "text-dark",
      renderCell: (params) => {
        return (
          <>
            <Link to={`${header.url}/${params.row.id}#applicants`}>
              <IconButton
                variant={"contained"}
                aria-label="Megnyitás"
                size="large"
                className={"me-3"}
              >
                <OpenInNewIcon />
              </IconButton>
            </Link>
            <IconButton
              variant={"contained"}
              aria-label="Jelentkezők"
              size="large"
              className={"me-3"}
              onClick={() => handleShowApplicants(params.row.id)}
            >
              <GroupsIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  if (roles.includes('hca_c')) {
    columns = columns.filter(item => item.field !== 'applicants' && item.field !== 'lastApplication');
  }

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
  const getSelectedBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
  const header = {
    title: "Pozíciók",
    url: "/positions",
    item: "pozíció",
    listskip: true,
    nopadding: true,
    disableFilterToolbar: true,
    styles: ({ theme }) => ({
      "& .row-selected": {
        fontWeight: "bold",
      },
      "& .row-md": {
        backgroundColor: getBackgroundColor(red[200], theme.palette.mode),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            red[200],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            red[200],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              red[200],
              theme.palette.mode
            ),
          },
        },
      },
      "& .row-hc": {
        backgroundColor: getBackgroundColor(blue[200], theme.palette.mode),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            blue[200],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            blue[200],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              blue[200],
              theme.palette.mode
            ),
          },
        },
      },
    }),
    getRowClassName: (params) => {
      let className = params.row.id === selected ? "row-selected " : "";
      if (params.row.type === 10) {
        className += "row-hc";
      } else if (params.row.type === 20) {
        className += "row-md";
      }
      return className;
    },
  };

  return (
    <>
      {permissions.includes("read") && myPositions.length > 0 && (
        <Lists
          ref={refList}
          header={header}
          permissions={permissions}
          getFunc={myPositions}
          // getCountFunc={getMyPositionCount}
          columns={columns}
          dataGrid={{ disableColumnFilter: true }}
        ></Lists>
      )}
    </>
  );
}

export default Positions;
