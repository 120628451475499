import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/hu";
import moment from "moment";

const FilterDateIntervalForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      if (params.getFilter) {
        return params.getFilter([valueFrom, valueTo]);
      } else if (valueFrom || valueTo) {
        filter[params.field] = {
          between: [
            moment(valueFrom).format("YYYY-MM-DD 00:00:00"),
            moment(valueTo).format("YYYY-MM-DD 23:59:59"),
          ],
        };
      }
      return filter;
    },
  }));

  const [valueFrom, setValueFrom] = useState(null);
  const [valueTo, setValueTo] = useState(null);

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={` col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <Grid
          container
          alignItems={"center"}
          sx={{ minWidth: 350, width: "100%" }}
        >
          <Grid>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="hu"
            >
              <DatePicker
                className={`form-control form-control-lg form-control-solid`}
                name={`${params.field}-from`}
                autoComplete="off"
                value={valueFrom}
                onChange={(newValue) => {
                  setValueFrom(newValue);
                }}
                sx={{ width: 140 }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid> - </Grid>
          <Grid>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="hu"
            >
              <DatePicker
                className={`form-control form-control-lg form-control-solid`}
                name={`${params.field}-to`}
                autoComplete="off"
                value={valueTo}
                onChange={(newValue) => {
                  setValueTo(newValue);
                }}
                sx={{ width: 140 }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const FilterDateInterval = forwardRef(FilterDateIntervalForwardRef);

FilterDateInterval.propTypes = {
  params: PropTypes.object,
};

export default FilterDateInterval;
