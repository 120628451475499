import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import BooleanInput from "./BooleanInput";
import NumberInput from "./NumberInput";
import NonInput from "./NonInput";
import CheckboxInput from "./CheckboxInput";
import TextEditorInput from "./TextEditorInput";
import AutocompleteInput from "./AutocompleteInput";
import DateInput from "./DateInput";
import HiddenInput from "./HiddenInput";
import Box from "@mui/material/Box";
import UploadInput from "./UploadInput";
import DocumentInput from "./DocumentInput";
import BrowserInput from "./BrowserInput";
import CoverInput from "./CoverInput";
import PasswordInput from "./PasswordInput";

const InputForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    callInput(args) {
      if (refInput.current?.callMe) {
        return refInput.current?.callMe(args);
      } else {
        setCallArgs(args);
      }
    },
    customValidation() {
      if (refInput.current?.customError) {
        console.log('customValid', refInput.current?.customError());
        return refInput.current?.customError();
      }
      return null;
    },
    returnColumn() {
      return column;
    },
    returnInput() {
      if (refInput.current) {
        return refInput.current;
      }
    }
  }));

  const {
    isEditable,
    formik,
    initialValue,
    isFormDirty,
    column,
    permissions,
    dirtying,
    values,
    ...args
  } = props;

  const [callArgs, setCallArgs] = useState(null);

  let editMode = isEditable;
  let visible = true;
  if (editMode && column.editable === false) {
    visible = false;
  } else if (
    editMode &&
    column.field === "id" &&
    column.fieldType !== "hidden"
  ) {
    editMode = false;
  }
  if (
    editMode &&
    !(permissions.includes("update") || permissions.includes("create"))
  ) {
    editMode = false;
  }
  if (!editMode && column.onlyIfEditable === true) {
    visible = false;
  }
  if (
    !editMode &&
    (column.fieldType === "debug" ||
      column.fieldType === "password" ||
      column.field === "id" ||
      column.fieldType === "hidden")
  ) {
    visible = false;
  }

  const refInput = useRef(null);

  useEffect(() => {
    if (refInput.current && callArgs) {
      if (refInput.current?.callMe) {
        refInput.current?.callMe(callArgs);
      }
      setCallArgs(null);
    }
  }, [callArgs, refInput]);

  return (
    <Box className={"mb-6"}>
      {visible && (
        <>
          {editMode && (
            <>
              {column.fieldType === "text" && (
                <TextInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                />
              )}
              {column.fieldType === "password" && (
                <PasswordInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                />
              )}
              {column.fieldType === "texteditor" && (
                <TextEditorInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                />
              )}
              {column.fieldType === "number" && (
                <NumberInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                />
              )}
              {column.fieldType === "select" && (
                <SelectInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "autocomplete" && (
                <AutocompleteInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "date" && (
                <DateInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "checkbox" && (
                <CheckboxInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "boolean" && (
                <BooleanInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "hidden" && (
                <HiddenInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "upload" && (
                <UploadInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  values={values}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "document" && (
                <DocumentInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  values={values}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "browser" && (
                <BrowserInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  values={values}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "cover" && (
                <CoverInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  dirtying={dirtying}
                  values={values}
                  ref={refInput}
                  {...args}
                />
              )}
              {column.fieldType === "" && (
                <NonInput
                  formik={formik}
                  initialValue={initialValue}
                  column={column}
                  isFormDirty={isFormDirty}
                  values={values}
                  ref={refInput}
                  {...args}
                />
              )}
            </>
          )}
          {!editMode && (
            <NonInput
              formik={formik}
              initialValue={initialValue}
              column={column}
              isFormDirty={isFormDirty}
              values={values}
              ref={refInput}
              {...args}
            />
          )}
        </>
      )}
    </Box>
  );
};

const Input = forwardRef(InputForwardRef);

Input.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  isFormDirty: PropTypes.bool,
  column: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
  dirtying: PropTypes.func.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
};

export default Input;
