import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Alert,
  Button,
  Divider,
  FormControl,
  Snackbar,
  TextField,
} from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PasswordIcon from "@mui/icons-material/Password";
import {
  emailRegex,
  LinearProgressWithLabel,
} from "../../../services/constants";
import {
  saveUserNewEmail,
  saveUserNewPassword,
  verifyUserNewEmail,
} from "../../../services/user";

function LoginData(props) {
  const { user } = props;

  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState(user.email ?? "");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  useEffect(() => {
    let error = "";
    if (!email.match(emailRegex)) {
      error = "Hibás e-mail cím formátum!";
    }
    if (!email) {
      error = "Az e-mail cím mező kötelező!";
    }
    if (email === user.email) {
      error = "Az e-mail cím azonos a jelenleg használttal!";
    }
    setEmailError(error);
  }, [email]);

  useEffect(() => {
    let error = "";
    let errorConfirm = "";
    if (password.length < 6) {
      error = "Legalább 6 karakteres jelszó legyen!";
    }
    if (!password) {
      error = "A jelszó mező kötelező!";
    }
    if (password && password !== passwordConfirm) {
      errorConfirm = "A jelszó és a megerősítés nem egyezik meg!";
    }
    setPasswordError(error);
    setPasswordConfirmError(errorConfirm);
  }, [password, passwordConfirm]);

  const handleSaveEmail = () => {
    setSaving(true);
    verifyUserNewEmail(user.id, email)
      .then((checkResponse) => {
        if (checkResponse.data.success) {
          saveUserNewEmail(user.id, email)
            .then((response) => {
              setSaving(false);
              if (response.data.success) {
                user.email = email;
                setSnackbarColor("success");
                setSnackbarMessage("Sikeres mentés!");
                setSnackbarOpen(true);
              } else {
                setSnackbarColor("error");
                setSnackbarMessage(response.data.message);
                setSnackbarOpen(true);
              }
            })
            .catch((err) => {
              setSaving(false);
              setSnackbarColor("error");
              setSnackbarMessage("Hiba történt!");
              setSnackbarOpen(true);
              console.error(err);
            });
        } else {
          setSaving(false);
          setSnackbarColor("error");
          setSnackbarMessage(checkResponse.data.message);
          setSnackbarOpen(true);
        }
      })
      .catch((err) => {
        setSaving(false);
        setSnackbarColor("error");
        setSnackbarMessage("Hiba történt!");
        setSnackbarOpen(true);
        console.error(err);
      });
  };

  const handleSavePassword = () => {
    setSaving(true);
    saveUserNewPassword(user.id, password)
      .then((response) => {
        setSaving(false);
        if (response.data.success) {
          setPassword("");
          setPasswordConfirm("");
          setSnackbarColor("success");
          setSnackbarMessage("Sikeres mentés!");
          setSnackbarOpen(true);
        } else {
          setSnackbarColor("error");
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        }
      })
      .catch((err) => {
        setSaving(false);
        setSnackbarColor("error");
        setSnackbarMessage("Hiba történt!");
        setSnackbarOpen(true);
        console.error(err);
      });
  };

  return (
    <>
      <Grid container direction={"column"}>
        <Grid>
          <h3>E-mail cím módosítása</h3>
          <div className="row my-3">
            <label className={`col-lg-4 col-form-label fw-bold fs-6`}>
              E-mail cím
            </label>
            <div className={`fv-row col-lg-8`}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  type="text"
                  name={"email"}
                  autoComplete="off"
                  onChange={(event) => {
                    setEmail(event.target.value ?? "");
                  }}
                  value={email}
                  error={!!emailError}
                  helperText={emailError ?? ""}
                />
              </FormControl>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <Button
                variant={"contained"}
                size={"small"}
                startIcon={<ForwardToInboxIcon />}
                color={"success"}
                disabled={saving || email === user.email || emailError}
                onClick={() => {
                  handleSaveEmail();
                }}
                sx={{ my: 2, p: 1 }}
              >
                E-mail cím módosítása
              </Button>
            </div>
          </div>
        </Grid>
        <Grid>
          <Divider sx={{ my: 3 }}></Divider>
        </Grid>
        <Grid>
          <h3>Jelszó módosítása</h3>
          <div className="row my-3">
            <label className={`col-lg-4 col-form-label fw-bold fs-6`}>
              Új jelszó
            </label>
            <div className={`fv-row col-lg-8`}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  type="password"
                  name={"password"}
                  autoComplete="off"
                  onChange={(event) => {
                    setPassword(event.target.value ?? "");
                  }}
                  value={password}
                  error={!!passwordError}
                  helperText={passwordError ?? ""}
                />
              </FormControl>
            </div>
          </div>
          <div className="row my-3">
            <label className={`col-lg-4 col-form-label fw-bold fs-6`}>
              Jelszó megerősítése
            </label>
            <div className={`fv-row col-lg-8`}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  type="password"
                  name={"passwordConfirm"}
                  autoComplete="off"
                  onChange={(event) => {
                    setPasswordConfirm(event.target.value ?? "");
                  }}
                  value={passwordConfirm}
                  error={!!passwordConfirmError}
                  helperText={passwordConfirmError ?? ""}
                />
              </FormControl>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              {saving && (
                <LinearProgressWithLabel value={"Mentés folyamatban..."} />
              )}
              <Button
                variant={"contained"}
                size={"small"}
                startIcon={<PasswordIcon />}
                color={"success"}
                disabled={
                  saving || !password || passwordError || passwordConfirmError
                }
                onClick={() => {
                  handleSavePassword();
                }}
                sx={{ my: 2, p: 1 }}
              >
                Jelszó módosítása
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        open={snackbarOpen}
        message={snackbarMessage}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarColor}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

LoginData.propTypes = {
  user: PropTypes.object.isRequired,
};

export default LoginData;
