import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, FormControl, IconButton, TextField } from "@mui/material";
import {
  formError,
  formErrorMsg,
  formFieldError,
  formGetValue,
  formSetValue,
} from "./formUtils";
import ImageUploading from "react-images-uploading";
import { getPictureSrc } from "../../../services/user";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying, values } = props;

  const [value, setValue] = useState("");
  const [inited, setInited] = useState(false);
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  // console.log("UploadInput initialValue", initialValue);

  useEffect(() => {
    const initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      setValue(initValue);
    }
    setInited(true);
  }, [initialValue, column]);

  useEffect(() => {
    if (inited) {
      if (typeof value === "object" && value?.data) {
        formSetValue(formik, column, JSON.stringify(value));
      } else if (
        typeof value === "string" &&
        value.match(/^{["']data["']:["']data\:/)
      ) {
        formSetValue(formik, column, value);
      } else {
        if (value && values.id) {
          setImages([{ dataUrl: getPictureSrc(value, values.id) }]);
        } else if (value) {
          setImages([{ dataUrl: getPictureSrc(value) }]);
        }
        formSetValue(formik, column, value);
      }
      dirtying();
      formError(formik, column);
    }
  }, [value]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    setValue({
      data: imageList[0].dataUrl,
      meta: {
        filename: imageList[0].file.name,
        filesize: imageList[0].file.size,
      },
    });
  };

  return (
    <div className="row">
      {column.headerName && (
        <label
          className={`col-lg-4 col-form-label fw-bold fs-6 ${
            column.validation?.required && "required"
          }`}
        >
          {column.headerName}
        </label>
      )}
      <div className={`fv-row ${column.headerName ? "col-lg-8" : "col-lg-12"}`}>
        <FormControl sx={{ width: "100%" }}>
          <ImageUploading
            multiple={false}
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="dataUrl"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
              errors,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                {!imageList.length && (
                  <Button
                    variant={"contained"}
                    color={isDragging ? "secondary" : "primary"}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Kép feltöltése (kattintson vagy húzza rá)
                  </Button>
                )}
                &nbsp;
                {/*<button onClick={onImageRemoveAll}>Remove all images</button>*/}
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["dataUrl"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <IconButton
                        onClick={() => onImageUpdate(index)}
                        sx={{ mb: 2 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color={"error"}
                        onClick={() => onImageRemove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
                {errors && (
                  <div>
                    {errors.maxNumber && (
                      <span>Number of selected images exceed maxNumber</span>
                    )}
                    {errors.acceptType && (
                      <span>Your selected file type is not allow</span>
                    )}
                    {errors.maxFileSize && (
                      <span>Selected file size exceed maxFileSize</span>
                    )}
                    {errors.resolution && (
                      <span>
                        Selected file is not match your desired resolution
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </ImageUploading>
        </FormControl>
        {formErrorMsg(formik, column)}
      </div>
    </div>
  );
};

const UploadInput = forwardRef(InputForwardRef);

UploadInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default UploadInput;
