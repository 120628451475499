import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  store: {},
};

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    setDictionaryStore: (state, action) => {
      if (action.payload.attr && action.payload.items) {
        state.store = {
          ...state.store,
          [action.payload.attr]: action.payload.items,
        };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDictionaryStore } = dictionarySlice.actions;

export default dictionarySlice.reducer;
