import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  getNotificationTemplateCompany,
  getUserApplicantStates,
  postNotificationTemplateCompany,
} from "../../../services/position";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Alert,
  Button,
  Divider,
  FormControl, FormControlLabel, FormGroup,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar, Switch,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Quill from "quill";
import "quill-paste-smart";

const QuillEditorForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    returnQuill() {
      return quill;
    },
    set(content) {
      // console.log("quill =", content);
      if (quill) {
        quill.root.innerHTML = content ?? "";
      }
    },
    enable(disabled) {
      if (quill) {
        quill.enable(disabled);
      }
    }
  }));

  const { type, content, onChange } = props;

  const [quill, setQuill] = useState(null);

  const refQuill = useRef(null);

  useEffect(() => {
    setQuill(
      new Quill(`#email-body-${type}`, {
        theme: "snow",
        // debug: "info",
        modules: {
          clipboard: {
            allowed: {
              tags: [
                "a",
                "b",
                "strong",
                "u",
                "s",
                "i",
                "p",
                "br",
                "ul",
                "ol",
                "li",
                "span",
              ],
              attributes: ["href", "rel", "target", "class"],
            },
            keepSelection: true,
            substituteBlockElements: true,
            magicPasteLinks: true,
            hooks: {
              uponSanitizeElement(node, data, config) {
                console.log(node);
              },
            },
          },
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote"],

            // [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            // [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            // [{ font: [] }],
            // [{ align: [] }],

            ["clean"], // remove formatting button
          ],
        },
      })
    );
    return () => {
      setQuill(null);
    };
  }, []);

  useEffect(() => {
    if (quill) {
      // window.WEBSPELLCHECKER.init({
      //   container: quill.root,
      // });

      quill.on("text-change", function (delta, oldDelta, source) {
        console.log("quill text-change", quill, delta, oldDelta, source);
        onChange(quill);
      });
    }
  }, [quill]);

  return (
    <div id={`email-body-${type}`} ref={refQuill}>
      {content ?? ""}
    </div>
  );
};
const QuillEditor = forwardRef(QuillEditorForwardRef);

function CompanyNotificationTemplate(props) {
  const { companyId } = props;

  const emptyTemplates = [
    {
      status: null,
      subjectHc: "Értesítés",
      bodyHc:
        '<p style="color: #969AA1; font-size: 18px; line-height: 28px; margin-bottom: 40px;">Ide jön az értesítés szövege...</p>',
      subjectMd: "Értesítés",
      bodyMd:
        '<p style="color: #969AA1; font-size: 18px; line-height: 28px; margin-bottom: 40px;">Ide jön az értesítés szövege...</p>',
    },
    {
      status: [30],
      subjectHc: "Értesítés interjúról",
      bodyHc:
        '<p style="color: #969AA1; font-size: 18px; line-height: 28px; margin-bottom: 40px;">Köszönettel vettük pályázatát a <b>{{ position.name }}</b> pozícióra. Állásajánlatunkkal kapcsolatban egyeztetésünk szerinti helyen és időben várjuk Önt egy személyes interjúra.</p>',
      subjectMd: "Értesítés interjúról",
      bodyMd:
        '<p style="color: #969AA1; font-size: 18px; line-height: 28px; margin-bottom: 40px;">Köszönettel vettük pályázatod a <b>{{ position.name }}</b> munkára. Állásajánlatunkkal kapcsolatban egyeztetésünk szerinti helyen és időpontban várunk egy személyes interjúra.</p>',
    },
    {
      status: [50],
      subjectHc: "Értesítés kiválasztásról",
      bodyHc:
        '<p style="color: #969AA1; font-size: 18px; line-height: 28px; margin-bottom: 40px;">Örömmel értesítjük, hogy a meghirdetett <b>{{ position.name }}</b> pozícióra kiválasztásra került.<br>\n' +
        "            <br>Gratulálunk!<br></p>",
      subjectMd: "Értesítés kiválasztásról",
      bodyMd:
        '<p style="color: #969AA1; font-size: 18px; line-height: 28px; margin-bottom: 40px;">Örömmel értesítünk, hogy a meghirdetett <b>{{ position.name }}</b> munkára kiválasztásra kerültél.<br>\n' +
        "            <br>Gratulálunk!<br></p>",
    },
    {
      status: [80],
      subjectHc: "Értesítés",
      bodyHc:
        '<p style="color: #969AA1; font-size: 18px; line-height: 28px; margin-bottom: 40px;">Köszönettel vettük pályázatát a honlapunkon meghirdetett <b>{{ position.name }}</b> pozícióra. Sajnos ez alkalommal nem tudunk lehetőséget biztosítani a személyes bemutatkozásra.<br><br>\n' +
        '            Kérjük, tekintse meg <a href="https://humancentrum.hu/allasok-290" style="color:#00acf0; text-decoration:none">aktuális állásajánlatainkat</a>, ahol további munkalehetőségeket talál. Ha szeretne többet megtudni az önéletrajz szerkesztés, interjúk és kiválasztási folyamat részleteiről látogasson el a <a href="https://humancentrum.hu/karriertanacsok-310">Tanácsok pályázóknak</a> oldalra.<br><br>\n' +
        "            Álláskereséséhez további sok sikert kívánunk!<br></p>",
      subjectMd: "Értesítés",
      bodyMd:
        '<p style="color: #969AA1; font-size: 18px; line-height: 28px; margin-bottom: 40px;">Köszönettel vettük pályázatodat a honlapunkon meghirdetett <b>{{ position.name }}</b> munkára, de ez alkalommal más jelöltet választottunk.<br><br>\n' +
        '            Kérjük, tekintsd meg <a href="https://minddiak.hu/diakmunka-226" style="color:#ed202a; text-decoration:none">aktuális diákmunkáinkat</a>, ahol további álláslehetőségeket találsz.<br><br>\n' +
        "            Munkakeresésedhez további sok sikert kívánunk!<br></p>",
    },
  ];

  const [states, setStates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [state, setState] = useState(undefined);
  const [original, setOriginal] = useState(false);
  const [originalTemplate, setOriginalTemplate] = useState({subjectHc: "", bodyHc: "", subjectMd: "", bodyMd: "", inactive: false, original: true});
  // const [inactive, setInactive] = useState(false);
  // const [inactiveDisabled, setInactiveDisabled] = useState(true);
  const [subjectHc, setSubjectHc] = useState(emptyTemplates[0].subjectHc);
  const [bodyHc, setBodyHc] = useState(emptyTemplates[0].bodyHc);
  const [subjectErrorHc, setSubjectErrorHc] = useState("");
  const [bodyErrorHc, setBodyErrorHc] = useState("");
  const [subjectMd, setSubjectMd] = useState(emptyTemplates[0].subjectMd);
  const [bodyMd, setBodyMd] = useState(emptyTemplates[0].bodyMd);
  const [subjectErrorMd, setSubjectErrorMd] = useState("");
  const [bodyErrorMd, setBodyErrorMd] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const refQuillEditorHc = useRef(null);
  const refQuillEditorMd = useRef(null);
  const refTemplate = useRef(emptyTemplates[0]);
  const refOriginal = useRef(null);

  useEffect(() => {
    getUserApplicantStates()
      .then((response) => {
        setStates([
          { value: null, name: "Alap sablon (nem küldi ki)" },
          ...response.data.filter(
            // (item) => item.value[0] !== 10 && item.value[0] !== 90
            (item) =>
              item.value[0] === 30 ||
              item.value[0] === 50 ||
              item.value[0] === 60 ||
              item.value[0] === 80
          ),
        ]);
      })
      .catch((err) => console.error(err));
    return () => {};
  }, []);

  useEffect(() => {
    if (companyId) {
      getNotificationTemplateCompany(companyId)
        .then((response) => {
          setTemplates(response.data);
          handleStateClick(null);
        })
        .catch((err) => console.error(err));
    }
    return () => {};
  }, [companyId]);

  useEffect(() => {
    if (!subjectHc) {
      setSubjectErrorHc("Kötelező tárgyat megadni!");
    } else {
      setSubjectErrorHc("");
    }
    return () => {};
  }, [subjectHc]);

  useEffect(() => {
    if (!subjectMd) {
      setSubjectErrorMd("Kötelező tárgyat megadni!");
    } else {
      setSubjectErrorMd("");
    }
    return () => {};
  }, [subjectMd]);

  useEffect(() => {
    refQuillEditorHc.current.set(refTemplate.current.bodyHc ?? "");
    refQuillEditorMd.current.set(refTemplate.current.bodyMd ?? "");
    return () => {};
  }, [refTemplate.current]);

  useEffect(() => {
    let template = templates.find((item) => item.status === state);
    if (!template) {
      template = templates.find((item) => item.status === null);
    }
    if (!template) {
      const emptyTemplate = emptyTemplates.find(
        (item) =>
          (item.status === null && state === null) ||
          (item.status !== null && item.status.join(",") === state)
      );
      if (emptyTemplate) {
        template = { ...emptyTemplate, state: state };
      } else {
        template = { ...emptyTemplates[0], state: state };
      }
    }
    setSubjectHc(template.subjectHc);
    setBodyHc(template.bodyHc);
    setSubjectMd(template.subjectMd);
    setBodyMd(template.bodyMd);
    // setInactive(template.inactive);
    setOriginal(template.original ?? false);
    // const currentStates = state?.split(',') ?? [];
    // setInactiveDisabled(!(currentStates.includes("60")));
    refTemplate.current = template;
    setTimeout(() => {
      const currentQuillHc = refQuillEditorHc.current?.returnQuill() ?? false;
      if (currentQuillHc) {
        handleChangeHc(currentQuillHc);
      }
      const currentQuillMd = refQuillEditorMd.current?.returnQuill() ?? false;
      if (currentQuillMd) {
        handleChangeMd(currentQuillMd);
      }
    }, 1000);
    // console.log("handleStateClick", state, templates, template);

    const newEmptyTemplate = emptyTemplates.find(
        (item) =>
            (item.status === null && state === null) ||
            (item.status !== null && item.status.join(",") === state)
    );
    setOriginalTemplate(newEmptyTemplate ?? emptyTemplates[0]);

    return () => {};
  }, [state]);

  const handleStateClick = (stateId) => {
    setState(stateId ? stateId.join(",") : null);
  };

  const handleChangeHc = (quill) => {
    const checkbox = refOriginal.current;
    if (!checkbox?.checked) {
      const value = quill.root.innerHTML;
      const text = quill.root.innerText.replace(/[\r\n]/, "");
      setBodyHc(value);
      if (!text) {
        setBodyErrorHc("Kötelező tartalmat megadni!");
      } else {
        setBodyErrorHc("");
      }
    }
  };

  const handleChangeMd = (quill) => {
    const checkbox = refOriginal.current;
    if (!checkbox?.checked) {
      const value = quill.root.innerHTML;
      const text = quill.root.innerText.replace(/[\r\n]/, "");
      setBodyMd(value);
      if (!text) {
        setBodyErrorMd("Kötelező tartalmat megadni!");
      } else {
        setBodyErrorMd("");
      }
    }
  };

  const handleSave = () => {
    if (
      bodyErrorHc.length === 0 &&
      subjectErrorHc.length === 0 &&
      bodyErrorMd.length === 0 &&
      subjectErrorMd.length === 0
    ) {
      postNotificationTemplateCompany(companyId, {
        status: state,
        subjectHc,
        subjectMd,
        bodyHc,
        bodyMd,
        // inactive,
        original,
      })
        .then((response) => {
          setSnackbarMessage("Sikeresen módosítottad az értesítőt!");
          setSnackbarOpen(true);
          getNotificationTemplateCompany(companyId)
            .then((response) => {
              setTemplates(response.data);
              handleStateClick(state === null ? state : state.split(","));
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  useEffect(() => {
    const checkbox = refOriginal.current;
    if (checkbox?.checked) {
      refQuillEditorHc.current.set(originalTemplate.bodyHc ?? "");
      refQuillEditorMd.current.set(originalTemplate.bodyMd ?? "");
      refQuillEditorHc.current.enable(false);
      refQuillEditorMd.current.enable(false);
    } else {
      refQuillEditorHc.current.set(bodyHc ?? "");
      refQuillEditorMd.current.set(bodyMd ?? "");
      refQuillEditorHc.current.enable();
      refQuillEditorMd.current.enable();
    }
  }, [original]);

  // useEffect(() => {
  //   if (original) {
  //     let template = templates.find((item) => item.status === state);
  //     const emptyTemplate = emptyTemplates.find(
  //         (item) =>
  //             (item.status === null && state === null) ||
  //             (item.status !== null && item.status.join(",") === state)
  //     );
  //     if (emptyTemplate) {
  //       template = { ...emptyTemplate, state: state };
  //     } else {
  //       template = { ...emptyTemplates[0], state: state };
  //     }
  //     if (template) {
  //       setSubjectHc(template.subjectHc);
  //       setSubjectMd(template.subjectMd);
  //       setBodyHc(template.bodyHc);
  //       setBodyMd(template.bodyMd);
  //       refQuillEditorHc.current.set(template.bodyHc ?? "");
  //       refQuillEditorMd.current.set(template.bodyMd ?? "");
  //     }
  //   }
  // }, [original]);

  return (
    <>
      <Grid container sx={{ mt: 5 }} spacing={3}>
        <Grid md={12}>
          <h3>Értesítések karbantartása</h3>
        </Grid>
        <Grid md={4} lg={3}>
          <List>
            {states.map((item, index) => (
              <ListItem key={index} divider={true} disablePadding>
                <ListItemButton
                  selected={
                    (item.value === null && state === null) ||
                    (item.value !== null && item.value.join(",") === state)
                  }
                  onClick={() => {
                    handleStateClick(item.value);
                  }}
                >
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid md={8} lg={9}>
          <Box sx={{ mb: 3 }}>
            <Grid container>
              {/*<Box md={6}>
                <FormGroup>
                  <FormControlLabel disabled={inactiveDisabled} control={<Switch checked={inactive} onChange={(event) => {
                    setInactive(event.target.checked);
                  }} />} label="Értesítő kikapcsolása" />
                </FormGroup>
              </Box>*/}
              <Box md={6}>
                <FormGroup>
                  <FormControlLabel control={<Switch inputProps={{ref: refOriginal}} checked={original} onChange={(event) => {
                    setOriginal(event.target.checked);
                  }} />} label="Eredeti sablon szöveg betöltése" />
                </FormGroup>
              </Box>
            </Grid>
          </Box>
          <Box sx={{ mb: 3 }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor={"email-subjectHc"}>
                E-mail tárgya (HC)
              </InputLabel>
              <TextField
                sx={{ mt: 6 }}
                type="text"
                name="email-subject"
                autoComplete="off"
                onChange={(event) => {
                  setSubjectHc(event.target.value);
                }}
                disabled={original}
                value={original ? originalTemplate.subjectHc : subjectHc ?? ""}
                error={!!subjectErrorHc}
                helperText={subjectErrorHc}
              />
            </FormControl>
          </Box>
          <Box sx={{ mb: 3 }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor={"email-subjectMd"}>
                E-mail tárgya (MD)
              </InputLabel>
              <TextField
                sx={{ mt: 6 }}
                type="text"
                name="email-subject"
                autoComplete="off"
                onChange={(event) => {
                  setSubjectMd(event.target.value);
                }}
                disabled={original}
                value={original ? originalTemplate.subjectMd : subjectMd ?? ""}
                error={!!subjectErrorMd}
                helperText={subjectErrorMd}
              />
            </FormControl>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Grid
              container
              spacing={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid md={6} lg={8}>
                <FormControl error={!!bodyErrorHc} sx={{ width: "100%" }}>
                  <p>
                    <u>HumánCentrum levél:</u>
                    <br />
                    Kedves {"{keresztnév}"}!<br />
                    ...
                  </p>
                  <Box>
                    <QuillEditor
                      type={"hc"}
                      ref={refQuillEditorHc}
                      onChange={handleChangeHc}
                    ></QuillEditor>
                  </Box>
                  <p>
                    ...
                    <br />
                    Köszönettel, <br />
                    <strong>Humán Centrum Kft</strong>
                  </p>
                  {bodyErrorHc && (
                    <FormHelperText id="body-error-text">
                      {bodyErrorHc}
                    </FormHelperText>
                  )}
                </FormControl>
                <Divider sx={{ my: 3 }} />
                <FormControl error={!!bodyErrorMd} sx={{ width: "100%" }}>
                  <p>
                    <u>Mind-Diák levél:</u>
                    <br />
                    Kedves {"{keresztnév}"}!<br />
                    ...
                  </p>
                  <Box>
                    <QuillEditor
                      type={"md"}
                      ref={refQuillEditorMd}
                      onChange={handleChangeMd}
                    ></QuillEditor>
                  </Box>
                  <p>
                    ...
                    <br />
                    Köszönettel, <br />
                    <strong>Mind-Diák Szövetkezet</strong>
                  </p>
                  {bodyErrorMd && (
                    <FormHelperText id="body-error-text">
                      {bodyErrorMd}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid md={6} lg={4}>
                <FormControl sx={{ width: "100%" }}>
                  <FormHelperText error={false} id="body-help-text">
                    <u>Tartalomban használható behelyettesítések:</u>
                    <br />
                    {"{{ firstName }}"} - keresztnév
                    <br />
                    {"{{ lastName }}"} - vezetéknév
                    <br />
                    {"{{ email }}"} - e-mail cím
                    <br />
                    {"{{ position.name }}"} - pozíció megnevezése
                    <br />
                    {"{{ position.id }}"} - pozíció azonosító
                    <br />
                    {"{{ position.company.name }}"} - cég megnevezése
                    <br />
                    {"{{ position.company.address }}"} - cég címe
                    <br />
                    {"{{ position.company.email }}"} - cég e-mail címe
                    <br />
                    {"{{ position.company.phone }}"} - cég telefonszáma
                    <br />
                    {"{{ application.createTime }}"} - jelentkezés ideje
                    <br />
                    {"{{ application.status }}"} - jelentkezés állapota
                    <br />
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Button
              variant={"contained"}
              color={"success"}
              disabled={
                bodyErrorHc.length > 0 ||
                subjectErrorHc.length > 0 ||
                bodyErrorMd.length > 0 ||
                subjectErrorMd.length > 0
              }
              onClick={handleSave}
            >
              Mentés
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        open={snackbarOpen}
        message={snackbarMessage}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarColor}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

CompanyNotificationTemplate.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default CompanyNotificationTemplate;
