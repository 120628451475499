import React, { useEffect, useState } from "react";
import {
  getCountries,
  getCountry,
  setCountry,
  deleteCountry,
} from "../../services/dictionary";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";

Countries.propTypes = {};

function Countries(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);

  const permissions = usePermission("dictionary");
  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "nationality",
      fieldType: "text",
      headerName: "Megnevezés",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Ország",
      validation: {
        required: true,
      },
    },
    {
      field: "code",
      fieldType: "text",
      headerName: "ISO országkód ",
      validation: {},
    },
    {
      field: "priority",
      fieldType: "number",
      // editable: false,
      defaultValue: 1,
      headerName: "Prioritás",
      width: 100,
    },
    {
      field: "isActive",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Státusz",
      width: 100,
      valueGetter: (params) => {
        return params.row.isActive ? "aktív" : "inaktív";
      },
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Állampolgárság lista",
    url: "/dictionary/countries",
    item: "állampolgárság",
  };

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getCountry}
          setFunc={setCountry}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getCountries}
          isDictionary={true}
          deleteFunc={deleteCountry}
          columns={columns}
        ></Lists>
      )}
    </>
  );
}

export default Countries;
