import React, { useEffect, useState } from "react";
import Lists from "../../modules/hcmd/components/Lists";
import {usePermission, useRoles} from "../../services/auth";
import {
  getUserDeletionLogCount,
  getUserDeletionLogs, roleLevel,
} from "../../services/user";
import moment from "moment";
import {Alert} from "@mui/material";

DeletionLogs.propTypes = {};

function DeletionLogs(props) {
  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const permissions = usePermission("user");
  const roles = useRoles();
  const userRoleLevel = roleLevel(roles);

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      headerName: "Név",
    },
    {
      field: "email",
      headerName: "E-mail",
    },
    {
      field: "type",
      headerName: "Oldal",
      valueGetter: (params) => {
        if (params.row.type === 10) {
          return "MD (10)";
        } else if (params.row.type === 20) {
          return "HC (20)";
        } else {
          return `egyéb (${params.row.type})`;
        }
      },
    },
    {
      field: "createTime",
      headerName: "Törlés ideje",
      valueGetter: (params) => {
        return moment(params.row.createTime).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      field: "actions",
      hide: true,
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Törlési napló",
    url: "/users/deletion-logs",
    item: "törlési napló",
  };

  return (
    <>
      {userRoleLevel >= 75 && (
          <Lists
              header={header}
              permissions={permissions}
              getFunc={getUserDeletionLogs}
              getCountFunc={getUserDeletionLogCount}
              columns={columns}
              createButtons={<></>}
          ></Lists>
      )}
      {userRoleLevel < 75 && (<Alert severity="error">
        Nincs jogosultságod ehhez a részhez!
      </Alert>)}
    </>
  );
}

export default DeletionLogs;
