import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { checkToken, requestPassword, setNewPassword } from "../core/_requests";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

const newPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(3, "Minimum 3 karakter")
    .max(50, "Maximum 50 karakter")
    .required("Az új jelszó kitöltése kötelező"),
  confirmPassword: Yup.string()
    .min(3, "Minimum 3 karakter")
    .max(50, "Maximum 50 karakter")
    .required("Az új jelszó kitöltése kötelező"),
});

export function NewPassword() {
  const params = useParams();

  const [close, setClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(undefined);

  useEffect(() => {
    if (params.token) {
      checkToken(params.token)
        .then((response) => {
          if (!response.data) {
            setHasErrors(true);
          }
        })
        .catch((err) => {
          console.error(err);
          setHasErrors(true);
        });
    } else {
      setHasErrors(true);
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      if (values.newPassword === values.confirmPassword) {
        setTimeout(() => {
          setNewPassword(params.token, values.newPassword)
            .then((response) => {
              if (response.data) {
                setHasErrors(false);
                setLoading(false);
                setStatus("Sikeres jelszó módosítás!");
                setClose(true);
              } else {
                setHasErrors(true);
                setLoading(false);
                setSubmitting(false);
                setStatus("Hiba történt a jelszó beállítása során!");
              }
            })
            .catch(() => {
              setHasErrors(true);
              setLoading(false);
              setSubmitting(false);
              setStatus("Hiba történt a jelszó beállítása során!");
            });
        }, 1000);
      } else {
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus("Nem egyezik a két jelszó!");
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Állíts be új jelszót</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            Add meg az új jelszavad.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Hiba történt!
              <br />
              {formik.status ??
                "A jelszó módosítás már megtörtént vagy lejárt a hivatkozás!"}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">Sikeres jelszó beállítás!</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        {!close && (
          <>
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                Új jelszó
              </label>
              <input
                type="password"
                placeholder=""
                autoComplete="off"
                {...formik.getFieldProps("newPassword")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.newPassword && formik.errors.newPassword,
                  },
                  {
                    "is-valid":
                      formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                Jelszó megerősítése
              </label>
              <input
                type="password"
                placeholder=""
                autoComplete="off"
                {...formik.getFieldProps("confirmPassword")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword,
                  },
                  {
                    "is-valid":
                      formik.touched.confirmPassword &&
                      !formik.errors.confirmPassword,
                  }
                )}
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.confirmPassword}</span>
                    </div>
                  </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
              <button
                type="submit"
                id="kt_password_reset_submit"
                className="btn btn-lg btn-primary fw-bolder me-4"
              >
                <span className="indicator-label">Jelszó megváltoztatása</span>
                {loading && (
                  <span className="indicator-progress">
                    Kérlek várj...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_password_reset_form_cancel_button"
                  className="btn btn-lg btn-light-primary fw-bolder"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Mégsem
                </button>
              </Link>{" "}
            </div>
            {/* end::Form group */}
          </>
        )}
        {close && (
          <>
            {/* begin::Form group */}
            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_password_reset_form_cancel_button"
                  className="btn btn-lg btn-light-primary fw-bolder"
                >
                  Tovább a belépéshez
                </button>
              </Link>{" "}
            </div>
            {/* end::Form group */}
          </>
        )}
      </form>
    </>
  );
}
