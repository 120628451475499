import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import WorkIcon from "@mui/icons-material/Work";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SchoolIcon from "@mui/icons-material/School";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PsychologyIcon from "@mui/icons-material/Psychology";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ComputerIcon from "@mui/icons-material/Computer";
import DvrIcon from "@mui/icons-material/Dvr";
import HearingIcon from "@mui/icons-material/Hearing";
import PublicIcon from "@mui/icons-material/Public";
import PlaceIcon from "@mui/icons-material/Place";
import TranslateIcon from "@mui/icons-material/Translate";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MapIcon from "@mui/icons-material/Map";
import StyleIcon from "@mui/icons-material/Style";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import Tags from "../../../pages/dictionary/Tags";
import FieldsOfWorkCategories from "../../../pages/dictionary/FieldsOfWorkCategories";
import FieldsOfWorks from "../../../pages/dictionary/FieldsOfWorks";
import Qualifications from "../../../pages/dictionary/Qualifications";
import FieldsOfStudyCategories from "../../../pages/dictionary/FieldsOfStudyCategories";
import FieldsOfStudies from "../../../pages/dictionary/FieldsOfStudies";
import WorkTypeMds from "../../../pages/dictionary/WorkTypeMds";
import ComputerSkillCategories from "../../../pages/dictionary/ComputerSkillCategories";
import ComputerSkills from "../../../pages/dictionary/ComputerSkills";
import Referers from "../../../pages/dictionary/Referers";
import Partners from "../../../pages/dictionary/Partners";
import Countries from "../../../pages/dictionary/Countries";
import Cities from "../../../pages/dictionary/Cities";
import Languages from "../../../pages/dictionary/Languages";
import DrivingLicences from "../../../pages/dictionary/DrivingLicences";
import SpareTimes from "../../../pages/dictionary/SpareTimes";
import Counties from "../../../pages/dictionary/Counties";
import TagCategories from "../../../pages/dictionary/TagCategories";
import { roleLevel } from "../../../services/user";

DictionaryRoutes.propTypes = {};

export const pages = [
  {
    title: "Szakterületek",
    url: "fields-of-work-categories",
    children: [":id", ""],
    element: <FieldsOfWorkCategories />,
    elementIcon: <WorkIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Foglalkozások",
    url: "fields-of-works",
    children: [":id", ""],
    element: <FieldsOfWorks />,
    elementIcon: <EngineeringIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Iskolai végzettségi szintek",
    url: "qualifications",
    children: [":id", ""],
    element: <Qualifications />,
    elementIcon: <SchoolIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Tanulmányterületek",
    url: "fields-of-study-categories",
    children: [":id", ""],
    element: <FieldsOfStudyCategories />,
    elementIcon: <HistoryEduIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Szakvégzettségek",
    url: "fields-of-studies",
    children: [":id", ""],
    element: <FieldsOfStudies />,
    elementIcon: <PsychologyIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Diákmunka típusok",
    url: "work-type-mds",
    children: [":id", ""],
    element: <WorkTypeMds />,
    elementIcon: <PersonSearchIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Számítógépes ismeret kategóriák",
    url: "computer-skill-categories",
    children: [":id", ""],
    element: <ComputerSkillCategories />,
    elementIcon: <ComputerIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Számítógépes ismeretek",
    url: "computer-skills",
    children: [":id", ""],
    element: <ComputerSkills />,
    elementIcon: <DvrIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Hol hallott rólunk?",
    url: "referers",
    children: [":id", ""],
    element: <Referers />,
    elementIcon: <HearingIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Exportálási lehetőségek",
    url: "partners",
    children: [":id", ""],
    element: <Partners />,
    elementIcon: <ImportExportIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Állampolgárság",
    url: "countries",
    children: [":id", ""],
    element: <Countries />,
    elementIcon: <PublicIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Megyék",
    url: "counties",
    children: [":id", ""],
    element: <Counties />,
    elementIcon: <MapIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Települések",
    url: "cities",
    children: [":id", ""],
    element: <Cities />,
    elementIcon: <PlaceIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Nyelvek",
    url: "languages",
    children: [":id", ""],
    element: <Languages />,
    elementIcon: <TranslateIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Jogosítvány kategóriák",
    url: "driving-licences",
    children: [":id", ""],
    element: <DrivingLicences />,
    elementIcon: <DirectionsCarIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Címke kategóriák",
    url: "tag-categories",
    children: [":id", ""],
    element: <TagCategories />,
    elementIcon: <StyleIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Címkék",
    url: "tags",
    children: [":id", ""],
    element: <Tags />,
    elementIcon: <LocalOfferIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
  {
    title: "Ráérés",
    url: "spare-times",
    children: [":id", ""],
    element: <SpareTimes />,
    elementIcon: <ManageHistoryIcon />,
    visible: (roles) => {
      return true; // roleLevel(roles) >= 75;
    },
  },
];

function DictionaryRoutes(props) {
  return (
    <Routes>
      <Route element={<Outlet />}>
        {pages.map((page) => (
          <Route key={page.url} path={page.url} element={page.element}>
            {page.children.map((child) => (
              <Route
                key={`${page.url}/${child}`}
                path={child}
                element={page.element}
              />
            ))}
          </Route>
        ))}

        <Route
          index
          element={<Navigate to="/dictionary/fields-of-work-categories" />}
        />
      </Route>
    </Routes>
  );
}

export default DictionaryRoutes;
