import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import EmailIcon from "@mui/icons-material/Email";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import EngineeringIcon from "@mui/icons-material/Engineering";

import NewsletterHcs from "../../../pages/address-book/NewsletterHcs";
import NewsletterMds from "../../../pages/address-book/NewsletterMds";
import EmailSubscriberHcs from "../../../pages/address-book/EmailSubscriberHcs";
import EmailSubscriberMds from "../../../pages/address-book/EmailSubscriberMds";
import CompanyRequests from "../../../pages/address-book/CompanyRequests";
import Companies from "../../../pages/address-book/Companies";
import Offices from "../../../pages/address-book/Offices";
import { roleLevel } from "../../../services/user";
import Employees from "../../../pages/address-book/Employees";

AddressBookRoutes.propTypes = {};

export const pages = [
  {
    title: "Felhasználók",
    url: "employees",
    children: [":id", ""],
    element: <Employees />,
    elementIcon: <EngineeringIcon />,
    visible: (roles) => {
      return roleLevel(roles) >= 75;
    },
  },
  {
    title: "Irodák",
    url: "offices",
    children: [":id", ""],
    element: <Offices />,
    elementIcon: <MapsHomeWorkIcon />,
    visible: (roles) => {
      return roleLevel(roles) >= 75;
    },
  },
  {
    title: "Cégek",
    url: "companies",
    children: [":id", ""],
    element: <Companies />,
    elementIcon: <ApartmentIcon />,
    visible: () => {
      return true;
    },
  },
  {
    title: "Állásposta feliratkozottak (HC)",
    url: "newsletter-hcs",
    children: [":id", ""],
    element: <NewsletterHcs />,
    elementIcon: <WorkHistoryIcon />,
    visible: (roles) => {
      return roleLevel(roles) >= 75;
    },
  },
  {
    title: "Állásposta feliratkozottak (MD)",
    url: "newsletter-mds",
    children: [":id", ""],
    element: <NewsletterMds />,
    elementIcon: <WorkHistoryIcon />,
    visible: (roles) => {
      return roleLevel(roles) >= 75;
    },
  },
  {
    title: "Hírlevél feliratkozottak (HC)",
    url: "email-subscriber-hcs",
    children: [":id", ""],
    element: <EmailSubscriberHcs />,
    elementIcon: <EmailIcon />,
    visible: (roles) => {
      return roleLevel(roles) >= 75;
    },
  },
  {
    title: "Hírlevél feliratkozottak (MD)",
    url: "email-subscriber-mds",
    children: [":id", ""],
    element: <EmailSubscriberMds />,
    elementIcon: <EmailIcon />,
    visible: (roles) => {
      return roleLevel(roles) >= 75;
    },
  },
  {
    title: "Árajánlat kérések",
    url: "company-requests",
    children: [":id", ""],
    element: <CompanyRequests />,
    elementIcon: <RequestQuoteIcon />,
    visible: (roles) => {
      return roleLevel(roles) >= 75;
    },
  },
];

function AddressBookRoutes(props) {
  return (
    <Routes>
      <Route element={<Outlet />}>
        {pages.map((page) => (
          <Route key={page.url} path={page.url} element={page.element}>
            {page.children.map((child) => (
              <Route
                key={`${page.url}/${child}`}
                path={child}
                element={page.element}
              />
            ))}
          </Route>
        ))}

        <Route
          index
          element={<Navigate to="/address-books/newsletter-hcs" />}
        />
      </Route>
    </Routes>
  );
}

export default AddressBookRoutes;
