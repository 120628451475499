import { Chip, LinearProgress, Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

export const tagTypes = [
  {
    id: 10,
    value: "MD",
    name: "Minddiák",
  },
  {
    id: 20,
    value: "HC",
    name: "Humáncentrum",
  },
];
export const sites = [
  {
    id: 10,
    value: "HC",
    name: "Humáncentrum",
  },
  {
    id: 20,
    value: "MD",
    name: "Minddiák",
  },
  {
    id: 30,
    value: "HC-MD",
    name: "Mindkettő",
  },
];

export const Crud = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
};
export const CrudPosition = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
  publish: "publish",
  status: "status",
  applicants: "applicants",
  applicantswrite: "applicantswrite",
  permission: "permission",
  ownerchange: "ownerchange",
  export: "export",
  notifications: "notifications",
};
export const CrudUser = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
};

export const languageLevels = [
  // { id: 0, name: "mindegy" },
  { id: 1, name: "alapfok" },
  { id: 2, name: "középfok" },
  { id: 3, name: "felsőfok" },
];

export const computerSkillLevels = [
  // { id: 0, name: "mindegy" },
  { id: 1, name: "kezdő" },
  { id: 2, name: "haladó" },
  { id: 3, name: "tapasztalt" },
  { id: 4, name: "szakértő" },
];

export const employmentStatuses = [
  { id: 30, name: "pályakezdő" },
  { id: 10, name: "foglalkoztatott" },
  { id: 40, name: "vállalkozó" },
  { id: 20, name: "munkanélküli" },
  { id: 50, name: "megváltozott munkaképességű" },
  { id: 60, name: "nyugdíjas" },
];

export const studentEmploymentStatus = [
  { id: 10, name: "Nappali aktív" },
  { id: 20, name: "Nappali passzív" },
  { id: 30, name: "Frissdiplomás" },
  { id: 40, name: "Megváltozott munkaképességű" },
  { id: 50, name: "Egyéb (esti, levelező,...)" },
];

export const workExperienceHc = [
  { id: 10, name: "pályakezdő" },
  { id: 20, name: "diákmunka" },
  { id: 30, name: "kevesebb, mint 1 év" },
  { id: 40, name: "1-2 év" },
  { id: 50, name: "2-3 év" },
  { id: 60, name: "3-5 év" },
  { id: 70, name: "5-10 év" },
  { id: 80, name: "10 évnél több" },
];

export const workExperienceMd = [
  { id: 10, name: "nincs" },
  { id: 20, name: "diákmunka" },
  { id: 30, name: "kevesebb, mint 1 év" },
  { id: 40, name: "1-2 év" },
  { id: 50, name: "2-3 év" },
  { id: 60, name: "3 évnél több" },
];

export const salaryRequestList = [
  { id: 10, name: "0 - 120 000" },
  { id: 20, name: "120 000 - 180 000" },
  { id: 30, name: "180 000 - 250 000" },
  { id: 40, name: "250 000 - 400 000" },
  { id: 50, name: "400 000 -" },
];

export const positionStatuses = [
  { id: 10, name: "szerkesztett" },
  { id: 20, name: "rögzített" },
  { id: 30, name: "jóváhagyott" },
  { id: 40, name: "megvalósult" },
  { id: 50, name: "lezárult" },
  { id: 60, name: "törölt" },
];

export const userStatuses = [
  { id: 10, name: "függőben" },
  { id: 20, name: "validált" },
  { id: 25, name: "egyszerűsített" },
  { id: 30, name: "aktív" },
  { id: 40, name: "törölt" },
];

export const emailRegex = new RegExp(
  "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])",
  "i"
);

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: 160 }}>
        <Typography variant="body2" color="text.secondary">
          {props.value}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", ml: 1 }}>
        <LinearProgress />
      </Box>
    </Box>
  );
}
