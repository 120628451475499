/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
// import { Languages } from "../../../../_hcmd/partials/layout/header-menus/Languages";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useRoles } from "../../../../app/services/auth";
import { getPictureSrc } from "../../../../app/services/user";
import { getMyPositionCount } from "../../../../app/services/position";

const HeaderUserMenu = () => {
  const { currentUser, logout } = useAuth();

  // console.log("currentUser", currentUser);

  const [userRoles, setUserRoles] = useState([]);
  const [positionCounter, setPositionCounter] = useState(0);
  const [avatar, setAvatar] = useState(
    toAbsoluteUrl("/media/avatars/blank.png")
  );

  const roles = useRoles();

  useEffect(() => {
    setUserRoles(roles);
    return () => {};
  }, [roles]);

  useEffect(() => {
    if (currentUser?.user?.picture) {
      setAvatar(getPictureSrc(currentUser.user.picture, currentUser.user.id));
    }
    getMyPositionCount()
      .then((response) => {
        setPositionCounter(response.data.count);
      })
      .catch((err) => console.error(err));
  }, [currentUser]);

  useEffect(() => {
    // console.log("userRoles", userRoles);
  }, [userRoles]);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={avatar} />
          </div>

          <div className="d-flex flex-column overflow-hidden">
            <span className="fw-bolder text-ellipsis fs-5 py-1">
              {currentUser?.firstName} {currentUser?.lastName}
            </span>
            <div
              className="fw-bolder text-ellipsis d-flex align-items-center fs-5"
              title={userRoles.join(", ")}
            >
              {userRoles.map((item, index) => (
                <span
                  key={index}
                  className="badge badge-light-success fw-bolder fs-8 px-2 py-1 me-2"
                >
                  {item}
                </span>
              ))}
            </div>
            <a
              href="#"
              className="fw-bold text-muted text-hover-primary py-1 fs-7 text-ellipsis"
              title={currentUser?.email}
            >
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to={"/profile"} className="menu-link px-5">
          Profil
        </Link>
      </div>

      <div className="menu-item px-5">
        <a href="/positions/me" className="menu-link px-5">
          <span className="menu-text">Pozícióim</span>
          <span className="menu-badge">
            <span className="badge badge-light-danger badge-circle fw-bolder fs-7">
              {positionCounter}
            </span>
          </span>
        </a>
      </div>

      {/*<div
        className="menu-item px-5"
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="left-start"
        data-kt-menu-flip="bottom"
      >
        <a href="#" className="menu-link px-5">
          <span className="menu-title">My Subscription</span>
          <span className="menu-arrow"></span>
        </a>

        <div className="menu-sub menu-sub-dropdown w-175px py-4">
          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Referrals
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Billing
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Payments
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex flex-stack px-5">
              Statements
              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="View your statements"
              ></i>
            </a>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-3">
            <div className="menu-content px-3">
              <label className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input w-30px h-20px"
                  type="checkbox"
                  value="1"
                  defaultChecked={true}
                  name="notifications"
                />
                <span className="form-check-label text-muted fs-7">
                  Notifications
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>*/}

      {/*<div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          My Statements
        </a>
      </div>*/}

      <div className="separator my-2"></div>

      {/* <Languages />*/}

      {/*<div className="menu-item px-5 my-1">
        <Link to="/crafted/account/settings" className="menu-link px-5">
          Account Settings
        </Link>
      </div>*/}

      <div className="menu-item px-5">
        <a onClick={() => logout(true)} className="menu-link px-5">
          Kilépés
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
