import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import {
  formError,
  formFieldError,
  formGetValue,
  formLabelAttr,
  formOptions,
  formSetValue,
  formValueAttr,
} from "./formUtils";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying, ...args } =
    props;

  const valueAttr = formValueAttr(column);
  const { labelAttr, labelAttrFn } = formLabelAttr(column);

  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [inited, setInited] = useState(false);

  useEffect(() => {
    const initOptions = formOptions(column, args);
    if (initOptions.length) {
      setOptions(initOptions);
    }
  }, [column, args]);

  useEffect(() => {
    let initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      if (!Array.isArray(initValue)) {
        const found = options.find((item) => item[valueAttr] === initValue);
        if (found) {
          initValue = [found];
        } else {
          initValue = [];
        }
      }
      setValue(initValue);
    }
    setInited(true);
  }, [initialValue]);

  // useEffect(() => {
  //   let checkboxValue = formGetValue(formik, column, options);
  //   if (!Array.isArray(checkboxValue)) {
  //     const found = options.find((item) => item[valueAttr] === checkboxValue);
  //     if (found) {
  //       checkboxValue = [found];
  //     } else {
  //       checkboxValue = [];
  //     }
  //   }
  //   if (
  //     options.length &&
  //     JSON.stringify(checkboxValue) !== JSON.stringify(value)
  //   ) {
  //     setValue(checkboxValue);
  //   }
  // }, [formik, column, options, formik.values[column.field]]);

  const handleChange = (event) => {
    const option = options.find(
      (item) => item.id === parseInt(event.target.value)
    );
    if (option) {
      const isChecked = value.find((item) => item.id === option.id);
      let newValues = value;
      if (event.target.checked && !isChecked) {
        newValues = [...value, option];
      } else if (!event.target.checked && isChecked) {
        newValues = value.filter((item) => item.id !== option.id);
      }
      if (value !== newValues) {
        setValue(newValues);
        formSetValue(formik, column, newValues);
        dirtying();
        formError(formik, column, options);
      }
    }
  };

  return (
    <div className="row">
      <FormControl
        sx={{ width: "100%" }}
        error={!!formFieldError(formik, column, isFormDirty)}
        component="fieldset"
        variant="standard"
      >
        <FormLabel
          className={`col-lg-4 col-form-label fw-bold fs-6 ${
            column.validation?.required && "required"
          }`}
          component="legend"
        >
          {column.headerName}
        </FormLabel>
        <div className="col-lg-8 fv-row">
          <FormGroup>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={
                      !!(
                        Array.isArray(value) &&
                        value.length &&
                        value.find(
                          (item) => item[valueAttr] === option[valueAttr]
                        )
                      )
                    }
                    onChange={(event) => handleChange(event)}
                    value={option[valueAttr]}
                  />
                }
                label={option[labelAttr]}
              />
            ))}
          </FormGroup>
          {!!formFieldError(formik, column, isFormDirty) && (
            <FormHelperText>
              {formFieldError(formik, column, isFormDirty)}
            </FormHelperText>
          )}
        </div>
      </FormControl>
    </div>
  );
};

const CheckboxInput = forwardRef(InputForwardRef);

CheckboxInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default CheckboxInput;
