import axios from "axios";
import { Crud } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { setMe, setPermission, setRoles } from "../store/auth.slice";

const apiUrl = process.env.REACT_APP_API_URL;

export function useMe() {
  const storedUser = useSelector((state) => state.auth.me);
  // console.log('useMe', storedUser);
  const dispatch = useDispatch();
  if (storedUser === false) {
    dispatch(setMe(null));
    me()
      .then((response) => {
        dispatch(setMe(response.data));
      })
      .catch((err) => console.error(err));
  }
  return storedUser ?? false;
}

export function useRoles() {
  const storedRoles = useSelector((state) => state.auth.roles);
  // console.log('useRoles', storedRoles);
  const dispatch = useDispatch();
  if (storedRoles === false) {
    dispatch(setRoles(null));
    roles()
      .then((response) => {
        dispatch(setRoles(response.data));
      })
      .catch((err) => console.error(err));
  }
  return storedRoles ?? [];
}

export function usePermission(type, id = 0) {
  let storedPermission = false;
  storedPermission = useSelector((state) => state.auth.permissions[type][id]);
  // console.log('usePermission', storedPermission);
  try {
    const dispatch = useDispatch();
    if (!storedPermission) {
      dispatch(
          setPermission({
            type,
            id,
            permissions: [],
            requested: new Date().getTime(),
          })
      );
      // dispatch(() => {
      //     try {
      //         setPermission({
      //           type,
      //           id,
      //           permissions: [],
      //           requested: new Date().getTime(),
      //         });
      //     } catch (e) {
      //       console.log('dispatch empty permission error', type, id);
      //     }
      //   }
      // );
      let fn;
      let params = {};
      switch (type) {
        case "dictionary":
          fn = dictionaryPermission;
          break;
        case "company":
          fn = companyPermission;
          break;
        case "employee":
          fn = employeePermission;
          break;
        case "user":
          fn = userPermission;
          params = {
            id,
          };
          break;
        case "position":
          fn = positionPermission;
          params = {
            id,
          };
          break;
        case "office":
          fn = officePermission;
          params = {
            officeId: id,
          };
          break;
      }
      if (fn) {
        fn(params)
            .then((response) => {
              dispatch(
                  setPermission({
                    type,
                    id,
                    permissions: response.data,
                    requested: storedPermission?.requested ?? null,
                    response: new Date().getTime(),
                  })
              );
              // dispatch(() => {
              //     try {
              //       setPermission({
              //         type,
              //         id,
              //         permissions: response.data,
              //         requested: storedPermission?.requested ?? null,
              //         response: new Date().getTime(),
              //       });
              //     } catch (e) {
              //       console.log('dispatch response error', type, id, response.data)
              //     }
              //   }
              // );
            })
            .catch((err) => console.error(err));
      }
    }
  } catch (e) {
    console.log('usePermission error', e);
    // usePermission error
  }
  return storedPermission ?? [];
}

export const me = () => {
  return axios.get(`${apiUrl}/admin/me`, {});
};

export const setProfile = (id, data) => {
  return axios.patch(`${apiUrl}/admin/me/${id}`, data);
};

const roles = (filter = {}) => {
  return axios.get(`${apiUrl}/admin/roles`, { params: filter });
};

export const getPossibleRoles = () => {
  return axios.get(`${apiUrl}/admin/roles/possible`, {});
};

export const userRoles = (id, filter = {}) => {
  return axios.get(`${apiUrl}/admin/roles/${id}`, { params: filter });
};

export const adminOffices = () => {
  return axios.get(`${apiUrl}/admin/offices`, {});
};

export const userOffices = (id) => {
  return axios.get(`${apiUrl}/admin/offices/${id}`, {});
};

export const dictionaryPermission = (params = {}) => {
  const crud = params?.crud ?? "";
  const crudStr = crud && Crud[crud] ? `/${crud}` : ``;
  return axios.get(`${apiUrl}/admin/permission/dictionary${crudStr}`, {});
};

export const companyPermission = (params = {}) => {
  const crud = params?.crud ?? "";
  const crudStr = crud && Crud[crud] ? `/${crud}` : ``;
  return axios.get(`${apiUrl}/admin/permission/company${crudStr}`, {});
};

export const officePermission = (params = {}) => {
  const officeId = params?.officeId ?? 0;
  const crud = params?.crud ?? "";
  const officeStr = officeId ? `/${officeId}` : ``;
  const crudStr = crud && Crud[crud] ? `/${crud}` : ``;
  return axios.get(
    `${apiUrl}/admin/permission/office${officeStr}${crudStr}`,
    {}
  );
};

export const employeePermission = (params = {}) => {
  const crud = params?.crud ?? "";
  const crudStr = crud && Crud[crud] ? `/${crud}` : ``;
  return axios.get(`${apiUrl}/admin/permission/employee${crudStr}`, {});
};

export const positionPermission = (params = {}) => {
  const id = params?.id ?? 0;
  const crud = params?.crud ?? "";
  const crudStr = crud && Crud[crud] ? `/${crud}` : ``;
  if (id) {
    return axios.get(`${apiUrl}/admin/permission/position/${id}${crudStr}`, {});
  } else {
    return axios.get(`${apiUrl}/admin/permission/position/create`, {});
  }
};

export const positionCreatePermission = (params = {}) => {
  return axios.get(`${apiUrl}/admin/permission/position/create`, {});
};

export const userPermission = (params = {}) => {
  const id = params?.id ?? 0;
  const crud = params?.crud ?? "";
  const crudStr = crud && Crud[crud] ? `/${crud}` : ``;
  if (id) {
    return axios.get(`${apiUrl}/admin/permission/user/${id}${crudStr}`, {});
  } else {
    return axios.get(`${apiUrl}/admin/permission/user/create`, {});
  }
};

export const userCreatePermission = (params = {}) => {
  return axios.get(`${apiUrl}/admin/permission/user/create`, {});
};

export const browseDir = (dir) => {
  return axios.post(`${apiUrl}/admin/browse`, {
    dir,
  });
};

export const quillUpload = (id, data) => {
  if (id) {
    return axios.post(`${apiUrl}/admin/position/upload/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/admin/quill/upload`, data);
  }
};
