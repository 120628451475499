import React, { useEffect, useRef, useState } from "react";
import {
  getEmployees,
  getEmployee,
  setEmployee,
  deleteEmployee,
  getOffices,
} from "../../services/address-book";
import Lists from "../../modules/hcmd/components/Lists";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import {
  getPossibleRoles,
  usePermission,
  userOffices,
  userRoles,
} from "../../services/auth";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Button,
  Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControlLabel,
  FormGroup, IconButton, Snackbar,
} from "@mui/material";
import { roleNames } from "../../services/user";
import SaveIcon from "@mui/icons-material/Save";
import { LinearProgressWithLabel } from "../../services/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import parse from "html-react-parser";

Employees.propTypes = {};

function Employees(props) {
  let { id } = props;

  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get("edit");

  const permissions = usePermission("employee");

  const params = useParams();
  let _isEditable = false;
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
  }
  if (id && isEdit) {
    _isEditable = true;
  }

  const [editId, setEditId] = useState(null);
  const [isForm, setIsForm] = useState(false);
  const formRefs = useRef([]);

  const navigate = useNavigate();

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const [user, setUser] = useState(null);
  const [isEditable, setIsEditable] = useState(_isEditable);
  const [formColumns, setFormColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [possibleRoles, setPossibleRoles] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [offices, setOffices] = useState([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({ id: 0, text: "" });
  const [dialogError, setDialogError] = useState('');

  useEffect(() => {
    getPossibleRoles().then((response) => {
      setPossibleRoles(response.data.map((item) => item.name));
    });
  }, []);

  useEffect(() => {
    if (selectedRoles.includes('hca_c')) {
      setSelectedRoles(['hca_c']);
    }
  }, [selectedRoles]);

  useEffect(() => {
    setEditId(id);
  }, [id]);

  useEffect(() => {
    if (editId !== null && editId !== undefined) {
      setIsForm(true);
      if (isEdit) {
        setIsEditable(true);
      }
    } else {
      setIsForm(false);
      setIsEditable(false);
    }
    return () => {
      setIsForm(false);
      setIsEditable(false);
      setUser(null);
    };
  }, [editId, isEdit]);

  useEffect(() => {
    if (isForm) {
      getOffices().then((response) => {
        setOffices(response.data);
      });
      if (editId) {
        getEmployee(editId).then((response) => {
          setUser(response.data);
        });
      } else if (isForm) {
        setUser({
          id: 0,
        });
        setIsEditable(true);
      }
    }
  }, [isForm, editId, isEdit]);

  useEffect(() => {
    if (editId > 0 && possibleRoles.length > 0) {
      userRoles(editId)
        .then((response) => {
          setSelectedRoles(
            response.data.filter((item) => possibleRoles.includes(item))
          );
        })
        .catch((err) => console.error(err));
    }
  }, [possibleRoles, editId]);

  useEffect(() => {
    if (editId > 0 && offices.length > 0) {
      userOffices(editId)
        .then((response) => {
          setSelectedOffices(response.data);
        })
        .catch((err) => console.error(err));
    }
  }, [offices, editId]);

  const handleDelete = (row) => {
    setDialogData({
      id: row.id,
      text: `"<b>${row.name ? row.name : (row.firstName && row.lastName) ? row.lastName + ' ' + row.firstName : ''}</b>" megnevezésű ${header.item}`,
    });
    setDialogError('');
    setDialogOpen(true);
  };

  useEffect(() => {
    // ide jon az oszlopok deklaralasa
    setColumns([
      {
        field: "id",
        fieldType: "number",
        hide: true,
      },
      {
        field: "email",
        fieldType: "text",
        headerName: "E-mail",
        validation: {
          required: true,
          minLength: 3,
        },
      },
      {
        field: "name",
        fieldType: "hidden",
        headerName: "Név",
        valueGetter: (params) => {
          return `${params.row.lastName} ${params.row.firstName}`;
        },
      },
      {
        field: "isActive",
        fieldType: "boolean",
        defaultValue: true,
        headerName: "Státusz",
        width: 100,
        valueGetter: (params) => {
          return params.row.isActive && params.row.status === 30
            ? "aktív"
            : "inaktív";
        },
      },
      {
        field: "adminPermission",
        fieldType: "boolean",
        defaultValue: true,
        headerName: "Belépés",
        width: 120,
        valueGetter: (params) => {
          return params.row.adminPermission
              ? "engedélyezve"
              : "tiltva";
        },
      },
      // {
      //   field: "role",
      //   fieldType: "select",
      //   headerName: "Jogosultság",
      //   width: 100,
      //   valueGetter: (params) => {
      //     console.log('role', params.row);
      //     return "";
      //   },
      // },
      {
        field: "actions",
        headerName: "Műveletek",
        width: 200,
        align: "right",
        headerAlign: "right",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerClassName: "text-dark",
        cellClassName: "text-dark",
        renderCell: (params) => {
          return (
              <>
                <Link to={`${header.url}/${params.row.id}`}>
                  <IconButton
                      variant={"contained"}
                      aria-label="szerkesztés"
                      size="large"
                      className={"me-3"}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Link>
                {permissions.includes("update") && <Link to={`${header.url}/${params.row.id}?edit=1`}>
                  <IconButton
                      variant={"contained"}
                      aria-label="szerkesztés"
                      size="large"
                      className={"me-3"}
                  >
                    <EditIcon />
                  </IconButton>
                </Link>}
                {permissions.includes("delete") && (!(params.row.isActive && params.row.status === 30)) && (
                    <IconButton
                        variant={"contained"}
                        aria-label="törlés"
                        size="large"
                        onClick={() => {
                          handleDelete(params.row);
                        }}
                    >
                      <DeleteIcon />
                    </IconButton>
                )}
              </>
          );
        },
      }
    ]);
    setFormColumns([
      [
        {
          field: "id",
          fieldType: "number",
          hide: true,
        },
        {
          field: "email",
          fieldType: "text",
          headerName: "E-mail",
          validation: {
            required: true,
            minLength: 3,
          },
        },
        {
          field: "changePassword",
          fieldType: "password",
          headerName: "Jelszó",
          validation: {
            required: true,
            minLength: 6,
          },
        },
        {
          field: "isActive",
          fieldType: "boolean",
          defaultValue: true,
          headerName: "Státusz",
          width: 100,
          valueGetter: (params, values) => {
            return values.row.isActive && values.row.status === 30 ? "aktív" : "inaktív";
          },
        },
        {
          field: "adminPermission",
          fieldType: "boolean",
          defaultValue: false,
          headerName: "Belépés",
          width: 100,
          valueGetter: (params, values) => {
            return values.row.adminPermission ? "engedélyezve" : "tiltás";
          },
        },
      ],
      [
        {
          field: "title",
          fieldType: "text",
          fieldModel: "userEmployee",
          hide: true,
          headerName: "Titulus",
        },
        {
          field: "lastName",
          fieldType: "text",
          hide: true,
          headerName: "Vezetéknév",
        },
        {
          field: "firstName",
          fieldType: "text",
          hide: true,
          headerName: "Keresztnév",
        },
        {
          field: "phone",
          fieldType: "text",
          fieldModel: "userEmployee",
          headerName: "Telefonszám",
          hide: true,
        },
        {
          field: "mobile",
          fieldType: "text",
          fieldModel: "userEmployee",
          headerName: "Mobilszám",
          hide: true,
        },
      ],
      [
        {
          field: "roles",
          fieldType: "hidden",
        },
        {
          field: "offices",
          fieldType: "hidden",
        },
      ],
      [
        {
          field: "picture",
          fieldType: "upload",
          headerName: "Profilkép",
        },
      ],
    ]);
  }, [possibleRoles, user]);

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Felhasználók",
    url: "/address-books/employees",
    item: "felhasználó",
    getRowClassName: (params) => {
      if (params.row.status === 40 || !params.row.isActive) {
        return "row-inactive";
      }
    },
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleOpenNew = () => {
    setUser({ id: 0 });
    setIsForm(false);
    setIsEditable(false);
    navigate("/address-books/employees/0");
  };

  const handleSave = () => {
    setSaving(true);

    let values = {};
    let errors = {};

    const merge = (cur, acc) => {
      Object.keys(acc).map((attr) => {
        if (!cur[attr]) {
          cur[attr] = acc[attr];
        } else if (Array.isArray(acc[attr]) && acc[attr].length > 0) {
          acc[attr].map((item, index) => {
            if (!cur[attr][index]) {
              cur[attr][index] = acc[attr][index];
            } else {
              cur[attr][index] = { ...cur[attr][index], ...item };
            }
          });
        } else if (Object.keys(acc[attr]).length > 0) {
          Object.keys(acc[attr]).map((key) => {
            if (!cur[attr][key]) {
              cur[attr][key] = acc[attr][key];
            } else {
              cur[attr][key] = { ...cur[attr][key], ...acc[attr][key] };
            }
          });
        }
      });
      return cur;
    };

    formRefs.current.map((form) => {
      if (form && form.submit) {
        const formValue = form.submit();
        const formErrors = form.validation();
        errors = { ...errors, ...formErrors };
        // values = { ...values, ...formValue };
        values = merge(values, formValue);
      } else {
        console.log("missing");
      }
    });
    values.roles = selectedRoles;
    values.offices = selectedOffices;

    setError('');
    setEmployee(editId, values)
      .then((response) => {
        console.log('setEmployee', response);
        if (response.data.success && response.data.employee) {
          if (!editId) {
            navigate(`/address-books/employees/${response.data.employee.id}`);
          } else {
            setSaving(false);
            setIsForm(false);
            setIsEditable(!isEditable);
            setUser(null);
            navigate(`/address-books/employees`);
          }
        } else {
          setError(response.data.msg);
          setSaving(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response?.data?.error?.message ?? 'Váratlan hiba történt!');
        setSaving(false);
      });
  };

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteAgree = (id) => {
    deleteEmployee(id).then(response => {
      if (response.data.success) {
        setSaving(false);
        setIsForm(false);
        setIsEditable(!isEditable);
        setUser(null);
        setDialogOpen(false);
        setDialogError('');
        navigate(`/address-books/employees`);
      } else {
        setDialogError(response.data.error);
      }
    }).catch(err => console.error(err));
  };

  return (
    <>
      {columns.length && (
        <>
          {isForm && user && (
            <Grid container direction={"column"}>
              <Grid>
                <h3>Bejelentkezési adatok</h3>
                <hr />
                <Forms
                  isDense={true}
                  editable={isEditable}
                  header={{ skip: true }}
                  permissions={["create", "read", "update", "delete"]}
                  columns={formColumns[0]}
                  getObj={user}
                  ref={(el) => (formRefs.current[0] = el)}
                  submitFunc={(values) => handleSubmit(values)}
                ></Forms>
              </Grid>
              <Grid>
                <h3>Elérhetőség</h3>
                <hr />
                <Forms
                  isDense={true}
                  editable={isEditable}
                  header={{ skip: true }}
                  permissions={["create", "read", "update", "delete"]}
                  columns={formColumns[1]}
                  getObj={user}
                  ref={(el) => (formRefs.current[1] = el)}
                  submitFunc={(values) => handleSubmit(values)}
                ></Forms>
              </Grid>
              <Grid>
                <h3>Beállítások</h3>
                <hr />
                {!!possibleRoles.length && (
                  <>
                    <h4>Jogosultságcsoport</h4>
                    <Grid container>
                      {possibleRoles.map((item, index) => {
                        const role = roleNames.find(
                          (roleName) => roleName.id === item
                        );
                        if (role) {
                          return (
                            <Grid key={index}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedRoles.includes(item)}
                                      disabled={!isEditable}
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          setSelectedRoles([
                                            ...selectedRoles,
                                            item,
                                          ]);
                                        } else {
                                          setSelectedRoles(
                                            selectedRoles.filter(
                                              (selected) => selected !== item
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  }
                                  label={role.name}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  </>
                )}
                <hr />
                {!!offices.length && (
                  <>
                    <h4>Irodák</h4>
                    <Grid container>
                      {offices.map((item, index) => (
                        <Grid key={index}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!isEditable}
                                  checked={
                                    !!selectedOffices.filter(
                                      (selected) => selected.id === item.id
                                    ).length
                                  }
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      setSelectedOffices([
                                        ...selectedOffices,
                                        item,
                                      ]);
                                    } else {
                                      setSelectedOffices(
                                        selectedOffices.filter(
                                          (selected) => selected.id !== item.id
                                        )
                                      );
                                    }
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          </FormGroup>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                <Forms
                  isDense={true}
                  editable={isEditable}
                  header={{ skip: true }}
                  permissions={["create", "read", "update", "delete"]}
                  columns={formColumns[2]}
                  getObj={user}
                  ref={(el) => (formRefs.current[2] = el)}
                  submitFunc={(values) => handleSubmit(values)}
                ></Forms>
              </Grid>
              <Grid>
                <h3>Egyéb</h3>
                <hr />
                <Forms
                  isDense={true}
                  editable={isEditable}
                  header={{ skip: true }}
                  permissions={["create", "read", "update", "delete"]}
                  columns={formColumns[3]}
                  getObj={user}
                  ref={(el) => (formRefs.current[3] = el)}
                  submitFunc={(values) => handleSubmit(values)}
                ></Forms>
              </Grid>
              <Grid>
                {!isEditable && (
                  <Button
                    variant={"contained"}
                    size={"small"}
                    startIcon={<EditIcon />}
                    color={"warning"}
                    onClick={() => {
                      handleEdit();
                    }}
                    sx={{ mr: 3 }}
                  >
                    Szerkesztés
                  </Button>
                )}
                {saving && (
                  <LinearProgressWithLabel value={"Mentés folyamatban..."} />
                )}
                {isEditable && !saving && (
                  <>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      startIcon={<SaveIcon />}
                      color={"success"}
                      onClick={() => {
                        handleSave();
                      }}
                      sx={{ mr: 3 }}
                    >
                      Mentés
                    </Button>
                    <Button
                      variant={"contained"}
                      size={"small"}
                      startIcon={<EditIcon />}
                      color={"warning"}
                      onClick={() => {
                        handleEdit();
                      }}
                      sx={{ mr: 3 }}
                    >
                      Mégsem
                    </Button>
                  </>
                )}
                {error && <Alert severity="error" sx={{mt: 3}}>{error}</Alert>}
              </Grid>
            </Grid>
          )}
          {!isForm && (
            <Lists
              header={header}
              permissions={permissions}
              isDictionary={true}
              getFunc={() => getEmployees({},
                  [],
                  25,
                  0,
                  [], true
              )}
              // getCountFunc={getEmployeeCount}
              columns={columns}
              offices={offices}
              createButtons={
                <Button
                  variant={"contained"}
                  color={"error"}
                  onClick={() => handleOpenNew()}
                >
                  Felhasználó létrehozása
                </Button>
              }
            ></Lists>
          )}
        </>
      )}
      <Dialog
          open={dialogOpen}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Adat törlése</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Biztosan törölni szeretné az alábbi adatot?
            <br />
            {parse(dialogData.text)}<br />
            {dialogError && <Alert severity="error" sx={{mt: 3}}>{dialogError}</Alert>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Mégsem</Button>
          <Button
              variant={"contained"}
              color={"secondary"}
              onClick={() => {
                handleDeleteAgree(dialogData.id);
              }}
          >
            Igen, biztosan
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Employees;
