import React, {useEffect, useState} from "react";
import {MenuItem} from "../../../../_metronic/layout/components/header/MenuItem";
import {MenuInnerWithSub} from "./MenuInnerWithSub";
// import { MegaMenu } from "../../../../_metronic/layout/components/header/MegaMenu";
import {useIntl} from "react-intl";
import {pages as dictionaryPages} from "../../../../app/modules/hcmd/Routes/DictionaryRoutes";
import {pages as addressBookPages} from "../../../../app/modules/hcmd/Routes/AddressBookRoutes";
import {pages as userPages} from "../../../../app/modules/hcmd/Routes/UserRoutes";
import {useRoles} from "../../../../app/services/auth";
import {roleLevel} from "../../../../app/services/user";

export function MenuInner() {
    const intl = useIntl();
    const dictionaryMenu = dictionaryPages;

    const roles = useRoles();
    const userRoleLevel = roleLevel(roles);

    return (
        <>
            {Array.isArray(roles) && roles?.includes("hca") &&
                (<>
                    <MenuItem
                        title={intl.formatMessage({id: "MENU.DASHBOARD"})}
                        to="/dashboard"
                    />
                    <MenuItem title="Pozíciók" to="/positions"/>
                    <MenuInnerWithSub
                        title="Munkavállalók"
                        to="/users"
                        menuPlacement="bottom-start"
                        menuTrigger="click"
                    >
                        {userPages.map((item: any) => {
                            return (
                                (!item.visible || item.visible(roles)) && (
                                <MenuItem
                                    key={item.url}
                                    to={`/users/${item.url}`}
                                    title={item.title}
                                    elementIcon={item.elementIcon ?? ""}
                                    hasBullet={false}
                                />
                            ));
                        })}
                    </MenuInnerWithSub>
                    {userRoleLevel >= 75 && (
                        <MenuInnerWithSub
                            isMega={true}
                            title="Szótár"
                            to="/dictionary"
                            menuPlacement="bottom-start"
                            menuTrigger="click"
                        >
                            <div className="row" data-kt-menu-dismiss="true">
                                <div className="col-lg-6">
                                    {dictionaryMenu
                                        .slice(0, Math.ceil(dictionaryMenu.length / 2))
                                        .map((item: any) => {
                                            return (
                                                (!item.visible || item.visible(roles)) && (
                                                    <MenuItem
                                                        key={item.url}
                                                        to={`/dictionary/${item.url}`}
                                                        title={item.title}
                                                        elementIcon={item.elementIcon ?? ""}
                                                        hasBullet={false}
                                                    />
                                                )
                                            );
                                        })}
                                </div>
                                <div className="col-lg-6">
                                    {dictionaryMenu
                                        .slice(Math.ceil(dictionaryMenu.length / 2))
                                        .map((item: any) => {
                                            return (
                                                (!item.visible || item.visible(roles)) && (
                                                    <MenuItem
                                                        key={item.url}
                                                        to={`/dictionary/${item.url}`}
                                                        title={item.title}
                                                        elementIcon={item.elementIcon ?? ""}
                                                        hasBullet={false}
                                                    />
                                                )
                                            );
                                        })}
                                </div>
                            </div>
                        </MenuInnerWithSub>
                    )}
                    <MenuInnerWithSub
                        title="Címlisták"
                        to="/address-books"
                        menuPlacement="bottom-start"
                        menuTrigger="click"
                    >
                        {addressBookPages.map((item: any) => {
                            return (
                                (!item.visible || item.visible(roles)) && (
                                    <MenuItem
                                        key={item.url}
                                        to={`/address-books/${item.url}`}
                                        title={item.title}
                                        elementIcon={item.elementIcon ?? ""}
                                        hasBullet={false}
                                    />
                                )
                            );
                        })}
                    </MenuInnerWithSub>
                </>)}
            {Array.isArray(roles) && !roles?.includes("hca") && roles?.includes("hca_c") &&
                (<>
                        <MenuItem
                            title={intl.formatMessage({id: "MENU.DASHBOARD"})}
                            to="/dashboard"
                        />
                        <MenuItem title="Pozíciók" to="/positions"/>
                    </>
                )}
        </>
    );
}
