import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import parse from "html-react-parser";
import {
  formGetValue,
  formLabelAttr,
  formOptions,
  formValueAttr,
} from "./formUtils";
import { Link } from "react-router-dom";
import { getPictureSrc } from "../../../services/user";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, values, ...args } = props;

  const valueAttr = formValueAttr(column);
  const { labelAttr, labelAttrFn } = formLabelAttr(column);
  let valueLabel = null;

  const options = formOptions(column, args);

  let value = formGetValue(formik, column, options, values);
  if (options && value) {
    const found = options.find((item) => item.id === value);
    if (found) {
      value = labelAttrFn ? labelAttrFn(found) : found[labelAttr];
    }
  }
  if (column.valueGetter) {
    value = column.valueGetter({ row: value }, { row: values });
    if (!value) {
      value = column.valueGetter({ row: null }, { row: values });
    }
  }
  if (column.fieldType === "upload" && value && values?.id) {
    value = getPictureSrc(value, values.id);
  } else if (column.fieldType === "document" && value && values?.id) {
    valueLabel = value;
    value = getPictureSrc(value, values.id);
  }
  if (value && typeof value !== "string") {
    value = value.toString();
  } else if (!value) {
    value = "";
  }

  return (
    <div className="row">
      {column.headerName && (
        <label
          className={`col-lg-4 col-form-label fw-bold fs-6 ${
            column.validation?.required && "required"
          }`}
        >
          {column.headerName}
        </label>
      )}
      <div
        className={`fv-row col-form-label ${
          column.headerName ? "col-lg-8" : "col-lg-12"
        }`}
      >
        <FormControl>
          {["upload", "document", "browser", "cover"].includes(
            column.fieldType
          ) && (
            <>
              {value.match(/\w+\.(jpe?g|gif|png)$/i) && (
                <a href={value.match(/^https?:\/\//)
                    ? value
                    : `${process.env.REACT_APP_API_URL}${value}`} target={'_blank'}>
                  <img
                    src={
                      value.match(/^https?:\/\//)
                        ? value
                        : `${process.env.REACT_APP_API_URL}${value}`
                    }
                    alt={"uploaded image"}
                    className={"user-avatar"}
                  />
                </a>
              )}
              {!value.match(/\w+\.(jpe?g|gif|png)$/i) && (
                <Typography component="p">
                  <a
                    href={
                      value.match(/^https?:\/\//)
                        ? value
                        : `${process.env.REACT_APP_API_URL}${value}`
                    }
                    target={"_blank"}
                  >
                    {valueLabel ?? value}
                  </a>
                </Typography>
              )}
            </>
          )}
          {!["upload", "document"].includes(column.fieldType) && (
            <Typography
              component={column.fieldType === "texteditor" ? "div" : "p"}
            >
              {column.fieldType === "texteditor" ? parse(value ?? "") : value}
            </Typography>
          )}
          {column.helperText && (
            <FormHelperText sx={{ mx: 0 }}>{column.helperText}</FormHelperText>
          )}
        </FormControl>
      </div>
    </div>
  );
};

const NonInput = forwardRef(InputForwardRef);

NonInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
};
export default NonInput;
