import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { applyPosition } from "../../../services/position";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

const DialogApplyForwardRef = (props, ref) => {
  const { position, userIds } = props;

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    open() {
      setApplyDialogOpen(true);
    },
  }));

  const [applyDialogOpen, setApplyDialogOpen] = useState(false);
  const [navigateButtons, setNavigateButtons] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleApplyDialogAgree = () => {
    applyPosition(position.id, userIds)
      .then((response) => {
        if (response.data.success) {
          const msg = `A kijelölt ${userIds.length} felhasználó hozzárendelésre került a(z) ${position.name} (${position.id}) pozícióhoz.`;
          setSnackbarColor("success");
          setSnackbarMessage("Sikeresen hozzárendelted a munkavállalókat!");
          setSnackbarOpen(true);
          setNavigateButtons(true);
        } else {
          setSnackbarColor("error");
          setSnackbarMessage(response.data.message.replace(/\n/g, "<br>"));
          setSnackbarOpen(true);
        }
      })
      .catch((err) => console.error(err));
  };
  const handleApplyDialogClose = () => {
    setApplyDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={applyDialogOpen}
        onClose={handleApplyDialogClose}
        aria-labelledby="apply-dialog-title"
        aria-describedby="apply-dialog-description"
      >
        <DialogTitle id="apply-dialog-title">
          {"Munkavállaló hozzárendelés"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={"div"} id="apply-dialog-description">
            Biztosan hozzá akarod rendelni a {userIds.length} munkavállalót a(z){" "}
            {position.name} ({position.id}) pozícióhoz?
          </DialogContentText>
        </DialogContent>
        {!navigateButtons && (
          <DialogActions>
            <Button onClick={handleApplyDialogClose} color={"error"}>
              Nem
            </Button>
            <Button
              onClick={handleApplyDialogAgree}
              variant={"contained"}
              color={"success"}
              autoFocus
            >
              Igen
            </Button>
          </DialogActions>
        )}
        {navigateButtons && (
          <DialogActions>
            <Button
              onClick={handleApplyDialogClose}
              color={"success"}
              variant={"contained"}
              autoFocus
            >
              Maradok a keresésnél
            </Button>
            <Button
              onClick={() => {
                navigate(`/positions/${position.id}`);
              }}
              variant={"contained"}
              color={"error"}
            >
              Átlépek a pozícióhoz
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        open={snackbarOpen}
        message={parse(snackbarMessage)}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarColor}
          sx={{ width: "100%" }}
        >
          {parse(snackbarMessage)}
        </Alert>
      </Snackbar>
    </>
  );
};

const DialogApply = forwardRef(DialogApplyForwardRef);

DialogApply.propTypes = {
  position: PropTypes.object.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DialogApply;
