import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const dashboardPositions = (site, uid, positionFilter, from, to, abortSignal) => {
    const url = `${apiUrl}/admin/dashboard/positions/${site}/${uid}/${positionFilter ? positionFilter : 'null'}/${from}/${to}`;
  return axios.get(
    url, {
        signal: abortSignal.signal
      }
  );
};

export const dashboardEmployees = (site, uid, positionFilter, from, to, abortSignal) => {
    const url = `${apiUrl}/admin/dashboard/employees/${site}/${uid}/${positionFilter ? positionFilter : 'null'}/${from}/${to}`;
  return axios.get(
    url, {
        signal: abortSignal.signal
      }
  );
};

export const dashboardList = (site, uid, positionFilter, from, to, abortSignal) => {
    const url = `${apiUrl}/admin/dashboard/list/${site}/${uid}/${positionFilter ? positionFilter : 'null'}/${from}/${to}`;
  return axios.get(
    url, {
        signal: abortSignal.signal
      }
  );
};
