import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { formFieldError } from "../../../modules/hcmd/components/formUtils";
import {
  getCounties,
  getCountriesAutocomplete,
} from "../../../services/dictionary";
import Grid from "@mui/material/Unstable_Grid2";

const FilterCountyForwardRef = (props, ref) => {
  const { params, site } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      return params.getFilter(values.map((item) => item.id));
    },
  }));

  const [values, setValues] = useState([]);

  const options = params.options ?? [];

  let optionTimer;

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={`col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <FormControl sx={{ width: "100%" }}>
          <Autocomplete
            name={params.field}
            multiple={true}
            options={options}
            isOptionEqualToValue={(option, value) => {
              return option.id === value;
            }}
            getOptionLabel={(option) => option.name ?? ""}
            onChange={(event, value) => {
              setValues(value ?? []);
            }}
            value={values}
            renderInput={(params) => (
              <TextField {...params} placeholder={"Több is választható"} />
            )}
          ></Autocomplete>
        </FormControl>
      </Grid>
    </Grid>
  );
};
const FilterCounty = forwardRef(FilterCountyForwardRef);

FilterCounty.propTypes = {
  params: PropTypes.object,
};

export default FilterCounty;
