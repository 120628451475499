import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FilterWorkExperienceMdForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      const filter = {};
      if (workExperience) {
        filter.userMd = { workExperience: workExperience };
      }
      if (workTypeMd || name || companyName) {
        filter.userWorkTypeMds = {};
        if (workTypeMd) {
          filter.userWorkTypeMds.workTypeMdId = workTypeMd;
        }
        if (name) {
          filter.userWorkTypeMds.name = { like: `%${name}%` };
        }
        if (companyName) {
          filter.userWorkTypeMds.companyName = { like: `%${companyName}%` };
        }
      }
      return filter;
    },
  }));

  const [workExperience, setWorkExperience] = useState("");
  const [workTypeMd, setWorkTypeMd] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const options = params.options ?? [];
  const workTypeMds = params.workTypeMds ?? [];

  return (
    <Grid container spacing={2}>
      <Grid>
        <label className={` col-form-label fw-bold fs-6`}>{params.name}</label>
      </Grid>
      <Grid className={`fv-row`}>
        <Grid
          container
          alignItems={"center"}
          sx={{ minWidth: 400, width: "100%" }}
        >
          <Grid>
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Eddigi munkatapasztalat</InputLabel>
              <Select
                name={`${params.field}-work-experience`}
                onChange={(event) => {
                  setWorkExperience(event.target.value ?? "");
                }}
                label={"Eddigi munkatapasztalat"}
                value={workExperience}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Diákmunka</InputLabel>
              <Select
                name={`${params.field}-worktypemd`}
                onChange={(event) => {
                  setWorkTypeMd(event.target.value ?? "");
                }}
                label={"Diákmunka"}
                value={workTypeMd}
              >
                {workTypeMds.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignItems={"center"}>
          <Grid>
            <FormControl sx={{ width: 150 }}>
              <TextField
                type="text"
                name={`${params.field}-name`}
                autoComplete="off"
                onChange={(event) => {
                  setName(event.target.value ?? "");
                }}
                label={"Beosztás"}
                value={name}
              />
            </FormControl>
          </Grid>
          <Grid>
            <FormControl sx={{ width: 150 }}>
              <TextField
                type="text"
                name={`${params.field}-companyName`}
                autoComplete="off"
                onChange={(event) => {
                  setCompanyName(event.target.value ?? "");
                }}
                label={"Munkahely"}
                value={companyName}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const FilterWorkExperienceMd = forwardRef(FilterWorkExperienceMdForwardRef);

FilterWorkExperienceMd.propTypes = {
  params: PropTypes.object,
};

export default FilterWorkExperienceMd;
