import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Forms from "../../../modules/hcmd/components/Forms";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Paper } from "@mui/material";
import {
  formFunctions,
  formSetValue,
} from "../../../modules/hcmd/components/formUtils";
import moment from "moment";

const DocumentForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit } = props;
  const formRefs = useRef([]);

  const [site, setSite] = useState("");
  const [formItems, setFormItems] = useState({});
  const [columns, setColumns] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (user.type === 10) {
      setSite("md");
    } else if (user.type === 20) {
      setSite("hc");
    }
    if (user.userDocuments?.length > 0) {
      const newFormItems = {};
      user.userDocuments.map((item, index) => {
        newFormItems[index] = item;
      });
      setFormItems(newFormItems);
    }
    return () => {
      setSite("");
    };
  }, [user]);

  useEffect(() => {}, [site]);

  useEffect(() => {
    if (Object.keys(formItems).length) {
      setRefresh(true);
    }
  }, [formItems]);

  useEffect(() => {
    if (refresh) {
      const newColumns = {};
      Object.keys(formItems).map((index) => {
        const formItem = formItems[index];
        const currentColumns = [
          {
            field: "description",
            fieldType: "text",
            fieldModel: "userDocuments",
            fieldModelArrayIndex: index,
            fieldModelId: formItem.documentId,
            subObject: "document",
            headerName: "Leírás",
            onChange: (newValue) => {
              console.log("description change", newValue);
              const newFormItems = JSON.parse(JSON.stringify(formItems));
              newFormItems[index]["document"].description = newValue;
              setFormItems(newFormItems);
            },
          },
          {
            field: "originalFilename",
            fieldType: "document",
            fieldModel: "userDocuments",
            fieldModelArrayIndex: index,
            fieldModelId: formItem.documentId,
            subObject: "document",
            headerName: "Feltöltött file",
            onChange: (event, column) => {
              if (event.target.value) {
                console.log("file change", event.target.value);
                const newFormItems = JSON.parse(JSON.stringify(formItems));
                newFormItems[column.fieldModelArrayIndex].documentId =
                  event.target.value;
                setFormItems(newFormItems);
              }
            },
          },
        ];
        newColumns[index] = currentColumns;
      });
      setColumns(newColumns);
      setRefresh(false);
    }
  }, [refresh]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  const handleAddRow = () => {
    const sorted = Object.keys(formItems).sort(
      (a, b) => parseInt(a) < parseInt(b)
    );
    const newRow =
      sorted.length > 0 ? parseInt(sorted[sorted.length - 1]) + 1 : 0;
    const newItem = {
      document: {
        description: "",
      },
    };
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    newFormItems[newRow] = newItem;
    setFormItems(newFormItems);
  };

  const handleDeleteRow = (delRow) => {
    const newFormItems = JSON.parse(JSON.stringify(formItems));
    delete newFormItems[delRow];
    setFormItems(newFormItems);
  };

  return (
    <>
      {Object.keys(formItems).map((index) => {
        const currentColumns = columns?.[index] ?? [];
        return (
          <React.Fragment key={index}>
            <Paper className={"bg-paper"}>
              {currentColumns.length > 0 && (
                <Grid container spacing={3} alignItems={"flex-start"}>
                  <Grid xs={10} sm={11} container>
                    <Grid md={4}>
                      <Forms
                        isDense={true}
                        editable={isEditable}
                        header={{ skip: true }}
                        permissions={["create", "read", "update", "delete"]}
                        columns={[currentColumns[1]]}
                        getObj={user}
                        ref={(el) => (formRefs.current[index * 10 + 1] = el)}
                        submitFunc={(values) => handleSubmit(values)}
                      ></Forms>
                    </Grid>
                    <Grid md={8}>
                      <Forms
                        isDense={true}
                        editable={isEditable}
                        header={{ skip: true }}
                        permissions={["create", "read", "update", "delete"]}
                        columns={[currentColumns[0]]}
                        getObj={user}
                        ref={(el) => (formRefs.current[index * 10 + 2] = el)}
                        submitFunc={(values) => handleSubmit(values)}
                      ></Forms>
                      {!isEditable &&
                        formItems?.[index]?.document?.createTime && (
                          <p>
                            Feltöltés dátuma:{" "}
                            <b>
                              {moment(
                                formItems[index].document.createTime
                              ).format("YYYY-MM-DD HH:mm:ss")}
                            </b>
                          </p>
                        )}
                    </Grid>
                  </Grid>
                  <Grid xs={2} sm={1}>
                    {isEditable && (
                      <IconButton
                        onClick={() => handleDeleteRow(index)}
                        color="error"
                        className={"mb-6"}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )}
            </Paper>
          </React.Fragment>
        );
      })}
      {isEditable && (
        <Button
          variant={"contained"}
          size={"small"}
          startIcon={<PlaylistAddIcon />}
          color={"success"}
          onClick={() => {
            handleAddRow(formItems.length);
          }}
          sx={{ mr: 3 }}
        >
          Dokumentum hozzáadása
        </Button>
      )}
    </>
  );
};
const Document = forwardRef(DocumentForwardRef);
Document.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default Document;
