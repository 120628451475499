import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import { Button, MenuItem, TextField } from "@mui/material";
import { getMyPositions } from "../../../services/position";
import DialogApply from "../../position/components/DialogApply";

const ApplyForwardRef = (props, ref) => {
  const { selectAll, passedFiltered, site } = props;

  useImperativeHandle(ref, () => ({
    passFiltered(passed) {
      setFiltered(passed);
    },
  }));

  const [positions, setPositions] = useState([]);
  const [position, setPosition] = useState("");
  const [filtered, setFiltered] = useState(passedFiltered);

  const refDialog = useRef(null);

  useEffect(() => {
    const type = site === "md" ? 20 : site === "hc" ? 10 : 0;
    getMyPositions({ type, status: 30 })
      .then((response) => {
        setPositions(response.data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <Grid container spacing={3} alignItems={"center"} sx={{ ml: 4, mb: 2 }}>
        <Grid>
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<ChecklistIcon />}
            color={"primary"}
            onClick={() => {
              selectAll();
            }}
            sx={{ p: 1 }}
          >
            Összes kijelölése
          </Button>
        </Grid>
        {filtered?.length > 0 && (
          <Grid>
            <TextField
              select
              label="Pozíció"
              helperText="Pozícióhoz kiválasztása"
              value={position}
              onChange={(event) => {
                setPosition(event.target.value);
              }}
            >
              {positions.map((position) => (
                <MenuItem key={position.id} value={position.id}>
                  {`${position.name} (${position.id})`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {position && (
          <Grid>
            <Button
              variant={"contained"}
              size={"small"}
              startIcon={<PlaylistAddCheckCircleIcon />}
              color={"success"}
              onClick={() => {
                if (refDialog.current) {
                  refDialog.current.open();
                }
              }}
              sx={{ p: 1 }}
            >
              Kijelöltek ({filtered.length})<br />
              pozícióhoz rendelése
            </Button>
          </Grid>
        )}
      </Grid>
      {position && filtered.length && (
        <DialogApply
          position={positions.find((item) => item.id === position)}
          userIds={filtered}
          ref={refDialog}
        ></DialogApply>
      )}
    </>
  );
}

const Apply = forwardRef(ApplyForwardRef);

Apply.propTypes = {
  selectAll: PropTypes.func,
  // filtered: PropTypes.arrayOf(PropTypes.number),
  site: PropTypes.string.isRequired,
};

export default Apply;
