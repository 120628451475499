import { useRoles } from "../../services/auth";
import Leader from "./Leader";
import Employee from "./Employee";

const DashboardWrapper = () => {
  const roles = useRoles();
  let isLeader = false;
  if (roles && Array.isArray(roles) && roles.includes("hca_m")) {
    isLeader = true;
  }

  return (
    <>
      {isLeader && <Leader></Leader>}
      {!isLeader && <Employee></Employee>}
    </>
  );
};

export { DashboardWrapper };
