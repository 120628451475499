import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AsideDefault } from "../../_metronic/layout/components/aside/AsideDefault";
import { Sidebar } from "../../_metronic/layout/components/sidebar/Sidebar";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { ScrollTop } from "../../_metronic/layout/components/ScrollTop";
import { Content } from "../../_metronic/layout/components/Content";
import { PageDataProvider, useLayout } from "../../_metronic/layout/core";
import { useLocation } from "react-router-dom";
import {
  DrawerMessenger,
  RightToolbar,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
} from "../../_metronic/partials";
import { MenuComponent } from "../../_metronic/assets/ts/components";
import clsx from "clsx";
import { WithChildren } from "../../_metronic/helpers";
import {Footer} from "./components/Footer";

const MasterLayout: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout();

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <div className="page d-flex flex-column flex-column-fluid">
        <HeaderWrapper />

        <div
          id="kt_content_container_wrapper"
          className={clsx(
            "d-flex flex-column-fluid align-items-stretch",
            classes.contentContainer.join(" ")
          )}
        >
          <AsideDefault />

          <div
            className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10"
            id="kt_wrapper"
          >
            <div className="flex-column-fluid" id="kt_content">
              <div className="post" id="kt_post">
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>

          {/*<Sidebar />*/}
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/*<RightToolbar />*/}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/*<InviteUsers />
      <UpgradePlan />*/}
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
