import React from "react";

const formGetValues = (formik, columns, values, args) => {
  const getValues = {};
  const tmpValues = values ?? formik.values ?? {};
  columns.map((column) => {
    if (column.fieldModel && tmpValues[column.fieldModel]) {
      getValues[column.fieldModel] = tmpValues[column.fieldModel];
    } else if (column.fieldModel) {
      if (getValues[column.fieldModel] === undefined) {
        getValues[column.fieldModel] = {};
      }
      let currentColumn;

      let currentValue = null;
      if (
        column.field !== undefined &&
        column.fieldModel !== undefined &&
        column.fieldModelArrayIndex !== undefined &&
        column.subObject !== undefined &&
        tmpValues?.[column.fieldModel]?.[column.fieldModelArrayIndex]?.[
          column.subObject
        ]?.[column.field] !== undefined
      ) {
        currentValue =
          tmpValues[column.fieldModel][column.fieldModelArrayIndex][
            column.subObject
          ][column.field];
      } else if (
        column.field !== undefined &&
        column.fieldModel !== undefined &&
        column.fieldModelArrayIndex !== undefined &&
        column.subObject === undefined &&
        tmpValues?.[column.fieldModel]?.[column.fieldModelArrayIndex]?.[
          column.field
        ] !== undefined
      ) {
        currentValue =
          tmpValues[column.fieldModel][column.fieldModelArrayIndex][
            column.field
          ];
      } else if (
        column.field !== undefined &&
        column.fieldModel !== undefined &&
        column.fieldModelArrayIndex === undefined &&
        column.subObject === undefined &&
        tmpValues?.[column.fieldModel]?.[column.field] !== undefined
      ) {
        currentValue = tmpValues[column.fieldModel][column.field];
      } else if (
        column.field !== undefined &&
        column.fieldModel === undefined &&
        column.fieldModelArrayIndex === undefined &&
        column.subObject === undefined &&
        tmpValues?.[column.field] !== undefined
      ) {
        currentValue = tmpValues[column.field];
      } else if (column.defaultValue !== undefined) {
        currentValue = column.defaultValue;
      }

      if (
        column.fieldModelArrayIndex !== undefined &&
        getValues[column.fieldModel]?.[column.fieldModelArrayIndex] ===
          undefined
      ) {
        getValues[column.fieldModel][column.fieldModelArrayIndex] = {};
        getValues[column.fieldModel][column.fieldModelArrayIndex][
          column.field
        ] = currentValue;
        currentColumn =
          getValues[column.fieldModel][column.fieldModelArrayIndex];
      } else {
        getValues[column.fieldModel][column.field] = currentValue;
        currentColumn = getValues[column.fieldModel];
      }
      if (currentValue === null) {
        if (["select", "checkbox"].includes(column.fieldType)) {
          currentColumn[column.field] = 0;
        } else if (["number", "autocomplete"].includes(column.fieldType)) {
          currentColumn[column.field] = 0;
        } else if (["boolean"].includes(column.fieldType)) {
          currentColumn[column.field] = false;
        } else {
          currentColumn[column.field] = "";
        }
      }
    } else if (tmpValues[column.field] !== undefined) {
      getValues[column.field] = tmpValues[column.field];
    } else {
      if (["select", "checkbox"].includes(column.fieldType)) {
        getValues[column.field] = 0;
      } else if (["number", "autocomplete"].includes(column.fieldType)) {
        getValues[column.field] = 0;
      } else if (["boolean"].includes(column.fieldType)) {
        getValues[column.field] = false;
      } else {
        getValues[column.field] = "";
      }
    }
  });
  return getValues;
};

const getValue = (values, column, options = []) => {
  let value = null;
  if (column.fieldModel) {
    if (
      column.subObject &&
      column.fieldModelArrayIndex !== undefined &&
      values[column.fieldModel]?.[column.fieldModelArrayIndex]?.[
        column.subObject
      ]?.[column.field]
    ) {
      value =
        values[column.fieldModel][column.fieldModelArrayIndex][
          column.subObject
        ][column.field];
    } else if (
      column.fieldModelArrayIndex !== undefined &&
      values[column.fieldModel]?.[column.fieldModelArrayIndex]?.[column.field]
    ) {
      value =
        values[column.fieldModel][column.fieldModelArrayIndex][column.field];
    } else if (values[column.fieldModel]?.[column.field]) {
      value = values[column.fieldModel][column.field];
    }
  } else if (values[column.field] !== undefined) {
    value = values[column.field];
  }
  if (column.fieldType === "boolean") {
    return value === "1" || value === 1 || value === true || value === "on";
  }
  if (column.fieldType === "number") {
    return !isNaN(parseInt(value)) ? parseInt(value) : 0;
  }
  const valueAttr = formValueAttr(column);
  if (options.length > 0 && value) {
    const found = options.find((item) => item[valueAttr] === value);
    if (found) {
      value = found;
    }
  }
  if (!value && column.defaultValue !== undefined) {
    if (options.length > 0) {
      const found = options.find(
        (item) => item[valueAttr] === column.defaultValue
      );
      if (found) {
        value = found;
      }
    } else {
      value = column.defaultValue;
    }
  }
  if (column.fieldType === "select" && value && !isNaN(parseInt(value))) {
    return parseInt(value);
  }
  return valueAttr && value?.[valueAttr] ? value[valueAttr] : value;
};

const formGetValue = (formik, column, options = [], values = null) => {
  const valueObj = values ?? formik.values;
  return getValue(valueObj, column, options);
};

const formSetValue = (formik, column, value) => {
  let values = JSON.parse(JSON.stringify(formik.values));
  if (column.fieldModel) {
    if (values[column.fieldModel] === undefined) {
      values[column.fieldModel] = {};
    }
    if (column.fieldModelArrayIndex !== undefined) {
      if (
        values[column.fieldModel][column.fieldModelArrayIndex] === undefined
      ) {
        values[column.fieldModel][column.fieldModelArrayIndex] = {};
      }
      if (column.subObject) {
        if (
          values[column.fieldModel][column.fieldModelArrayIndex][
            column.subObject
          ] === undefined
        ) {
          values[column.fieldModel][column.fieldModelArrayIndex][
            column.subObject
          ] = {};
        }
        values[column.fieldModel][column.fieldModelArrayIndex][
          column.subObject
        ][column.field] = value;
      } else {
        values[column.fieldModel][column.fieldModelArrayIndex][column.field] =
          value;
      }
    } else {
      values[column.fieldModel][column.field] = value;
    }
  } else {
    values[column.field] = value;
  }
  if (values) {
    formik.setValues(values, true);
  }
  return values;
};

const formOptions = (column, args) => {
  let options = [];
  if (!column.field) {
    return options;
  }
  if (column.options && Array.isArray(column.options)) {
    options = column.options;
  } else if (
    column.options &&
    typeof column.options === "string" &&
    args[column.options]
  ) {
    options = args[column.options];
  } else if (args[`${column.field}s`]) {
    options = args[`${column.field}s`];
  } else if (args[`${column.field.substring(0, column.field.length - 1)}ies`]) {
    options = args[`${column.field.substring(0, column.field.length - 1)}ies`];
  } else if (args[`${column.field.replace(/id$/i, "")}s`]) {
    options = args[`${column.field.replace(/id$/i, "")}s`];
  } else if (args[`${column.field.substring(0, column.field.length - 1)}ies`]) {
    options = args[`${column.field.substring(0, column.field.length - 1)}ies`];
  } else if (
    args[
      `${column.field
        .replace(/id$/i, "")
        .substring(0, column.field.length - 3)}ies`
    ]
  ) {
    options =
      args[
        `${column.field
          .replace(/id$/i, "")
          .substring(0, column.field.length - 3)}ies`
      ];
  } else if (args[`${column.field}`]) {
    options = args[`${column.field}`];
  }
  return options;
};

const formValueAttr = (column) => {
  return column.valueAttr ?? "id";
};

const formLabelAttr = (column) => {
  let labelAttrFn, labelAttr;
  if (typeof column.labelAttr === "function") {
    labelAttrFn = column.labelAttr;
  } else {
    labelAttr = column.labelAttr ?? "name";
  }
  return { labelAttr, labelAttrFn };
};

const formError = (formik, column, options) => {
  const value = formGetValue(formik, column, options);
  if (!value && column.validation?.required) {
    formik.setFieldError(column.field, "Kötelező");
  }
};

const formFieldClass = (formik, column, isFormDirty) => {
  return `${
    column.validation && isFormDirty && !formik.errors[column.field]
      ? "is-valid"
      : ""
  }${
    column.validation && isFormDirty && formik.errors[column.field]
      ? "is-invalid"
      : ""
  }`;
};

const formFieldError = (formik, column, isFormDirty) => {
  const error =
    column.validation && isFormDirty && formik.errors[column.field]
      ? formik.errors[column.field].msg
      : "";
  return error;
};

const formErrorMsg = (formik, column) => {
  return (
    column.validation &&
    formik.touched[column.field] &&
    formik.errors[column.field] && (
      <div className="fv-plugins-message-container">
        <div className="fv-help-block">{formik.errors[column.field].msg}</div>
      </div>
    )
  );
};

const formFunctions = (formRefs) => {
  return {
    submit() {
      formRefs.current.map((formRef) => {
        formRef.submit();
      });
    },
    forms() {
      return formRefs.current;
    },
  };
};

export {
  getValue,
  formGetValues,
  formGetValue,
  formSetValue,
  formOptions,
  formValueAttr,
  formLabelAttr,
  formError,
  formFieldClass,
  formFieldError,
  formErrorMsg,
  formFunctions,
};
