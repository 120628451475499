import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/hu";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import {
  formError,
  formFieldError,
  formGetValue,
  formSetValue,
} from "./formUtils";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying } = props;

  const [value, setValue] = useState(moment("now"));
  const [inited, setInited] = useState(false);

  useEffect(() => {
    const initValue = initialValue;
    if (
      !inited &&
      initValue !== undefined &&
      moment(initValue).format("YYYY-MM-DD HH:mm:ss") !==
        moment(value).format("YYYY-MM-DD HH:mm:ss")
    ) {
      setValue(moment(initValue));
    }
    setInited(true);
  }, [initialValue]);

  // useEffect(() => {
  //   setInited(true);
  //   const formikValue = formGetValue(formik, column);
  //   if (
  //     formikValue &&
  //     moment(formikValue).format("YYYY-MM-DD HH:mm:ss") !==
  //       moment(value).format("YYYY-MM-DD HH:mm:ss")
  //   ) {
  //     setValue(moment(formikValue));
  //   }
  // }, [formik, column]);

  useEffect(() => {
    if (inited) {
      formSetValue(formik, column, value?.format("YYYY-MM-DD") ?? "");
      dirtying();
      formError(formik, column);
    }
  }, [value]);

  return (
    <FormControl
      sx={{ width: "100%" }}
      error={!!formFieldError(formik, column, isFormDirty)}
      component="fieldset"
      variant="standard"
    >
      <div className="row">
        <FormLabel
          className={`col-lg-4 col-form-label fw-bold fs-6 ${
            column.validation?.required && "required"
          }`}
          sx={{ padding: "12.625px 11.25px", color: "inherit" }}
          component="label"
        >
          {column.headerName}
        </FormLabel>

        <div className="col-lg-8 fv-row">
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="hu">
            <DatePicker
              className={`form-control form-control-lg form-control-solid`}
              id={column.field}
              autoComplete="off"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
          </LocalizationProvider>
          {!!formFieldError(formik, column, isFormDirty) && (
            <FormHelperText>
              {formFieldError(formik, column, isFormDirty)}
            </FormHelperText>
          )}
          <input
            type="hidden"
            name={column.field}
            value={value?.format("YYYY-MM-DD") ?? ""}
          />
        </div>
      </div>
    </FormControl>
  );
};

const DateInput = forwardRef(InputForwardRef);

DateInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default DateInput;
