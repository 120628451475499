import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

const FilterLanguageForwardRef = (props, ref) => {
  const { params } = props;

  useImperativeHandle(ref, () => ({
    getFilter() {
      return params.getFilter(values, operator);
    },
  }));

  const [values, setValues] = useState([{}]);
  const [operator, setOperator] = useState("or");

  const options = params.options ?? [];
  const levels = params.levels ?? [];

  const handleAddRow = () => {
    setValues([...values, {}]);
  };

  const handleDeleteRow = (toDelete) => {
    setValues(values.filter((item, index) => index !== toDelete));
  };

  return (
    <>
      <Grid container spacing={2} direction={"column"}>
        <Grid>
          <FormControl disabled={values.length < 2}>
            <FormLabel id="group-label">
              A felsorolt nyelvek közül ...
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="group-label"
              name="radio-buttons-group"
              value={operator}
              onChange={(event) => setOperator(event.target.value)}
            >
              <FormControlLabel
                value="and"
                control={<Radio />}
                label="...mindegyik"
              />
              <FormControlLabel
                value="or"
                control={<Radio />}
                label="...bármelyik"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid>
          {values.map((value, index) => (
            <Grid key={index} container spacing={2} alignItems={"center"}>
              <Grid>
                <FormControl sx={{ minWidth: 150 }}>
                  <InputLabel>Nyelv</InputLabel>
                  <Select
                    name={`${params.field}[language][]`}
                    label={"Nyelv"}
                    onChange={(event) => {
                      const newValues = JSON.parse(JSON.stringify(values));
                      newValues[index].languageId = event.target.value ?? "";
                      setValues(newValues);
                    }}
                    value={values[index].languageId ?? ""}
                  >
                    {options.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel>Szóban</InputLabel>
                  <Select
                    name={`${params.field}[spokenLevel][]`}
                    label={"Szóban legalább..."}
                    onChange={(event) => {
                      const newValues = JSON.parse(JSON.stringify(values));
                      newValues[index].spokenLevel = event.target.value ?? "";
                      setValues(newValues);
                    }}
                    value={values[index].spokenLevel ?? ""}
                  >
                    {levels.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel>Írásban</InputLabel>
                  <Select
                    name={`${params.field}[writtenLevel][]`}
                    label={"Írásban legalább..."}
                    onChange={(event) => {
                      const newValues = JSON.parse(JSON.stringify(values));
                      newValues[index].writtenLevel = event.target.value ?? "";
                      setValues(newValues);
                    }}
                    value={values[index].writtenLevel ?? ""}
                  >
                    {levels.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                {index === values.length - 1 && (
                  <IconButton color="success" onClick={() => handleAddRow()}>
                    <PlaylistAddIcon />
                  </IconButton>
                )}
                {index !== values.length - 1 && (
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <PlaylistRemoveIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
const FilterLanguage = forwardRef(FilterLanguageForwardRef);

FilterLanguage.propTypes = {
  params: PropTypes.object,
};

export default FilterLanguage;
