import {lazy, Suspense, useEffect, useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {MasterLayout} from "../../_hcmd/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import {MenuTestPage} from "../pages/MenuTestPage";
import {getCSSVariableValue} from "../../_metronic/assets/ts/_utils";
import {DisableSidebar} from "../../_metronic/layout/core";
import {DashboardWrapper} from "../pages/dashboard/DashboardWrapper";
import {WithChildren} from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import {useMe} from "../services/auth";

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
    // const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
    // const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
    // const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
    // const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
    // const DevPage = lazy(() => import("../modules/apps/dev/DevPage"));
    const DictionaryPage = lazy(() =>
        import("../modules/hcmd/Routes/DictionaryRoutes")
    );
    const AddressBookPage = lazy(() =>
        import("../modules/hcmd/Routes/AddressBookRoutes")
    );
    const UsersPage = lazy(() => import("../modules/hcmd/Routes/UserRoutes"));
    const AppPages = lazy(() => import("../modules/hcmd/Routes/AppRoutes"));

    const [loaded, setLoaded] = useState(false);

    const me = useMe();

    useEffect(() => {
        if (me) {
            setLoaded(true);
        }
    }, [me]);

    return (
        <Routes>
            {loaded && (<Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path="auth/*" element={<Navigate to="/dashboard"/>}/>
                {/* Pages */}
                <Route
                    path="dictionary/*"
                    element={
                        <SuspensedView>
                            <DictionaryPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="address-books/*"
                    element={
                        <SuspensedView>
                            <AddressBookPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="users/*"
                    element={
                        <SuspensedView>
                            <UsersPage/>
                        </SuspensedView>
                    }
                />
                <Route path="dashboard" element={<DashboardWrapper/>}/>
                {/*<Route path="builder" element={<BuilderPageWrapper />} />
                <Route path="menu-test" element={<MenuTestPage />} />*/}
                {/* Lazy Modules */}
                {/*<Route
                  path="crafted/pages/profile/*"
                  element={
                    <SuspensedView>
                      <ProfilePage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="crafted/pages/wizards/*"
                  element={
                    <SuspensedView>
                      <WizardsPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="crafted/widgets/*"
                  element={
                    <SuspensedView>
                      <WidgetsPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="crafted/account/*"
                  element={
                    <SuspensedView>
                      <AccountPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="apps/chat/*"
                  element={
                    <SuspensedView>
                      <ChatPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="apps/devs/*"
                  element={
                    <SuspensedView>
                      <DevPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="apps/user-management/*"
                  element={
                    <SuspensedView>
                      <UsersPage />
                    </SuspensedView>
                  }
                />*/}
                <Route
                    path="*"
                    element={
                        <SuspensedView>
                            <AppPages/>
                        </SuspensedView>
                    }
                />
            </Route>)}
        </Routes>
    );
};

const SuspensedView = ({children}) => {
    const baseColor = getCSSVariableValue("--bs-primary");
    TopBarProgress.config({
        barColors: {
            0: baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    });
    return (
        <Suspense fallback={<TopBarProgress/>}>
            <DisableSidebar>{children}</DisableSidebar>
        </Suspense>
    );
};

export {PrivateRoutes, SuspensedView};
