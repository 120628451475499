import React, { useEffect, useRef, useState } from "react";
import Lists from "../../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import {
  addUserComment,
  getUserComment,
  getUserComments,
  getUsers,
} from "../../../services/user";
import { Button, TextField } from "@mui/material";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import Box from "@mui/material/Box";

Comments.propTypes = {};

function Comments(props) {
  let { user } = props;

  const [comments, setComments] = useState([]);
  const [columns, setColumns] = useState([]);
  const [adminIds, setAdminIds] = useState([]);
  const [admins, setAdmins] = useState([]);

  const refComment = useRef(null);

  useEffect(() => {
    getUserComments(user.id)
      .then((response) => {
        setComments(response.data ?? []);
      })
      .catch((err) => console.error(err));
    return () => {
      setComments([]);
    };
  }, [user]);

  useEffect(() => {
    const adminId = JSON.parse(JSON.stringify(adminIds));
    comments.map((item) => {
      if (!adminId.includes(item.adminId)) {
        adminId.push(item.adminId);
      }
      setAdminIds(adminId);
    });
  }, [comments]);

  useEffect(() => {
    if (adminIds.length) {
      getUsers({
        id: { inq: adminIds },
      }).then((response) => {
        setAdmins(response.data);
      });
    }
  }, [adminIds]);

  const params = useParams();

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const types = [
    {
      id: 1,
      name: "Független megjegyzés",
    },
    {
      id: 2,
      name: "Adatmódosítási megjegyzés",
    },
    {
      id: 3,
      name: "Feketelista megjegyzés",
    },
    {
      id: 4,
      name: "Interjú miatti megjegyzés",
    },
  ];

  // ide jon az oszlopok deklaralasa
  useEffect(() => {
    setColumns([
      {
        field: "id",
        fieldType: "number",
        hide: true,
      },
      {
        field: "admin",
        fieldType: "",
        width: 150,
        headerName: "Admin",
        valueGetter: (params) => {
          const admin = admins.find((item) => item.id === params.row.adminId);
          return admin ? `${admin.lastName} ${admin.firstName}` : "";
        },
      },
      {
        field: "comment",
        fieldType: "",
        headerName: "Megjegyzés",
        renderCell: (params) => {
          return parse(params.row.text);
        },
      },
      {
        field: "createTime",
        fieldType: "",
        width: 150,
        headerName: "Megjegyzés dátuma",
        align: "right",
        valueGetter: (params) => {
          return moment(params.row.createTime).format("YYYY-MM-DD HH:mm:ss");
        },
      },
      {
        field: "actions",
        fieldType: "",
        hide: true,
      },
    ]);
  }, [user, admins]);

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Felhasználói megjegyzések",
    url: "",
    item: "",
  };

  const handleAddNewComment = () => {
    const newComment = refComment.current.value;
    addUserComment(user.id, newComment)
      .then((response) => {
        setComments([...comments, response.data]);
        refComment.current.value = "";
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Lists
        header={header}
        permissions={() => {
          return Promise.resolve({ data: ["read"] });
        }}
        getFunc={Promise.resolve({ data: comments })}
        columns={columns}
        types={types}
      ></Lists>
      <Box sx={{ ml: 4, my: 2 }}>
        <Box sx={{ maxWidth: 400, width: "100%" }}>
          <TextField
            inputRef={refComment}
            label="Új megjegyzés szövege"
            multiline
            rows={3}
            sx={{ width: "100%" }}
          />
        </Box>
        <Box>
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<InsertCommentIcon />}
            color={"success"}
            onClick={() => {
              handleAddNewComment();
            }}
            sx={{ my: 2, p: 1 }}
          >
            Megjegyzés hozzáadása
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default Comments;
