import axios from "axios";
import { AuthModel, UserModel } from "./_models";
import { me } from "../../../services/auth";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/me`;
export const LOGIN_URL = `${API_URL}/admin/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/admin/forgotten`;

// Server should return AuthModel
export function login(email, password) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  });
}

// Server should return AuthModel
export function register(
  email,
  firstname,
  lastname,
  password,
  password_confirmation
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function checkToken(token) {
  return axios.post(`${API_URL}/admin/forgotten-token`, {
    token,
  });
}

export function setNewPassword(token, newPassword) {
  return axios.post(`${API_URL}/admin/new-password`, {
    token,
    newPassword,
  });
}

export function getUserByToken() {
  return me();
}
