import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PauseIcon from "@mui/icons-material/Pause";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

ApplicationMassButtons.propTypes = {};

function ApplicationMassButtons(props) {
  const { selectedStatuses, handleMassChange, selectedToChange } = props;
  console.log("selectedToChange", selectedToChange);

  return (
    <Grid>
      {!selectedStatuses.includes(80) && (
        <ButtonGroup
          disabled={!selectedToChange.current.length}
          aria-label="tömeges státuszállítás"
        >
          {!selectedStatuses.includes(90) && (
            <Button
              key={`mass-change-0`}
              variant={"outlined"}
              aria-label="Továbbléptetés a következő státuszba"
              startIcon={<PlayArrowIcon />}
              onClick={() => {
                handleMassChange("stepForward");
              }}
            >
              Továbbléptetés a következő státuszba
            </Button>
          )}
          {selectedStatuses.length === 1 && selectedStatuses[0] === 20 && (
            <Button
              key={`mass-change-1`}
              variant={"outlined"}
              aria-label="Továbbléptetés interjúztatás nélkül"
              startIcon={<FastForwardIcon />}
              onClick={() => {
                handleMassChange("stepForwardFfwd");
              }}
            >
              Továbbléptetés interjúztatás nélkül
            </Button>
          )}
          {!selectedStatuses.includes(90) && (
            <Button
              key={`mass-change-2`}
              variant={"outlined"}
              aria-label="Parkolópályára tesz"
              startIcon={<PauseIcon />}
              onClick={() => {
                handleMassChange("pause");
              }}
            >
              Parkolópályára tesz
            </Button>
          )}
          {selectedStatuses.length === 1 && selectedStatuses[0] === 90 && (
            <Button
              key={`mass-change-3`}
              variant={"outlined"}
              aria-label="Parkolópályáról visszavesz"
              startIcon={<NotStartedIcon />}
              onClick={() => {
                handleMassChange("unpause");
              }}
            >
              Parkolópályáról visszavesz
            </Button>
          )}
          {!selectedStatuses.includes(80) && (
            <Button
              key={`mass-change-4`}
              variant={"outlined"}
              aria-label="Visszautasít"
              startIcon={<ThumbDownIcon />}
              onClick={() => {
                handleMassChange("reject");
              }}
            >
              Visszautasít
            </Button>
          )}
        </ButtonGroup>
      )}
    </Grid>
  );
}

export default ApplicationMassButtons;
