import React from "react";
import PropTypes from "prop-types";
import Users from "./Users";

UserMds.propTypes = {};

function UserMds(props) {
  return <Users type={"md"} />;
}

export default UserMds;
