import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import BlockIcon from "@mui/icons-material/Block";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Grid from "@mui/material/Unstable_Grid2";
import Basic from "./Basic";
import Box from "@mui/material/Box";
import { Alert } from "@mui/lab";
import { setUser } from "../../../services/user";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Place from "./Place";
import Qualification from "./Qualification";
import Language from "./Language";
import ComputerSkill from "./ComputerSkill";
import DrivingLicence from "./DrivingLicence";
import FieldsOfWork from "./FieldsOfWork";
import WorkTypeMd from "./WorkTypeMd";
import Preference from "./Preference";
import Availability from "./Availability";
import DialogDelete from "./DialogDelete";
import DialogInactivate from "./DialogInactivate";
import DialogBlacklist from "./DialogBlacklist";
import { usePermission, useRoles } from "../../../services/auth";
import { LinearProgressWithLabel } from "../../../services/constants";

Details.propTypes = {
  id: PropTypes.number,
  user: PropTypes.object.isRequired,
};

function Details(props) {
  const { id, user, site, options } = props;

  const sectionRefs = useRef([]);

  const navigate = useNavigate();

  const [isEditable, setIsEditable] = useState(user.id > 0 ? false : true);
  const [refresh, setRefresh] = useState(false);
  const [accordions, setAccordions] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [saving, setSaving] = useState(false);

  const refDialogDelete = useRef(null);
  const refDialogInactivate = useRef(null);
  const refDialogBlacklist = useRef(null);

  const roles = useRoles();

  const permissions = usePermission("user", id);

  useEffect(() => {
    if (site && user && Object.keys(options).length > 0) {
      let accords = [
        {
          name: "basic",
          label: "Alap adatok",
          sublabel: "",
          element: (
            <Basic
              user={user}
              isEditable={isEditable}
              options={options}
              ref={(el) => (sectionRefs.current["basic"] = el)}
              onSubmit={(values) => {
                submit("basic", values);
              }}
            ></Basic>
          ),
        },
        {
          name: "place",
          label: "Elérhetőségek",
          sublabel: "",
          element: (
            <Place
              user={user}
              isEditable={isEditable}
              options={options}
              ref={(el) => (sectionRefs.current["place"] = el)}
              onSubmit={(values) => {
                submit("place", values);
              }}
            ></Place>
          ),
        },
        {
          name: "qualification",
          label: "Tanulmányok",
          sublabel: "",
          element: (
            <Qualification
              user={user}
              isEditable={isEditable}
              options={options}
              ref={(el) => (sectionRefs.current["qualification"] = el)}
              onSubmit={(values) => {
                submit("qualification", values);
              }}
            ></Qualification>
          ),
        },
        {
          name: "language",
          label: "Nyelvek",
          sublabel: "",
          element: (
            <Language
              user={user}
              isEditable={isEditable}
              options={options}
              ref={(el) => (sectionRefs.current["language"] = el)}
              onSubmit={(values) => {
                submit("language", values);
              }}
            ></Language>
          ),
        },
        {
          name: "computerskill",
          label: "Számítógépes ismeretek",
          sublabel: "",
          element: (
            <ComputerSkill
              user={user}
              isEditable={isEditable}
              options={options}
              ref={(el) => (sectionRefs.current["computerskill"] = el)}
              onSubmit={(values) => {
                submit("computerskill", values);
              }}
            ></ComputerSkill>
          ),
        },
        {
          name: "drivinglicence",
          label: "Jogosítvány",
          sublabel: "",
          element: (
            <DrivingLicence
              user={user}
              isEditable={isEditable}
              options={options}
              ref={(el) => (sectionRefs.current["drivinglicence"] = el)}
              onSubmit={(values) => {
                submit("drivinglicence", values);
              }}
            ></DrivingLicence>
          ),
        },
      ];
      if (site === "md") {
        accords = [
          ...accords,
          {
            name: "worktypemd",
            label: "Munkatapasztalat",
            sublabel: "",
            element: (
              <WorkTypeMd
                user={user}
                isEditable={isEditable}
                options={options}
                ref={(el) => (sectionRefs.current["worktypemd"] = el)}
                onSubmit={(values) => {
                  submit("worktypemd", values);
                }}
              ></WorkTypeMd>
            ),
          },
          {
            name: "availability",
            label: "Ráérés",
            sublabel: "",
            element: (
              <Availability
                user={user}
                isEditable={isEditable}
                options={options}
                ref={(el) => (sectionRefs.current["availability"] = el)}
                onSubmit={(values) => {
                  console.log("Availability onSubmit", values);
                  submit("availability", values);
                }}
              ></Availability>
            ),
          },
        ];
      } else if (site === "hc") {
        accords = [
          ...accords,
          {
            name: "fieldsofwork",
            label: "Munkatapasztalat",
            sublabel: "",
            element: (
              <FieldsOfWork
                user={user}
                isEditable={isEditable}
                options={options}
                ref={(el) => (sectionRefs.current["fieldsofwork"] = el)}
                onSubmit={(values) => {
                  submit("fieldsofwork", values);
                }}
              ></FieldsOfWork>
            ),
          },
          {
            name: "preference",
            label: "Elvárások",
            sublabel: "",
            element: (
              <Preference
                user={user}
                isEditable={isEditable}
                options={options}
                ref={(el) => (sectionRefs.current["preference"] = el)}
                onSubmit={(values) => {
                  submit("preference", values);
                }}
              ></Preference>
            ),
          },
        ];
      }
      setAccordions(accords);
    }
    return () => {};
  }, [site, user, options, isEditable]);

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleBlacklist = () => {
    if (refDialogBlacklist.current) {
      refDialogBlacklist.current.open();
    }
  };

  const handleDeletePhysical = () => {
    if (refDialogDelete.current) {
      refDialogDelete.current.open();
    }
  };

  const handleInactivate = () => {
    if (refDialogInactivate.current) {
      refDialogInactivate.current.open();
    }
  };

  const handleSave = () => {
    let sections = {};
    setSaving(true);
    Object.keys(sectionRefs.current).map((section) => {
      sections[section] = sectionRefs.current[section].forms();
      if (section === "availability") {
        console.log("userAvailabilityMds forms", sections[section]);
      }
      // sectionRefs.current[section].submit();
    });

    let values = {};
    const merge = (cur, acc) => {
      Object.keys(acc).map((attr) => {
        if (!cur[attr]) {
          cur[attr] = acc[attr];
        } else if (Array.isArray(acc[attr]) && acc[attr].length > 0) {
          acc[attr].map((item, index) => {
            if (!cur[attr][index]) {
              cur[attr][index] = acc[attr][index];
            } else {
              cur[attr][index] = { ...cur[attr][index], ...item };
            }
          });
        } else if (Object.keys(acc[attr]).length > 0) {
          Object.keys(acc[attr]).map((key) => {
            if (!cur[attr][key]) {
              cur[attr][key] = acc[attr][key];
            } else {
              cur[attr][key] = { ...cur[attr][key], ...acc[attr][key] };
            }
          });
        }
      });
      return cur;
    };
    let firstError = null,
      allErrors = {};
    for (const section of Object.keys(sections)) {
      for (const form of sections[section]) {
        if (form && form.validation) {
          const errors = form.validation();
          if (errors && !firstError) {
            firstError = errors[Object.keys(errors)[0]];
          }
          if (Object.keys(errors).length) {
            Object.keys(errors).map((errorField) => {
              const error = errors[errorField];
              if (!allErrors[errorField]) {
                allErrors[errorField] = error;
              } else {
                allErrors[errorField].columns = [
                  ...allErrors[errorField].columns,
                  ...error.columns,
                ];
              }
            });
          }
        }
      }
    }
    setErrors(allErrors);
    if (firstError) {
      console.error(allErrors);
    } else {
      for (const section of Object.keys(sections)) {
        for (const form of sections[section]) {
          if (section === "availability") {
            console.log("userAvailabilityMds submit", form);
          }
          if (form && form.submit) {
            const formValue = form.submit();
            // values = { ...values, ...formValue };
            values = merge(values, formValue);
          } else {
            console.log("missing", section);
          }
        }
      }
    }
    console.log("save", values);
    if (Object.keys(allErrors).length) {
      setSaving(false);
      setErrorMessage("Hibás mezők az űrlapon!");
    } else {
      try {
        if (Object.keys(values.userLanguages ?? {}).length) {
          values.userLanguages = Object.keys(values.userLanguages).map(
            (key) => values.userLanguages[key]
          );
        }
        if (Object.keys(values.userQualifications ?? {}).length) {
          values.userQualifications = Object.keys(
            values.userQualifications
          ).map((key) => {
            if (
              !values.userQualifications[key].startDate ||
              !moment(values.userQualifications[key].startDate)
            ) {
              delete values.userQualifications[key].startDate;
            }
            if (
              !values.userQualifications[key].endDate ||
              !moment(values.userQualifications[key].endDate)
            ) {
              delete values.userQualifications[key].endDate;
            }
            return values.userQualifications[key];
          });
        }
        if (Object.keys(values.userComputerSkills ?? {}).length) {
          values.userComputerSkills = Object.keys(
            values.userComputerSkills
          ).map((key) => values.userComputerSkills[key]);
        }
        if (Object.keys(values.userAvailabilityMds ?? {}).length) {
          values.userAvailabilityMds = Object.keys(
            values.userAvailabilityMds
          ).map((key) => values.userAvailabilityMds[key]);
        }
        if (Object.keys(values.userWorkTypeMds ?? {}).length) {
          values.userWorkTypeMds = Object.keys(values.userWorkTypeMds).map(
            (key) => {
              if (
                !values.userWorkTypeMds[key].startDate ||
                !moment(values.userWorkTypeMds[key].startDate)
              ) {
                delete values.userWorkTypeMds[key].startDate;
              }
              if (
                !values.userWorkTypeMds[key].endDate ||
                !moment(values.userWorkTypeMds[key].endDate)
              ) {
                delete values.userWorkTypeMds[key].endDate;
              }
              return values.userWorkTypeMds[key];
            }
          );
        }
        if (Object.keys(values.userCountyPreferences ?? {}).length) {
          values.userCountyPreferences = Object.keys(
            values.userCountyPreferences
          ).map((key) => values.userCountyPreferences[key]);
        }
        if (
          Object.keys(values.userFieldsOfWorkCategoryPreferences ?? {}).length
        ) {
          values.userFieldsOfWorkCategoryPreferences = Object.keys(
            values.userFieldsOfWorkCategoryPreferences
          ).map((key) => values.userFieldsOfWorkCategoryPreferences[key]);
        }
        if (Object.keys(values.userFieldsOfWorks ?? {}).length) {
          values.userFieldsOfWorks = Object.keys(values.userFieldsOfWorks).map(
            (key) => {
              if (
                !values.userFieldsOfWorks[key].startDate &&
                !moment(values.userFieldsOfWorks[key].startDate)
              ) {
                delete values.userFieldsOfWorks[key].startDate;
              }
              if (
                !values.userFieldsOfWorks[key].endDate &&
                !moment(values.userFieldsOfWorks[key].endDate)
              ) {
                delete values.userFieldsOfWorks[key].endDate;
              }
              return values.userFieldsOfWorks[key];
            }
          );
        }

        if (!id) {
          values.type = site === "md" ? 10 : site === "hc" ? 20 : 0;
        }

        setUser(id, values)
          .then((response) => {
            console.log("response", response);
            const time = new Date().getTime();
            setSaving(false);
            navigate(`/users/${site}/${response.data.id}?refresh=${time}`);
          })
          .catch((err) => {
            console.error(err);
            setSaving(false);
          });
      } catch (err) {
        console.error(err);
        setSaving(false);
        setErrorMessage(err.message);
      }
    }
  };

  const parseErrors = (errors) => {
    const errorChips = [];
    Object.keys(errors).map((field) => {
      const error = errors[field];
      let label = error.columns[0].headerName ?? field;
      if (error.columns.length > 1) {
        label += ` (${error.columns.length})`;
      }
      errorChips.push(<Chip className={"ms-3"} label={label} color="error" />);
    });
    return errorChips;
  };

  const submit = (section, values) => {
    console.log(`${section} submit`, values);
  };

  const handleCancel = () => {
    console.log("handleCancel");
  };

  const handleCopy = () => {
    console.log("handleCopy");
  };

  const handleDelete = () => {
    console.log("handleDelete");
  };

  return (
    <div>
      <Box>
        {!isEditable && (
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<EditIcon />}
            color={"warning"}
            onClick={() => {
              handleEdit();
            }}
            sx={{ mr: 3 }}
          >
            Szerkesztés
          </Button>
        )}
        {isEditable && user.id > 0 && (
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<EditIcon />}
            color={"error"}
            onClick={() => {
              handleEdit();
            }}
            sx={{ mr: 3 }}
          >
            Szerkesztés visszavonása
          </Button>
        )}
        <Button
          variant={"contained"}
          size={"small"}
          startIcon={<DoDisturbOnIcon />}
          color={"error"}
          onClick={() => {
            handleBlacklist();
          }}
          sx={{ mr: 2 }}
        >
          Feketelista
        </Button>
        {permissions.includes("delete") && (
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<DeleteForeverIcon />}
            color={"error"}
            onClick={() => {
              handleDeletePhysical();
            }}
            sx={{ mr: 2 }}
          >
            Fizikai törlés
          </Button>
        )}
        {permissions.includes("inactivate") && (
          <Button
            variant={"contained"}
            size={"small"}
            startIcon={<VisibilityOffIcon />}
            color={"error"}
            onClick={() => {
              handleInactivate();
            }}
            sx={{ mr: 2 }}
          >
            Inaktiválás
          </Button>
        )}
      </Box>
      {accordions.map((accordion, index) => (
        <Accordion key={index} expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {accordion.label}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {accordion.sublabel}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{accordion.element}</AccordionDetails>
        </Accordion>
      ))}
      {saving && <LinearProgressWithLabel value={"Mentés folyamatban..."} />}
      {isEditable && !saving && (
        <>
          <Grid container spacing={3}>
            <Grid>
              <Button
                variant={"contained"}
                startIcon={<SaveIcon />}
                color={Object.keys(errors).length > 0 ? "warning" : "success"}
                onClick={() => {
                  handleSave();
                }}
              >
                {`${
                  Object.keys(errors).length > 0
                    ? "Ellenőrzés és mentés"
                    : "Mentés"
                }`}
              </Button>
            </Grid>
            <Grid>
              <Button
                variant={"contained"}
                startIcon={<BlockIcon />}
                color={"error"}
                onClick={() => {
                  handleCancel();
                }}
              >
                Mégsem
              </Button>
            </Grid>
          </Grid>
          {Object.keys(errors).length > 0 && (
            <Alert severity="error" className={"mt-5"}>
              Hiba az alábbi mezőkben: {parseErrors(errors)}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error" className={"mt-5"}>
              Váratlan hiba történt: {errorMessage}
            </Alert>
          )}
        </>
      )}
      <DialogDelete user={user} ref={refDialogDelete}></DialogDelete>
      <DialogInactivate
        user={user}
        ref={refDialogInactivate}
      ></DialogInactivate>
      <DialogBlacklist user={user} ref={refDialogBlacklist}></DialogBlacklist>
    </div>
  );
}

export default Details;
