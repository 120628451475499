import React, { useEffect, useState } from "react";
import Lists from "../../../modules/hcmd/components/Lists";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import { getUserEventLog, getUsers } from "../../../services/user";
import { Typography } from "@mui/material";

Logs.propTypes = {};

function Logs(props) {
  let { user } = props;

  const [isForm, setIsForm] = useState(false);

  const [eventLogs, setEventLogs] = useState([]);
  const [columns, setColumns] = useState([]);
  const [adminIds, setAdminIds] = useState([]);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    getUserEventLog(user.id).then((response) => {
      setEventLogs(response.data);
      const adminId = JSON.parse(JSON.stringify(adminIds));
      response.data.map((item) => {
        if (!adminId.includes(item.userEmployeeId)) {
          adminId.push(item.userEmployeeId);
        }
      });
      setAdminIds(adminId);
    });
  }, [user]);

  useEffect(() => {
    if (adminIds.length) {
      getUsers({
        id: { inq: adminIds },
      }).then((response) => {
        setAdmins(response.data);
      });
    }
  }, [adminIds]);

  const params = useParams();

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const types = [
    {
      id: 1,
      name: "Független megjegyzés",
    },
    {
      id: 2,
      name: "Adatmódosítási megjegyzés",
    },
    {
      id: 3,
      name: "Feketelista megjegyzés",
    },
    {
      id: 4,
      name: "Interjú miatti megjegyzés",
    },
  ];

  // ide jon az oszlopok deklaralasa
  useEffect(() => {
    setColumns([
      {
        field: "id",
        fieldType: "number",
        hide: true,
      },
      {
        field: "createTime",
        fieldType: "",
        width: 150,
        headerName: "Esemény dátuma",
        align: "right",
        valueGetter: (params) => {
          return moment(params.row.createTime).format("YYYY-MM-DD HH:mm:ss");
        },
      },
      {
        field: "position",
        fieldType: "",
        width: 300,
        headerName: "Pozíció",
        renderCell: (params) => {
          return (
            params.row.position && (
              <Link to={`/positions/${params.row.position.id}`} target="_blank">
                {params.row.position.name}
              </Link>
            )
          );
        },
      },
      {
        field: "admin",
        fieldType: "",
        width: 150,
        headerName: "Admin",
        valueGetter: (params) => {
          const admin = admins.find(
            (item) => item.id === params.row.userEmployeeId
          );
          return admin ? `${admin.lastName} ${admin.firstName}` : "";
        },
      },
      {
        field: "body",
        fieldType: "",
        headerName: "Esemény",
        renderCell: (params) => {
          return (
            <Typography
              sx={{
                "& span": {
                  fontWeight: "bold",
                  color: "#fff",
                  textShadow: "0 -1px 0 rgba(0,0,0,0.25)",
                  whiteSpace: "nowrap",
                  verticalAlign: "baseline",
                  backgroundColor: "#999",
                  padding: "1px 4px 2px",
                  borderRadius: "3px",
                },
              }}
            >
              {parse(params.row.body)}
            </Typography>
          );
        },
      },
      {
        field: "actions",
        fieldType: "",
        hide: true,
      },
    ]);
  }, [user, admins]);

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Naplóbejegyzések",
    url: "",
    item: "",
  };

  return (
    <>
      {eventLogs.length > 0 && (
        <Lists
          header={header}
          permissions={() => {
            return Promise.resolve({ data: ["read"] });
          }}
          getFunc={Promise.resolve({ data: eventLogs })}
          columns={columns}
          types={types}
        ></Lists>
      )}
    </>
  );
}

export default Logs;
