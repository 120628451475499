import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth.slice";
import dictionaryReducer from "./dictionary.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dictionary: dictionaryReducer,
  },
});
