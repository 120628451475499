import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  formError,
  formErrorMsg,
  formFieldError,
  formSetValue,
} from "./formUtils";
import { FormControl, TextField } from "@mui/material";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, isFormDirty, dirtying } = props;

  const [value, setValue] = useState(0);
  const [inited, setInited] = useState(false);

  useEffect(() => {
    const initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      setValue(initValue);
    }
    setInited(true);
  }, [initialValue]);

  useEffect(() => {
    if (inited) {
      formSetValue(formik, column, value);
      dirtying();
      formError(formik, column);
    }
  }, [value]);

  const errorText = formFieldError(formik, column, isFormDirty);

  return (
    <div className="row">
      {column.headerName && (
        <label
          className={`col-lg-4 col-form-label fw-bold fs-6 ${
            column.validation?.required && "required"
          }`}
        >
          {column.headerName}
        </label>
      )}
      <div className={`fv-row ${column.headerName ? "col-lg-8" : "col-lg-12"}`}>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            type="text"
            placeholder={column.headerName}
            name={column.field}
            autoComplete="off"
            onChange={(event) => {
              if (column.onChange) {
                column.onChange(event.target.value ?? "");
              }
              setValue(event.target.value ?? "");
              return formik.handleChange;
            }}
            value={value ?? 0}
            error={!!formFieldError(formik, column, isFormDirty)}
            helperText={errorText ? errorText : column.helperText ?? ""}
            inputProps={{ inputMode: "numeric", pattern: "[0-9.,]*" }}
          />
        </FormControl>
        {formErrorMsg(formik, column)}
      </div>
    </div>
  );
};

const NumberInput = forwardRef(InputForwardRef);

NumberInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool,
  dirtying: PropTypes.func.isRequired,
};

export default NumberInput;
