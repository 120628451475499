import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { Checkbox } from "@mui/material";

const InputForwardRef = (props, ref) => {
  const { values, isEditable, index, onChange } = props;
  const checkValues = {};
  for (let d = 1; d <= 7; d++) {
    let allDay = 1;
    for (let p = 1; p <= 6; p++) {
      checkValues[`d${d}p${p}`] = values[`d${d}p${p}`] ?? 0;
      if (values[`d${d}p${p}`] !== 1) {
        allDay = 0;
      }
    }
    checkValues[`d${d}p7`] = allDay;
  }
  const [checked, setChecked] = useState(checkValues);
  const cols = ["", "H", "K", "Sze", "Cs", "P", "Szo", "V"];
  const rows = ["6-8", "8-12", "12-14", "14-16", "16-22", "este", "0-24"];
  const handleClick = (d, p, isChecked) => {
    const newChecked = JSON.parse(JSON.stringify(checked));
    if (p === 7) {
      for (let p = 1; p <= 7; p++) {
        newChecked[`d${d}p${p}`] = isChecked ? 1 : 0;
      }
    } else {
      newChecked[`d${d}p${p}`] = isChecked ? 1 : 0;
      let isAllDay = 1;
      for (let p = 1; p <= 6; p++) {
        if (!newChecked[`d${d}p${p}`]) {
          isAllDay = 0;
        }
      }
      if (isAllDay) {
        newChecked[`d${d}p7`] = 1;
      } else {
        newChecked[`d${d}p7`] = 0;
      }
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    onChange(checked, index);
  }, [checked]);

  return (
    <div>
      <Grid container direction={"column"} sx={{ p: 0, m: 3 }}>
        <Grid
          sx={{
            width: "100%",
            backgroundColor: "#343a40",
            color: "#ffffff",
            height: 53,
            p: 0,
            m: 0,
          }}
        >
          <Grid
            container
            direction={"row"}
            columns={8}
            alignItems={"center"}
            sx={{ p: 0, m: 0, height: 53 }}
          >
            {cols.map((col, d) => {
              return (
                <Grid
                  key={`d${d}p0`}
                  xs={1}
                  sx={{
                    textAlign: "center",
                    color: "#ffffff",
                    p: 0,
                    m: 0,
                  }}
                >
                  {col}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {rows.map((row, p) => {
          return (
            <Grid key={`row${p}`} sx={{ height: 53, p: 0, m: 0 }}>
              <Grid
                columns={8}
                container
                direction={"row"}
                alignItems={"center"}
                sx={{ p: 0, m: 0 }}
              >
                {cols.map((col, d) => {
                  const content =
                    d === 0 ? (
                      row
                    ) : (
                      <Checkbox
                        checked={!!checked[`d${d}p${p + 1}`]}
                        disabled={!isEditable}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                          p: 0,
                          m: 0,
                        }}
                        onChange={(event) => {
                          handleClick(d, p + 1, event.target.checked);
                        }}
                      />
                    );
                  return (
                    <Grid
                      key={`d${d}p${p}`}
                      xs={1}
                      sx={{
                        textAlign: "center",
                        height: 52,
                        borderRight: "1px solid #62646b",
                        borderBottom: "1px solid #62646b",
                        backgroundColor: d === 0 ? "#62646b" : "#ffffff",
                        color: d === 0 ? "#ffffff" : "#000000",
                      }}
                    >
                      {content}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const AvailabilityTable = forwardRef(InputForwardRef);

AvailabilityTable.propTypes = {};

export default AvailabilityTable;
