import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { me, useMe } from "../../../../app/services/auth";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import {
  HeaderNotificationsMenu,
  QuickLinks,
  Search,
  ThemeModeSwitcher,
} from "../../../../_metronic/partials";
import { HeaderUserMenu } from "../../../../_hcmd/partials";
import { getPictureSrc } from "../../../../app/services/user";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarButtonIconSizeClass = "svg-icon-1";

const Topbar = () => {
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState(
    toAbsoluteUrl("/media/avatars/blank.png")
  );
  const me = useMe();

  useEffect(() => {
    if (me) {
      setUser(me);
    }
    return () => {};
  }, [me]);

  useEffect(() => {
    if (user?.user?.picture) {
      setAvatar(getPictureSrc(user.user.picture, user.user.id));
    }
  }, [user]);

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div className="topbar d-flex align-items-stretch flex-shrink-0">
        {/* Search */}
        {/*<div
          className={clsx(
            "d-flex align-items-stretch",
            toolbarButtonMarginClass
          )}
        >
          <Search />
        </div>*/}
        {/* Activities */}
        {/*<div
          className={clsx(
            "d-flex align-items-center ",
            toolbarButtonMarginClass
          )}
        >
          <div
            className={clsx(
              "btn btn-icon btn-active-light-primary btn-custom",
              toolbarButtonHeightClass
            )}
            id="kt_activities_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen032.svg"
              className={toolbarButtonIconSizeClass}
            />
          </div>
        </div>*/}

        {/* CHAT */}
        {/*<div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          <div
            className={clsx(
              "btn btn-icon btn-active-light-primary btn-custom position-relative",
              toolbarButtonHeightClass
            )}
            id="kt_drawer_chat_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/communication/com012.svg"
              className={toolbarButtonIconSizeClass}
            />

            <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
          </div>
        </div>*/}

        {/* NOTIFICATIONS */}
        {/*<div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          <div
            className={clsx(
              "btn btn-icon btn-active-light-primary btn-custom",
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <KTSVG
              path="/media/icons/duotune/abstract/abs038.svg"
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <HeaderNotificationsMenu />
        </div>*/}

        {/* Quick links */}
        {/*<div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          <div
            className={clsx(
              "btn btn-icon btn-active-light-primary btn-custom",
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen025.svg"
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks />
        </div>*/}

        {/* begin::Theme mode */}
        {/*<div className={"d-flex align-items-center ms-lg-5"}>
          <ThemeModeSwitcher toggleBtnClass="btn btn-active-light d-flex align-items-center bg-hover-light py-2 px-2 px-md-3" />
        </div>*/}
        {/* end::Theme mode */}

        {/* begin::User */}
        {user && (
          <div
            className="d-flex align-items-center ms-lg-5"
            id="kt_header_user_menu_toggle"
          >
            <div
              className="btn btn-active-light d-flex align-items-center bg-hover-light py-2 px-2 px-md-3"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              <div className="d-none d-md-flex flex-column align-items-end justify-content-center me-2">
                <span className="text-muted fs-7 fw-bold lh-1 mb-2">Szia</span>
                <span className="text-dark fs-base fw-bolder lh-1">
                  {user.firstName}
                </span>
              </div>

              <div className="symbol symbol-30px symbol-md-40px">
                <img src={avatar} alt="avatar" />
              </div>
            </div>
            <HeaderUserMenu />
          </div>
        )}
        {/* end::User */}

        {/* Header menu toggle */}
        <div
          className="d-flex d-lg-none align-items-center me-n2"
          title="Show header menu"
        >
          <button
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className="svg-icon-1"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export { Topbar };
