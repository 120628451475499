import React, { useEffect, useState } from "react";
import Lists from "../../../modules/hcmd/components/Lists";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { applicationStatuses } from "../../../services/position";

Positions.propTypes = {};

function Positions(props) {
  let { user } = props;

  const [isForm, setIsForm] = useState(false);

  const params = useParams();

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const statuses = applicationStatuses;

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "company",
      fieldType: "",
      headerName: "Cég",
      valueGetter: (params) => {
        return params.row.position.company?.name ?? "nincs megadva";
      },
    },
    {
      field: "position",
      fieldType: "",
      headerName: "Pozíció",
      valueGetter: (params) => {
        return params.row.position.name ?? "nincs megadva";
      },
      renderCell: (params) => {
        return (
          <Link to={`/positions/${params.row.position.id}`} target="_blank">
            {params.row.position.name}
          </Link>
        );
      },
    },
    {
      field: "status",
      fieldType: "",
      headerName: "Státusz",
      align: "center",
      valueGetter: (params) => {
        return statuses.find((item) => item.id === params.row.status).name;
      },
    },
    {
      field: "createTime",
      fieldType: "",
      headerName: "Jelentkezés dátuma",
      align: "right",
      valueGetter: (params) => {
        return moment(params.row.createTime).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      field: "updateTime",
      fieldType: "",
      headerName: "Utolsó státusz módosítás",
      align: "right",
      valueGetter: (params) => {
        return moment(params.row.updateTime ?? params.row.createTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      },
    },
    {
      field: "actions",
      fieldType: "",
      hide: true,
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Megpályázott pozíciók",
    url: "",
    item: "",
  };

  return (
    <>
      <Lists
        header={header}
        permissions={() => {
          return Promise.resolve({ data: ["read"] });
        }}
        getFunc={Promise.resolve({ data: user?.userPositions ?? [] })}
        columns={columns}
        statuses={statuses}
      ></Lists>
    </>
  );
}

export default Positions;
