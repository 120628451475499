import React, { useEffect, useState } from "react";
import {
  getReferers,
  getReferer,
  setReferer,
  deleteReferer,
} from "../../services/dictionary";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";
import { sites } from "../../services/constants";

Referers.propTypes = {};

function Referers(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);

  const permissions = usePermission("dictionary");
  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const siteArr = sites;

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Megnevezés",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "site",
      headerName: "Site",
      validation: {
        required: true,
      },
      fieldType: "select",
      width: 120,
      align: "center",
      valueGetter: (params) => {
        let v = "Egyéb";
        switch (params.row.site) {
          case 10:
            v = "Humáncentrum";
            break;
          case 20:
            v = "Minddiák";
            break;
          case 30:
            v = "Mindkettő";
            break;
        }
        return v;
      },
    },
    {
      field: "priority",
      fieldType: "number",
      // editable: false,
      defaultValue: 1,
      headerName: "Prioritás",
      width: 100,
    },
    {
      field: "isActive",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Státusz",
      width: 100,
      valueGetter: (params) => {
        return params.row.isActive ? "aktív" : "inaktív";
      },
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Megjelenési felületek",
    url: "/dictionary/referers",
    item: "megjelenési felület",
  };

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getReferer}
          setFunc={setReferer}
          sites={siteArr}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getReferers}
          isDictionary={true}
          deleteFunc={deleteReferer}
          columns={columns}
          sites={siteArr}
        ></Lists>
      )}
    </>
  );
}

export default Referers;
