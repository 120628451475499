import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Snackbar,
  Switch,
} from "@mui/material";
import { inactivateUser } from "../../../services/user";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

const DialogInactivateForwardRef = (props, ref) => {
  const { user } = props;

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    open() {
      setInactivateDialogOpen(true);
    },
  }));

  const [inactivateDialogOpen, setInactivateDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [checked, setChecked] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleInactivateDialogAgree = () => {
    inactivateUser(user.id)
      .then((response) => {
        if (response.data.success) {
          setSnackbarMessage("Sikeresen inaktiváltad a felhasználót!");
          setSnackbarOpen(true);
          setTimeout(() => {
            window.history.back();
          }, 2000);
        } else {
          setSnackbarColor("error");
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        }
      })
      .catch((err) => console.error(err));
    setInactivateDialogOpen(false);
  };
  const handleInactivateDialogClose = () => {
    setInactivateDialogOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Dialog
        open={inactivateDialogOpen}
        onClose={handleInactivateDialogClose}
        aria-labelledby="inactivate-dialog-title"
        aria-describedby="inactivate-dialog-description"
      >
        <DialogTitle id="inactivate-dialog-title">
          {"Felhasználó inaktiválás"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component={"div"}
            id="inactivate-dialog-description"
          >
            <Grid container direction={"column"} spacing={2}>
              <Grid>Biztosan inaktiválni akarod a felhasználót?</Grid>
              <Grid>
                <FormControlLabel
                  required
                  control={<Switch checked={checked} onChange={handleChange} />}
                  label="Megerősítés"
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInactivateDialogClose} color={"error"}>
            Nem
          </Button>
          <Button
            onClick={handleInactivateDialogAgree}
            variant={"contained"}
            color={"success"}
            autoFocus
            disabled={!checked}
          >
            Igen
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        open={snackbarOpen}
        message={snackbarMessage}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarColor}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const DialogInactivate = forwardRef(DialogInactivateForwardRef);

DialogInactivate.propTypes = {
  user: PropTypes.object.isRequired,
};

export default DialogInactivate;
